import React, { ReactElement, useCallback, useState, useEffect } from "react";
import moment from "moment-timezone";
import styled, { keyframes } from "styled-components";
import { OnboardingTitle } from "../../home/CitySelector";
import { AnonymousRestaurantSlides } from "../../home/CoverForPopUp";
import {
  Centered,
  TextTitle,
  text12300,
  CenteredByColumn,
  centered,
  NavHeight,
  BorderGrey,
  CenteredByColumnLeft,
  Text12300,
  ActionTransparent,
  Beige,
  centeredByColumn,
  Neon,
  BackgroundGrey,
  ActionUnderline,
} from "../../Shared";
export interface Membership30DTrialProps {
  onClick: () => {};
}

const ContentContainer = styled(CenteredByColumn)`
  /* width: 100vw;
  height: 100vh; */
  width: calc(100vw - 60px);
  max-width: 500px;
  /* height: calc(100vh - 120px); */
  max-height: 800px;
  background-color: white;
  flex-direction: column;
  position: fixed;
  justify-content: flex-start;
  top: 30px;
  /* padding-bottom: 20px; */
  @media only screen and (max-height: 700px) and (max-width: 380px) {
    /* height: calc(100vh - 80px); */
    top: 10px;
    width: calc(100vw - 40px);
  }
  @media only screen and (min-width: 1000px) {
  }
  @media only screen and (min-width: 1200px) {
  }
`;

export const SeeDetails = styled.button`
  ${centeredByColumn}
  ${text12300}
  span {
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    /* line-height: 24px; */
  }
  letter-spacing: 0.8px;
  /* border: 1px solid black; */
  padding: 10px 30px;
  margin-top: 10px;

  background-color: black;
  color: white;
  label {
    font-size: 8px;
    text-transform: none;
  }
  @media only screen and (max-width: 360px) {
    margin-top: 5px;
  }
`;

const Tagline = styled.span`
  ${text12300}
  margin: 0px 0px 5px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 1;
  text-transform: uppercase;
  @media only screen and (min-width: 1000px) {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 360px) {
    font-size: 16px;
  }
`;
const SubTitle = styled.span`
  ${text12300}
  margin: 5px 0px 20px 0px;
  font-size: 10px;
  line-height: 1.5;
  /* font-weight: 500; */
  letter-spacing: 0.6px;
  text-transform: uppercase;
  text-align: center;
  @media only screen and (min-width: 1000px) {
    margin-bottom: 10px;
  }
  @media only screen and (max-height: 700px) and (max-width: 380px) {
    margin-bottom: 10px;
    font-size: 8px;
  }
`;

const Benefits = styled(CenteredByColumnLeft)`
  /* margin-top: 20px; */
  width: 100%;
`;
const Benefit = styled(TextTitle)`
  font-size: 16px;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 10px;
  /* margin-left: 15px; */
  /* &::before{
    content: "✓";
    position: absolute;
    left: -15px;
  } */
  b {
    font-weight: 400;
    background-color: ${Neon};
    padding: 2px 4px;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 15px;
    line-height: 2;
  }
  @media only screen and (max-height: 700px) and (max-width: 380px) {
    font-size: 13px;
  }
`;
const SubBenefit = styled(Text12300)<SectionProps>`
  font-size: 12px;
  /* text-transform: capitalize; */
  position: relative;
  margin-left: 30px;
  b {
    background-color: ${(props) =>
      props.highlight ? `${Neon}` : "transparent"};
    font-weight: 500;
    padding: 2px 4px;
  }
  /* font-weight: ${(props) => (props.highlight ? 500 : 300)}; */
  /* &::before {
    content: "•";
    position: absolute;
    left: -10px;
    top: -20px;
    font-size: 30px;
  } */
  &::before {
    content: "✓";
    position: absolute;
    left: -15px;
  }
  @media only screen and (max-height: 700px) and (max-width: 380px) {
    font-size: 10px;
    line-height: 1.5;
  }
`;

interface SectionProps {
  highlight?: boolean;
}
const Section = styled(CenteredByColumn)<SectionProps>`
  width: 100%;
  /* flex-grow: 1; */
  padding: 30px 10px;
  border-bottom: 1px solid ${BorderGrey};
  position: relative;
  background-color: ${(props) => (props.highlight ? `${Beige}` : "white")};
  &:last-child {
    border-bottom: none;
  }
  @media only screen and (min-width: 1000px) {
    padding: 15px;
  }
  @media only screen and (max-height: 700px) and (max-width: 380px) {
    padding: 20px 10px;
  }
`;
const BorderMargin = 0;
interface SectionBorderProps {
  isHighlight?: boolean;
}
const SectionBorder = styled(CenteredByColumn)<SectionBorderProps>`
  width: calc(100% - ${BorderMargin * 2}px);
  height: calc(100% - ${BorderMargin * 2}px);
  border: 1px solid black;
  border: none;
  @media only screen and (max-width: 380px) {
    border: none;
  }
`;
export const SkipButton = styled(ActionUnderline)`
  ${text12300}
  font-weight: 500;
  text-transform: uppercase;
  margin: 20px 0px 5px 0px;
  /* background-color: ${BackgroundGrey}; */
  /* border: none; */
  /* height: 40px; */
  @media only screen and (max-width: 360px) {
    margin-top: 10px;
  }
`;
export const MembershipOnboarding: React.FC<Membership30DTrialProps> = ({
  onClick,
}: Membership30DTrialProps): ReactElement => {
  return (
    <ContentContainer>
      <Section>
        <SectionBorder>
          <Tagline>Foodie Regular</Tagline>
          <SubTitle>no subscription</SubTitle>
          <Benefit>
            <b>20% cheaper</b> than Doordash
          </Benefit>
          <Benefits>
            <SubBenefit>flat fee</SubBenefit>
            <SubBenefit>Free delivery</SubBenefit>
            <SubBenefit>Delivery range: 25 mi</SubBenefit>
            <SubBenefit>Same food price as Doordash</SubBenefit>
          </Benefits>
        </SectionBorder>
      </Section>
      <Section highlight={true}>
        <SectionBorder>
          <Tagline>Foodie Member</Tagline>
          <SubTitle>$10/Mo, 30 day free trial, cancel anytime</SubTitle>
          <Benefit>
            <b>40% cheaper</b> than Doordash
          </Benefit>
          <Benefits>
            <SubBenefit>flat fee</SubBenefit>
            <SubBenefit>Free delivery</SubBenefit>
            <SubBenefit>Delivery range: 25 mi</SubBenefit>
            <SubBenefit highlight={true}>
              {/* <b>20% cheaper</b> food price */}
              No food price markups
            </SubBenefit>
            <SubBenefit highlight={true}>
              {/* <b>80+ foodie exclusive</b> restaurants */}
              80+ foodie exclusive restaurants
            </SubBenefit>
          </Benefits>
          <SkipButton onClick={onClick.bind(this, 0)}>
            <span>skip</span>
          </SkipButton>
          <SeeDetails onClick={onClick.bind(this, -1)}>
            <span>Learn More</span>
            {/* <label>Cancel anytime</label> */}
          </SeeDetails>
        </SectionBorder>
      </Section>
    </ContentContainer>
  );
};
