import React, { Component } from "react";
import { cloneDeep } from "lodash";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { CalendarContainer } from "react-datepicker";
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { DishEditModal } from "src/components/DishEditModal";
import { getSortedCategoryNamesFromDishes } from "src/util/categories";
import {
  DayReceipts,
  getMonth,
  Loading,
  LOGGING,
  MenuCalendar,
  Navbar,
  RestaurantLevel,
  RestaurantList,
  RestaurantMap,
  ConfirmAlert,
  PopUp,
  RestaurantContact,
  EditMeal,
  CATimeZone,
} from "./";
import { MenuCalendarHeader } from "./components/MenuCalendar";
import { NavHeight } from "./components/Shared";
import {
  fetchDeliveryZones,
  fetchDishesForRestaurant,
  fetchMeals,
  fetchMenu,
  getDayOrders,
  orderCancelItem,
  readRestaurants,
  refundOrder,
  saveMeal,
  cancelOrderByOps,
  saveDish,
  deleteMeal,
  confirmMeal,
  saveRestaurant,
  saveMealsBatch,
  swapMeals,
  refreshCache,
  deleteMealByWindowStart,
  fetchMealTime,
} from "../../store/actions";
import {
  isWindowStartLunch,
  kCritialHourMinute,
  CutOffFromDeliveryStartInMinutes,
} from "../../util/time";
class PageMenuCalendar extends Component {
  constructor() {
    super();
    LOGGING && console.log("PageMenuCalendar constructor");
    this.state = {
      zoom: 15,
      center: { lat: 37.3858165, lng: -122.0945878 },
      month: null,
      firstDayOfMonth: null,
      loading: false,
      showRestaurantListFor: {
        day: null,
        mealType: null,
      },
      swapWindowStarts: [0, 0],
      dishes: [],
      selectedPreOrderedDishes: [],
      selectedDishes: [],
      selectedRestaurants: [], // for creating meals in batch
      consideringRestaurantIds: [], // for creating meals in batch
      selectedRestaurant: -1, // for editing meal
      showRestaurantDetails: false,
      showDayReceipts: false,
      supportEarlyDelivery: false,
      selectedDeliveryZone: [],
      isDishEditOpen: false,
      dishEdit: null,
      showDayMeals: {},
      showRestaurantContact: null,
      mealNotes: "",
      filterByTimeAndCuisine: {},
      showCuisineFilter: {},
    };
    this.handleGoToToday = this.handleGoToToday.bind(this);
    this.handleToggleShowCuisineFilter =
      this.handleToggleShowCuisineFilter.bind(this);
    this.handleFilterWeekByCuisines =
      this.handleFilterWeekByCuisines.bind(this);
    this.handleFetchMealTime = this.handleFetchMealTime.bind(this);
    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);
    this.handleToggleRestaurantInMealBatch =
      this.handleToggleRestaurantInMealBatch.bind(this);
    this.handleCloseRestaurant = this.handleCloseRestaurant.bind(this);
    this.handleCancelRestaurant = this.handleCancelRestaurant.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleToggleDish = this.handleToggleDish.bind(this);
    this.handleSelectAllDishes = this.handleSelectAllDishes.bind(this);
    this.handleDeselectAllDishes = this.handleDeselectAllDishes.bind(this);
    this.handleSelectAllDishesInCategory =
      this.handleSelectAllDishesInCategory.bind(this);
    this.handleDeselectAllDishesInCategory =
      this.handleDeselectAllDishesInCategory.bind(this);
    this.handleSaveMeal = this.handleSaveMeal.bind(this);
    this.handleSaveMeals = this.handleSaveMeals.bind(this);
    this.handleEditMeal = this.handleEditMeal.bind(this);
    this.handleDeleteMeal = this.handleDeleteMeal.bind(this);
    this.handleDeleteMealConfirm = this.handleDeleteMealConfirm.bind(this);
    this.handleMoveMonth = this.handleMoveMonth.bind(this);
    this.handleShowDayReceipts = this.handleShowDayReceipts.bind(this);
    this.handleCloseDayReceipts = this.handleCloseDayReceipts.bind(this);
    this.handleExpandDay = this.handleExpandDay.bind(this);
    this.handleCollaposeDay = this.handleCollaposeDay.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.handleCancelItem = this.handleCancelItem.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.handleToggleSupportEarlyDelivery =
      this.handleToggleSupportEarlyDelivery.bind(this);
    this.handleChangePreOrderQuantity =
      this.handleChangePreOrderQuantity.bind(this);
    this.handleSelectDeliveryZone = this.handleSelectDeliveryZone.bind(this);
    this.handleEditDish = this.handleEditDish.bind(this);
    this.handleEditDishClose = this.handleEditDishClose.bind(this);
    this.handleEditDishSave = this.handleEditDishSave.bind(this);
    this.handleShowRestaurantContact =
      this.handleShowRestaurantContact.bind(this);
    this.handleHideRestaurantContact =
      this.handleHideRestaurantContact.bind(this);
    this.handleToggleMealConfirmStatus =
      this.handleToggleMealConfirmStatus.bind(this);
    this.handleToggleAsian = this.handleToggleAsian.bind(this);
    this.handleToggleNonAsian = this.handleToggleNonAsian.bind(this);
    this.handleToggleAll = this.handleToggleAll.bind(this);
    this.handleSaveMealConfirmStatus =
      this.handleSaveMealConfirmStatus.bind(this);
    this.handleEditMealNotes = this.handleEditMealNotes.bind(this);
    this.handleSetHeroImage = this.handleSetHeroImage.bind(this);
    this.handleToggleSwapWindowStart =
      this.handleToggleSwapWindowStart.bind(this);
    this.handleSwapMeals = this.handleSwapMeals.bind(this);
    this.handleRefreshCache = this.handleRefreshCache.bind(this);
    this.handleEditCutOff = this.handleEditCutOff.bind(this);
    this.handleGoToPlanningWeek = this.handleGoToPlanningWeek.bind(this);
    this.handleDeleteWindowStart = this.handleDeleteWindowStart.bind(this);
    this.handleConfirmDeleteWindowStart =
      this.handleConfirmDeleteWindowStart.bind(this);
    // create a ref to store today's grid
    this.todayRef = React.createRef();
    this.planningWeekRef = React.createRef();
    this.handleQuickDeleteMealConfirm =
      this.handleQuickDeleteMealConfirm.bind(this);
    this.handleQuickDeleteMeal = this.handleQuickDeleteMeal.bind(this);
  }
  handleGoToToday(e) {
    e?.preventDefault();
    const top = this.todayRef.current.getBoundingClientRect().top;
    LOGGING && console.log("top of todayRef:", top);
    window.scrollTo({
      top: window.scrollY + top - 50,
      behavior: "smooth",
    });
  }
  handleToggleShowCuisineFilter(weekIndex, e) {
    e.preventDefault();
    let { showCuisineFilter } = this.state;
    showCuisineFilter[weekIndex] = !showCuisineFilter[weekIndex];
    this.setState({ showCuisineFilter });
  }

  handleFilterWeekByCuisines(weekIndex, cuisine) {
    LOGGING &&
      console.log("handleFilterWeekByCuisines called with:", {
        weekIndex,
        cuisine,
      });
    let { filterByTimeAndCuisine } = this.state;
    // toggle cuisine in filterByTimeAndCuisine[weekIndex]
    if (!filterByTimeAndCuisine[weekIndex]) {
      filterByTimeAndCuisine[weekIndex] = [];
    }
    const foundIndex = filterByTimeAndCuisine[weekIndex].findIndex(
      (c) => c === cuisine
    );
    if (foundIndex >= 0) {
      filterByTimeAndCuisine[weekIndex].splice(foundIndex, 1);
    } else {
      filterByTimeAndCuisine[weekIndex].push(cuisine);
    }
    this.setState({ filterByTimeAndCuisine });
  }
  handleFetchMealTime(windowStart, e) {
    e.preventDefault();
    this.props.fetchMealTime(windowStart);
  }
  handleDeleteWindowStart(windowStart, e) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmDeleteWindowStart.bind(
              this,
              windowStart
            )}
            onClose={onClose}
            actionName="delete meals"
          />
        );
      },
    });
  }

  handleConfirmDeleteWindowStart(windowStart) {
    this.props.deleteMealByWindowStart(windowStart);
  }
  handleGoToPlanningWeek(e) {
    e.preventDefault();
    const top = this.planningWeekRef.current.getBoundingClientRect().top;
    LOGGING && console.log("top of planningWeekRef:", top);
    window.scrollTo({
      top: window.scrollY + top - 2 * NavHeight,
      behavior: "smooth",
    });
  }
  handleEditCutOff(e) {
    LOGGING && console.log("handleEditCutOff called with e: ", e.target);
    let { cutOff } = this.state;
    cutOff[e.target.name] = Number(e.target.value);
    this.setState({ cutOff });
  }
  handleSwapMeals(e) {
    e.preventDefault();
    const { swapWindowStarts } = this.state;
    this.setState({ swapping: true });
    this.props.swapMeals(swapWindowStarts[0], swapWindowStarts[1]).then(() => {
      this.setState({ swapping: false, swapWindowStarts: [0, 0] });
    });
  }
  handleRefreshCache(e) {
    e.preventDefault();
    LOGGING &&
      console.log("handleRefreshCache called at: ", moment().valueOf());
    this.props.refreshCache();
  }

  handleToggleSwapWindowStart(windowStart, e) {
    e.preventDefault();
    let { swapWindowStarts } = this.state;
    LOGGING &&
      console.log("handleToggleSwapWindowStart called with:", {
        windowStart,
        swapWindowStarts,
      });
    const foundIndex = swapWindowStarts.findIndex((a) => a === windowStart);
    LOGGING &&
      console.log("handleToggleSwapWindowStart foundIndex :", foundIndex);
    if (foundIndex >= 0) {
      swapWindowStarts[foundIndex] = 0;
    } else if (swapWindowStarts[0] === 0) {
      swapWindowStarts[0] = windowStart;
    } else if (swapWindowStarts[1] === 0) {
      swapWindowStarts[1] = windowStart;
    }
    LOGGING &&
      console.log("handleToggleSwapWindowStart got:", swapWindowStarts);
    this.setState({ swapWindowStarts });
  }

  handleEditMealNotes(e) {
    this.setState({ mealNotes: e.target.value });
  }

  handleSetHeroImage(restaurantId, heroImage) {
    LOGGING &&
      console.log("handleSetHeroImage called:", { restaurantId, heroImage });
    const restaurant = this.props.restaurants.find(
      (restaurant) => restaurant._id === restaurantId
    );
    const updatedRestaurant = { ...restaurant, heroImage };
    this.props.saveRestaurant(updatedRestaurant);
  }

  handleSaveMealConfirmStatus(e) {
    e.preventDefault();
    const { showRestaurantContact } = this.state;
    LOGGING &&
      console.log(
        "handleSaveMealConfirmStatus called with showRestaurantContact:",
        showRestaurantContact
      );

    this.setState({ showRestaurantContact: null, loading: true }, () => {
      this.props.confirmMeal(showRestaurantContact).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleToggleMealConfirmStatus(e) {
    e.preventDefault();
    const { showRestaurantContact } = this.state;

    this.setState({
      showRestaurantContact: {
        ...showRestaurantContact,
        confirmedWithRestaurant: !showRestaurantContact.confirmedWithRestaurant,
      },
    });
  }

  handleToggleAsian(e) {
    e.preventDefault();
    const { showRestaurantContact } = this.state;
    let mealGroup;

    LOGGING &&
      console.log("handleToggleAsian got: ", { showRestaurantContact });

    if (showRestaurantContact.mealGroup === 0) mealGroup = 2;
    else if (showRestaurantContact.mealGroup === 1) mealGroup = -1;
    else if (showRestaurantContact.mealGroup === 2) mealGroup = 0;
    else mealGroup = 1;

    LOGGING && console.log("handleToggleAsian result: ", { mealGroup });

    this.setState({
      showRestaurantContact: {
        ...showRestaurantContact,
        mealGroup,
      },
    });
  }

  handleToggleNonAsian(e) {
    e.preventDefault();
    const { showRestaurantContact } = this.state;
    let mealGroup;

    LOGGING &&
      console.log("handleToggleNonAsian got: ", { showRestaurantContact });

    if (showRestaurantContact.mealGroup === 0) mealGroup = 1;
    else if (showRestaurantContact.mealGroup === 1) mealGroup = 0;
    else if (showRestaurantContact.mealGroup === 2) mealGroup = -1;
    else mealGroup = 2;

    LOGGING && console.log("handleToggleNonAsian result: ", { mealGroup });

    this.setState({
      showRestaurantContact: {
        ...showRestaurantContact,
        mealGroup,
      },
    });
  }

  handleToggleAll(e) {
    e.preventDefault();
    const { showRestaurantContact } = this.state;
    let mealGroup;

    LOGGING && console.log("handleToggleAll got: ", { showRestaurantContact });

    if (
      showRestaurantContact.mealGroup === 0 ||
      showRestaurantContact.mealGroup === 1 ||
      showRestaurantContact.mealGroup === 2
    )
      mealGroup = 3;
    else mealGroup = 0;

    LOGGING && console.log("handleToggleAll result: ", { mealGroup });

    this.setState({
      showRestaurantContact: {
        ...showRestaurantContact,
        mealGroup,
      },
    });
  }

  handleShowRestaurantContact(meal, e) {
    LOGGING &&
      console.log(" handleShowRestaurantContact called with meal:", meal);
    e.preventDefault();
    this.setState({ showRestaurantContact: meal });
  }

  handleHideRestaurantContact(e) {
    e.preventDefault();
    this.setState({ showRestaurantContact: null });
  }

  handleDeleteMealConfirm(meal) {
    LOGGING && console.log(" handleDeleteMealConfirm called with meal:", meal);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={() => this.handleDeleteMeal(meal)}
            onClose={onClose}
            message={`Are you sure you want to delete this meal?`}
          />
        );
      },
    });
  }
  handleQuickDeleteMealConfirm(mealId) {
    LOGGING &&
      console.log(" handleQuickDeleteMealConfirm called with mealId:", mealId);
    this.props.deleteMeal({ _id: mealId }).then(() => {
      LOGGING && console.log("deleteMeal returned from backend.");
    });
  }

  handleQuickDeleteMeal(mealId, e) {
    LOGGING &&
      console.log(" handleQuickDeleteMeal called with mealId:", { mealId, e });
    e.preventDefault();
    LOGGING &&
      console.log(" handleQuickDeleteMeal called with mealId:", mealId);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={() => this.handleQuickDeleteMealConfirm(mealId)}
            onClose={onClose}
            message={`Are you sure you want to delete this meal?`}
          />
        );
      },
    });
  }

  handleDeleteMeal(meal) {
    let { showDayMeals } = this.state;
    const { windowStart } = meal;
    const lunchWindowStart = isWindowStartLunch(windowStart)
      ? windowStart
      : windowStart - 6.5 * 1000 * 3600;
    showDayMeals[lunchWindowStart] = false;
    LOGGING && console.log("handleDeleteMeal called with:", meal, showDayMeals);
    this.props.deleteMeal(meal).then(() => {
      LOGGING && console.log("deleteMeal returned from backend.");
      this.setState({ showDayMeals });
    });
  }

  handleSelectDeliveryZone(deliveryZones) {
    console.log(
      "handleSelectDeliveryZone",
      deliveryZones,
      deliveryZones.map((zone) => zone["_id"])
    );
    this.setState({
      selectedDeliveryZone: deliveryZones.map((zone) => zone["_id"]),
    });
  }

  handleChangePreOrderQuantity(dishId, e) {
    let { selectedPreOrderedDishes } = this.state;
    LOGGING &&
      console.log(
        "handleChangePreOrderQuantity called with:",
        dishId,
        selectedPreOrderedDishes,
        e.target
      );
    const dishIndex = selectedPreOrderedDishes.findIndex((o) =>
      typeof o.dish === "string" ? o.dish === dishId : o.dish._id === dishId
    );
    selectedPreOrderedDishes[dishIndex].quantity = Number(e.target.value);
    this.setState({ selectedPreOrderedDishes });
  }

  handleToggleSupportEarlyDelivery(e) {
    LOGGING && console.log("handleToggleSupportEarlyDelivery called");
    e.preventDefault();
    this.setState({
      supportEarlyDelivery: !this.state.supportEarlyDelivery,
    });
  }

  handleRefund(orderId, refundAmount) {
    LOGGING && console.log("handleRefund called: ", { orderId, refundAmount });
    const { showDayReceipts } = this.state;
    const { lunchMeals, dinnerMeals } = showDayReceipts;
    this.props.refundOrder(orderId, refundAmount).then(() => {
      toast.success("refund success");
      this.setState({ loading: true });
      this.props.getDayOrders([...lunchMeals, ...dinnerMeals]).then(() => {
        window.scrollTo(0, 0);
        this.setState({ loading: false });
      });
    });
  }

  handleCancelItem(orderId, index, dishId, cancelQuantity) {
    LOGGING &&
      console.log("handleCancelItem called", {
        orderId,
        index,
        dishId,
        cancelQuantity,
      });
    this.props
      .orderCancelItem(orderId, index, dishId, cancelQuantity)
      .then(() => {
        toast.success("cancel item success");
        this.setState({ loading: true }, () => {
          const { showDayReceipts } = this.state;
          const { lunchMeals, dinnerMeals } = showDayReceipts;
          LOGGING &&
            console.log("handleCancelItem called", {
              lunchMeals,
              dinnerMeals,
              showDayReceipts,
            });
          this.props.getDayOrders([...lunchMeals, ...dinnerMeals]).then(() => {
            window.scrollTo(0, 0);
            this.setState({ loading: false });
          });
        });
      });
  }

  handleCancelOrder(orderId) {
    const { showDayReceipts } = this.state;
    const { lunchMeals, dinnerMeals } = showDayReceipts;
    LOGGING &&
      console.log("handleCancelOrder called", {
        showDayReceipts,
        lunchMeals,
        dinnerMeals,
        orderId,
      });
    this.props.cancelOrderByOps(orderId, {
      success: () => {
        toast.success("cancel order success");
        this.setState({ loading: true });
        this.props.getDayOrders([...lunchMeals, ...dinnerMeals]).then(() => {
          window.scrollTo(0, 0);
          this.setState({ loading: false });
        });
      },
    });
  }

  handleCloseDayReceipts(e) {
    e.preventDefault();
    this.setState({ showDayReceipts: null });
  }

  handleShowDayReceipts(day, lunchMeals, earlyDinnerMeals, dinnerMeals, e) {
    e.preventDefault();
    LOGGING &&
      console.log("handleShowDayReceipts called with: ", {
        day,
        lunchMeals,
        dinnerMeals,
      });
    const lunchMealsEnriched = lunchMeals
      ? lunchMeals.map((m) => ({ ...m, mealType: "lunch" }))
      : [];
    const dinnerMealsEnriched = dinnerMeals
      ? dinnerMeals.map((m) => ({ ...m, mealType: "dinner" }))
      : [];
    const earlyDinnerMealsEnriched = earlyDinnerMeals
      ? earlyDinnerMeals.map((m) => ({ ...m, mealType: "earlyDinner" }))
      : [];
    this.setState({
      loading: true,
      showDayReceipts: {
        day,
        lunchMeals: lunchMealsEnriched,
        earlyDinnerMeals: earlyDinnerMealsEnriched,
        dinnerMeals: dinnerMealsEnriched,
      },
    });
    this.props
      .getDayOrders([
        ...lunchMealsEnriched,
        ...earlyDinnerMealsEnriched,
        ...dinnerMealsEnriched,
      ])
      .then(() => {
        window.scrollTo(0, 0);
        this.setState({ loading: false });
      });
  }

  handleExpandDay(day, e) {
    e.preventDefault();
    LOGGING && console.log("handleExpandDay called with day: ", day);
    let { showDayMeals } = this.state;
    showDayMeals[day.lunchWindowStart] = true;
    this.setState({ showDayMeals });
  }

  handleCollaposeDay(day, e) {
    e.preventDefault();
    let { showDayMeals } = this.state;
    LOGGING && console.log("handleCollaposeDay called with day: ", day);
    showDayMeals[day.lunchWindowStart] = false;
    this.setState({ showDayMeals });
  }

  handleMoveMonth(m, e) {
    LOGGING && console.log("handleMoveMonth called with m:", m);
    e.preventDefault();
    this.setState({ loading: true });
    const { firstDayOfMonth } = this.state;
    const now = moment();
    const firstDayOfNextMonth = firstDayOfMonth.clone().add(m, "months");
    LOGGING &&
      console.log(
        "handleMoveMonth got firstDayOfNextMonth:",
        firstDayOfNextMonth.format("LLLL"),
        firstDayOfMonth.format("LLLL")
      );
    const { firstDayOfCalendar, lastDayOfCalendar, month, currentMonth } =
      getMonth(firstDayOfNextMonth, now);
    LOGGING && console.log("handleMoveMonth got currentMonth:", currentMonth);
    this.props
      .fetchMeals({
        start: firstDayOfCalendar.startOf("d").valueOf(),
        end: lastDayOfCalendar.endOf("d").valueOf(),
      })
      .then((windowStartsWithStats) => {
        this.setState(
          {
            month,
            currentMonth,
            loading: false,
            windowStartsWithStats,
            firstDayOfMonth: firstDayOfNextMonth,
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      });
  }
  handleSaveMeal(e, mealId) {
    e.preventDefault();
    const {
      selectedDishes,
      selectedRestaurant,
      selectedDeliveryZone,
      showRestaurantListFor,
      supportEarlyDelivery,
      selectedPreOrderedDishes,
      mealNotes,
      cutOff,
      defaultCutOff,
    } = this.state;

    const { deliveryZones } = this.props;

    const { day, mealType, windowStart } = showRestaurantListFor;
    LOGGING &&
      console.log("handleSaveMeal called with:", {
        windowStart,
        cutOff,
        showRestaurantListFor,
        defaultCutOff,
      });
    let earlyCutoffTime = null;
    if (
      cutOff.hour !== defaultCutOff.hour ||
      cutOff.minute !== defaultCutOff.minute ||
      cutOff.year !== defaultCutOff.year ||
      cutOff.month !== defaultCutOff.month ||
      cutOff.date !== defaultCutOff.date
    ) {
      earlyCutoffTime = moment(windowStart)
        .set("hour", cutOff.hour)
        .set("minute", cutOff.minute)
        .set("year", cutOff.year)
        .set("month", cutOff.month - 1)
        .set("date", cutOff.date)
        .valueOf();
    }

    this.setState(
      {
        loading: true,
        showRestaurantListFor: {
          day: null,
          mealType: null,
        },
      },
      () => {
        const meal = {
          dishes: Array.from(selectedDishes),
          preOrderedDishes: selectedPreOrderedDishes,
          restaurant: this.props.restaurants.filter(
            (r) => r.level === RestaurantLevel.Admitted
          )[selectedRestaurant]?._id,
          earlyCutoffTime,
          windowStart: day.date
            .clone()
            .hour(kCritialHourMinute[mealType].deliveryStart[0])
            .minute(kCritialHourMinute[mealType].deliveryStart[1])
            // .hour(isLunch ? 11 : 18)
            // .minute(isLunch ? 30 : 0)
            .valueOf(),
          supportEarlyDelivery: false,
          supportedDeliveryZone:
            selectedDeliveryZone.length > 0
              ? selectedDeliveryZone
              : deliveryZones.map((d) => d._id),
          _id: mealId,
          notes: mealNotes,
        };

        // if (draft) {
        //   meal.draft = true;
        // }

        // if (notes) {
        //   meal.notes = notes;
        // }

        this.props.saveMeal(meal).then(() => {
          // fetch restaurants
          this.props.readRestaurants();
          let { showDayMeals } = this.state;
          showDayMeals[day.lunchWindowStart] = false;
          this.setState({
            showRestaurantListFor: {
              day: null,
              mealType: null,
            },
            selectedPreOrderedDishes: [],
            selectedDishes: [],
            selectedRestaurant: -1,
            supportEarlyDelivery: undefined,
            selectedDeliveryZone: [],
            mealNotes: "",
            showDayMeals,
            loading: false,
            showRestaurantDetails: false,
          });
        });
      }
    );
  }
  handleEditMeal(day, mealType, meal, e) {
    e.preventDefault();
    const { meals, restaurants } = this.props;
    const {
      restaurant,
      dishes,
      supportEarlyDelivery,
      supportedDeliveryZone,
      notes,
      earlyCutoffTime,
      windowStart,
    } = meal;
    LOGGING &&
      console.log("handleEditMeal got: ", {
        meals,
        restaurants,
        mealType,
        day,
        meal,
        notes,
        restaurant,
        supportedDeliveryZone,
      });
    const showRestaurantListFor = {
      day,
      mealType,
      mealId: meal._id,
      windowStart,
    };
    if (restaurant) {
      const selectedRestaurant = restaurants
        .filter((r) => r.level === RestaurantLevel.Admitted)
        .findIndex((r) => r._id === restaurant._id);
      const { windowStart } = meal;
      const thisMealType =
        moment(windowStart).hour() < 12
          ? "lunch"
          : moment(windowStart).hour() < 17
          ? "earlyDinner"
          : "dinner";
      LOGGING &&
        console.log("handleEditMeal found:", {
          selectedRestaurant,
          windowStart,
        });
      const cutOffTime =
        earlyCutoffTime ||
        moment(windowStart)
          .add(CutOffFromDeliveryStartInMinutes[thisMealType], "m")
          .valueOf();
      LOGGING &&
        console.log("handleEditMeal got cutOffTime:", {
          cutOffTime,
          windowStart,
          meal,
          earlyCutoffTime,
          CutOffFromDeliveryStartInMinutes,
        });
      const hour = moment(cutOffTime).tz(CATimeZone).hour();
      const minute = moment(cutOffTime).tz(CATimeZone).minute();
      const year = moment(cutOffTime).tz(CATimeZone).year();
      const month = moment(cutOffTime).tz(CATimeZone).month() + 1;
      const date = moment(cutOffTime).tz(CATimeZone).date();
      const cutOff = { hour, minute, year, month, date };
      const defaultCutOff = { hour, minute, year, month, date };

      // Backend returns dishes without pupulate to speed up the page load.
      const selectedDishes = dishes.map((d) => d._id || d);

      this.setState({ loading: true });
      this.props.fetchDishesForRestaurant(restaurant._id).then((dishes) => {
        const categoriesForRestaurant =
          getSortedCategoryNamesFromDishes(dishes);
        LOGGING &&
          console.log("got categories for restaurant:", {
            categoriesForRestaurant,
            supportedDeliveryZone,
          });
        // use same struct
        // const copiedPreOrderedDishes = cloneDeep(preOrderedDishes);
        // copiedPreOrderedDishes.forEach((dish) => {
        //   dish.dish = dish.dish._id;
        // });
        LOGGING &&
          console.log("about to set state:", {
            categoriesForRestaurant,
            supportedDeliveryZone,
            selectedRestaurant,
            dishes,
            showRestaurantListFor,
            showRestaurantDetails: true,
            selectedDishes,
            loading: false,
            supportEarlyDelivery,
            categoriesForRestaurant,
            selectedDeliveryZone: supportedDeliveryZone.map((z) => z._id),
            mealNotes: notes,
            cutOff,
            defaultCutOff,
          });
        this.setState({
          selectedRestaurant,
          dishes,
          showRestaurantListFor,
          showRestaurantDetails: true,
          selectedDishes,
          loading: false,
          supportEarlyDelivery,
          categoriesForRestaurant,
          selectedDeliveryZone: supportedDeliveryZone.map((z) => z._id),
          mealNotes: notes,
          cutOff,
          defaultCutOff,
        });
      });
    } else {
      this.setState({
        showRestaurantListFor,
        showRestaurantDetails: false,
        loading: false,
        supportEarlyDelivery,
        selectedDeliveryZone: supportedDeliveryZone.map((z) => z._id),
        mealNotes: notes,
      });
    }
  }

  handleSaveMeals(e) {
    e.preventDefault();
    const { consideringRestaurantIds, showRestaurantListFor } = this.state;
    // const { restaurants } = this.props;
    LOGGING &&
      console.log("handleSaveMeals called with:", consideringRestaurantIds);

    const { day, mealType } = showRestaurantListFor;
    // const selectedRestaurants = restaurants.filter(
    //   (r, index) => r.level === RestaurantLevel.Admitted && selectedRestaurantIds.includes(index)
    // );

    this.setState(
      {
        loading: true,
        showRestaurantListFor: {
          day: null,
          mealType: null,
        },
      },
      () => {
        this.props
          .saveMealsBatch({
            restaurantIds: consideringRestaurantIds,
            windowStart: day.date
              .clone()
              .hour(kCritialHourMinute[mealType].deliveryStart[0])
              .minute(kCritialHourMinute[mealType].deliveryStart[1])
              .valueOf(),
          })
          .then(() => {
            // fetch restaurants
            this.props.readRestaurants();
            let { showDayMeals } = this.state;
            showDayMeals[day.lunchWindowStart] = false;
            this.setState({
              showRestaurantListFor: {
                day: null,
                mealType: null,
              },
              selectedPreOrderedDishes: [],
              selectedDishes: [],
              selectedRestaurants: [],
              consideringRestaurantIds: [],
              selectedRestaurant: -1,
              supportEarlyDelivery: undefined,
              selectedDeliveryZone: [],
              mealNotes: "",
              showDayMeals,
              loading: false,
            });
          });
      }
    );
  }

  handleToggleDish(dishId, e) {
    e.preventDefault();
    const { restaurants } = this.props;
    const { selectedRestaurant } = this.state;
    var { selectedDishes, selectedPreOrderedDishes } = this.state;
    LOGGING &&
      console.log("handleToggleDish called with:", {
        dishId,
        selectedDishes,
        selectedRestaurant,
        restaurants,
        selectedPreOrderedDishes,
      });

    const { preOrderOnly } = restaurants.filter(
      (r) => r.level === RestaurantLevel.Admitted
    )[selectedRestaurant];
    if (preOrderOnly) {
      if (selectedPreOrderedDishes.find((o) => o.dish === dishId)) {
        this.setState({
          selectedPreOrderedDishes: selectedPreOrderedDishes.filter(
            (o) => o.dish !== dishId
          ),
        });
      } else {
        this.setState({
          selectedPreOrderedDishes: [
            ...selectedPreOrderedDishes,
            { dish: dishId, quantity: 100 },
          ],
        });
      }
    } else {
      if (selectedDishes.includes(dishId)) {
        selectedDishes = selectedDishes.filter(function (value, index, arr) {
          return value != dishId;
        });
      } else {
        selectedDishes.push(dishId);
      }
      this.setState({ selectedDishes });
    }
  }

  handleSelectAllDishes(e) {
    e.preventDefault();
    const { restaurants } = this.props;
    const { dishes, selectedRestaurant } = this.state;
    const { preOrderOnly } = restaurants[selectedRestaurant];
    if (preOrderOnly) {
      const selectedPreOrderedDishes = dishes.map((d) => ({
        dish: d._id,
        quantity: 1,
      }));
      this.setState({ selectedPreOrderedDishes });
    } else {
      const selectedDishes = dishes.filter((d) => d.imageURL).map((d) => d._id);
      this.setState({ selectedDishes });
    }
  }

  handleSelectAllDishesInCategory(categoryName, e) {
    e.preventDefault();
    const { restaurants } = this.props;
    const {
      dishes,
      selectedRestaurant,
      selectedPreOrderedDishes,
      selectedDishes,
    } = this.state;
    const { preOrderOnly } = restaurants[selectedRestaurant];
    LOGGING &&
      console.log("handleSelectAllDishesInCategory called with: ", {
        dishes,
        selectedRestaurant,
        selectedPreOrderedDishes,
        selectedDishes,
        preOrderOnly,
        categoryName,
      });
    if (preOrderOnly) {
      this.setState({
        selectedPreOrderedDishes: [
          ...selectedPreOrderedDishes,
          ...dishes
            .filter((d) => (d) => d.imageURL && d.categoryName === categoryName)
            .map((d) => ({ dish: d._id, quantity: 100 })),
        ],
      });
    } else {
      this.setState({
        selectedDishes: [
          ...selectedDishes,
          ...dishes
            .filter((d) => d.imageURL && d.categoryName === categoryName)
            .map((d) => d._id),
        ],
      });
    }
  }

  handleDeselectAllDishes(e) {
    e.preventDefault();
    this.setState({ selectedDishes: [], selectedPreOrderedDishes: [] });
  }

  handleDeselectAllDishesInCategory(categoryName, e) {
    e.preventDefault();
    const { restaurants } = this.props;
    const {
      selectedRestaurant,
      selectedDishes,
      selectedPreOrderedDishes,
      dishes,
    } = this.state;
    const { preOrderOnly } = restaurants[selectedRestaurant];
    LOGGING &&
      console.log("handleDeselectAllDishesInCategory called with ", {
        categoryName,
        selectedRestaurant,
        selectedDishes,
        selectedPreOrderedDishes,
        dishes,
      });
    if (preOrderOnly) {
      this.setState({
        selectedPreOrderedDishes: dishes
          .filter(
            (d) =>
              selectedPreOrderedDishes.includes(d._id) &&
              d.categoryName !== categoryName
          )
          .map((d) => ({ dish: d._id, quantity: 100 })),
      });
    } else {
      this.setState({
        selectedDishes: dishes
          .filter(
            (d) =>
              selectedDishes.includes(d._id) && d.categoryName !== categoryName
          )
          .map((d) => d._id),
      });
    }
  }
  handleGoBack() {
    LOGGING && console.log("PageMenuCalendar handleGoBack");
    this.setState({
      showRestaurantListFor: {
        day: null,
        mealType: null,
      },
    });
  }

  handleToggleRestaurantInMealBatch(restaurantId, e) {
    e.preventDefault();
    const { consideringRestaurantIds } = this.state;
    LOGGING &&
      console.log("handleToggleRestaurantInMealBatch called with :", {
        consideringRestaurantIds,
        restaurantId,
      });
    if (!consideringRestaurantIds.includes(restaurantId)) {
      this.setState({
        consideringRestaurantIds: [...consideringRestaurantIds, restaurantId],
      });
    } else {
      this.setState({
        consideringRestaurantIds: consideringRestaurantIds.filter(
          (s) => s !== restaurantId
        ),
      });
    }
  }

  handleCloseRestaurant(e) {
    e.preventDefault();
    LOGGING && console.log("handleCloseRestaurant called");
    this.setState({
      showRestaurantListFor: {
        day: null,
        mealType: null,
      },
      selectedPreOrderedDishes: [],
      selectedDishes: [],
      selectedRestaurants: [],
      consideringRestaurantIds: [],
      selectedRestaurant: -1,
      supportEarlyDelivery: undefined,
      selectedDeliveryZone: [],
      mealNotes: "",
      // showDayMeals: {},
      showRestaurantDetails: false,
      loading: false,
    });
  }

  handleCancelRestaurant(e) {
    e.preventDefault();
    LOGGING && console.log("handleCancelRestaurant called");
    this.setState({
      showRestaurantListFor: { day: null, mealType: null },
      selectedRestaurant: -1,
    });
  }

  handleSelectRestaurant(day, mealType) {
    // e.preventDefault();
    const { meals } = this.props;
    const windowStart =
      mealType === "lunch"
        ? day.lunchWindowStart
        : mealType === "earlyDinner"
        ? day.earlyDinnerWindowStart
        : day.dinnerWindowStart;
    const restaurants = meals
      .filter((m) => m.windowStart === windowStart)
      .map((m) => ({
        _id: m?.restaurant?._id,
        confirmedWithRestaurant: m.confirmedWithRestaurant,
      }));

    let selectedRestaurants = {};
    restaurants.forEach((r) => {
      selectedRestaurants[r._id] = r.confirmedWithRestaurant;
    });
    LOGGING &&
      console.log("handleSelectRestaurant called with:", {
        selectedRestaurants,
        restaurants,
        day,
        mealType,
        windowStart,
      });
    this.setState({
      showRestaurantListFor: { day, mealType },
      selectedRestaurants,
      consideringRestaurantIds: [],
    });
  }

  handleEditDish(dish) {
    this.setState({
      isDishEditOpen: true,
      dishEdit: dish,
    });
  }

  handleEditDishClose() {
    this.setState({
      isDishEditOpen: false,
    });
  }

  handleEditDishSave(dish) {
    const { dishes } = this.state;
    const copiedDishes = cloneDeep(dishes);
    copiedDishes.forEach((copiedDish) => {
      if (copiedDish._id === dish._id) {
        copiedDish.name = dish.name;
        copiedDish.description = dish.description;
        copiedDish.priceFoodieListed = dish.priceFoodieListed;
        copiedDish.imageURL = dish.imageURL;
      }
    });
    this.setState({
      isDishEditOpen: false,
      dishes: copiedDishes,
    });
    this.props.saveDish(dish).then(() => {
      toast.success("Dish save success!");
    });
  }

  componentDidMount() {
    LOGGING && console.log("PageMenuCalendar componentdidmount");
    const now = moment();
    const firstDayOfMonth = now.clone().startOf("month");
    const { firstDayOfCalendar, lastDayOfCalendar, month, currentMonth } =
      getMonth(firstDayOfMonth, now);
    this.setState({ loading: true });
    this.props.fetchDeliveryZones().then(() => {
      LOGGING &&
        console.log(
          "PageMenuCalendar fetchDeliveryZones",
          this.props.deliveryZones
        );

      this.props.readRestaurants().then(() => {
        this.props
          .fetchMeals({
            start: firstDayOfCalendar.startOf("d").valueOf(),
            end: lastDayOfCalendar.endOf("d").valueOf(),
          })
          .then((windowStartsWithStats) => {
            const todayDay = moment().day();
            const planningWeekSunday = moment()
              .add(todayDay ? 2 : 1, "week")
              .day(0);

            this.setState(
              {
                month,
                windowStartsWithStats,
                currentMonth,
                loading: false,
                firstDayOfMonth,
                planningWeekSunday,
              },
              () => {
                // bring top of the todayRef be 2 * ${Navheight}px below the top of screen
                this.handleGoToToday();
              }
            );
          });
      });
    });
  }

  render() {
    const { currentUser, restaurants, deliveryZones, meals, dayOrders } =
      this.props;
    const {
      windowStartsWithStats,
      currentMonth,
      loading,
      month,
      selectedRestaurants,
      consideringRestaurantIds,
      showDayReceipts,
      showRestaurantListFor,
      isDishEditOpen,
      dishEdit,
      showDayMeals,
      showRestaurantContact,
      zoom,
      center,
      showRestaurantDetails,
      categoriesForRestaurant,
      dishes,
      selectedDishes,
      selectedRestaurant,
      supportEarlyDelivery,
      selectedDeliveryZone,
      mealNotes,
      swapWindowStarts,
      cutOff,
      swapping,
      planningWeekSunday,
      filterByTimeAndCuisine,
      showCuisineFilter,
    } = this.state;
    const { day, mealType, mealId } = showRestaurantListFor;
    LOGGING &&
      console.log("PageMenuCalendar rendering with:", {
        props: this.props,
        state: this.state,
      });

    // const mealsFilteredByDeliveryZone = Object.fromEntries(
    //   Object.entries(meals).filter(
    //     ([key]) =>
    //       meals[key].supportedDeliveryZone.map((item) => item._id).includes(selectedDeliveryZone) ||
    //       meals[key].supportedDeliveryZone?.length == 0
    //   )
    // );
    if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page calendar">
        <Navbar noShadow={true} hideAdmin={true} />
        <MenuCalendarHeader
          currentMonth={currentMonth}
          onMoveMonth={this.handleMoveMonth}
        />
        {showRestaurantDetails ? (
          <EditMeal
            restaurant={
              selectedRestaurant >= 0 ? restaurants[selectedRestaurant] : null
            }
            categoriesForRestaurant={categoriesForRestaurant}
            day={day}
            dishes={dishes.filter((d) => d.imageURL)}
            mealType={mealType}
            mealId={mealId}
            onBack={this.handleGoBack}
            onChangePreOrderQuantity={this.handleChangePreOrderQuantity}
            onClose={this.handleCancelRestaurant}
            onCloseRestaurant={this.handleCloseRestaurant}
            onDeselectAllDishes={this.handleDeselectAllDishes}
            onDeselectAllDishesInCategory={
              this.handleDeselectAllDishesInCategory
            }
            onSaveMeal={this.handleSaveMeal}
            onSelectAllDishes={this.handleSelectAllDishes}
            onSelectAllDishesInCategory={this.handleSelectAllDishesInCategory}
            onToggleDish={this.handleToggleDish}
            onViewRestaurant={this.handleViewRestaurant}
            restaurants={restaurants.filter(
              (r) => r.level === RestaurantLevel.Admitted
            )}
            selectedDishes={selectedDishes}
            selectedRestaurant={selectedRestaurant}
            showRestaurantDetails={showRestaurantDetails}
            supportEarlyDelivery={supportEarlyDelivery}
            deliveryZones={deliveryZones}
            onSelectDeliveryZone={this.handleSelectDeliveryZone}
            selectedDeliveryZone={selectedDeliveryZone}
            onEditDish={this.handleEditDish}
            mealNotes={mealNotes}
            onEditMealNotes={this.handleEditMealNotes}
            onSetHeroImage={this.handleSetHeroImage}
            cutOff={cutOff}
            onEditCutOff={this.handleEditCutOff}
          />
        ) : month ? (
          day ? (
            <React.Fragment>
              <RestaurantMap
                zoom={zoom}
                center={center}
                day={day}
                mealType={mealType}
                mealId={mealId}
                onBack={this.handleGoBack}
                onSelectRestaurant={this.handleToggleRestaurantInMealBatch}
                onSaveMeals={this.handleSaveMeals}
                restaurants={restaurants.filter(
                  (r) => r.level === RestaurantLevel.Admitted
                )}
                selectedRestaurants={selectedRestaurants}
                consideringRestaurantIds={consideringRestaurantIds}
              />
              <DishEditModal
                isOpen={isDishEditOpen}
                dish={dishEdit}
                onSave={this.handleEditDishSave}
                onClose={this.handleEditDishClose}
              />
            </React.Fragment>
          ) : showDayReceipts ? (
            loading ? (
              <Loading />
            ) : (
              <DayReceipts
                onClose={this.handleCloseDayReceipts}
                day={showDayReceipts.day}
                // lunchMeal={{ ...showDayReceipts.lunchMeal, orders: dayOrders.lunch || [] }}
                // dinnerMeal={{ ...showDayReceipts.dinnerMeal, orders: dayOrders.dinner || [] }}
                ordersByMeal={dayOrders}
                onRefund={this.handleRefund}
                onCancelItem={this.handleCancelItem}
                onCancelOrder={this.handleCancelOrder}
              />
            )
          ) : (
            <>
              <PopUp
                isPoppedUp={showRestaurantContact}
                componentToDisplay={
                  <RestaurantContact
                    meal={showRestaurantContact}
                    onToggleConfirm={this.handleToggleMealConfirmStatus}
                    onToggleAsian={this.handleToggleAsian}
                    onToggleNonAsian={this.handleToggleNonAsian}
                    onToggleAll={this.handleToggleAll}
                    onSave={this.handleSaveMealConfirmStatus}
                    onCancel={this.handleHideRestaurantContact}
                  />
                }
                hidePopUp={this.handleHideRestaurantContact}
                // backgroundColor="transparent"
              />
              {loading ? (
                <Loading />
              ) : (
                <MenuCalendar
                  currentMonth={currentMonth}
                  meals={meals}
                  month={month}
                  deliveryZones={deliveryZones}
                  onSelectRestaurant={this.handleSelectRestaurant}
                  onBack={this.handleGoBack}
                  onEditMeal={this.handleEditMeal}
                  onDeleteMeal={this.handleDeleteMealConfirm}
                  onDeleteWindowStart={this.handleDeleteWindowStart}
                  onMoveMonth={this.handleMoveMonth}
                  onShowDayReceipts={this.handleShowDayReceipts}
                  showDayMeals={showDayMeals}
                  onShowDayMeals={this.handleExpandDay}
                  onHideDayMeals={this.handleCollaposeDay}
                  onShowRestaurantContact={this.handleShowRestaurantContact}
                  onHideRestaurantContact={this.handleHideRestaurantContact}
                  showRestaurantContact={showRestaurantContact}
                  windowStartsWithStats={windowStartsWithStats}
                  onToggleSwapWindowStart={this.handleToggleSwapWindowStart}
                  swapWindowStarts={swapWindowStarts}
                  onSwapMeals={this.handleSwapMeals}
                  swapping={swapping}
                  onRefreshCache={this.handleRefreshCache}
                  todayRef={this.todayRef}
                  onGoToPlanningWeek={this.handleGoToPlanningWeek}
                  onGoToToday={this.handleGoToToday}
                  planningWeekRef={this.planningWeekRef}
                  planningWeekSunday={planningWeekSunday}
                  onFetchMealTime={this.handleFetchMealTime}
                  onQuickDeleteMeal={this.handleQuickDeleteMeal}
                  filterByTimeAndCuisine={filterByTimeAndCuisine}
                  onFilterByTimeAndCuisine={this.handleFilterWeekByCuisines}
                  showCuisineFilter={showCuisineFilter}
                  onToggleShowCuisineFilter={this.handleToggleShowCuisineFilter}
                />
              )}
            </>
          )
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageMenuCalendar got redux state:", state);
  return {
    currentUser: state.currentUser,
    restaurants: state.restaurants,
    deliveryZones: state.deliveryZones,
    meals: state.meals,
    dayOrders: state.dayOrders,
  };
}

export default connect(mapStateToProps, {
  fetchMenu,
  readRestaurants,
  fetchDishesForRestaurant,
  fetchMeals,
  fetchDeliveryZones,
  getDayOrders,
  saveMeal,
  refundOrder,
  orderCancelItem,
  cancelOrderByOps,
  saveDish,
  saveMealsBatch,
  deleteMeal,
  confirmMeal,
  swapMeals,
  refreshCache,
  saveRestaurant,
  deleteMealByWindowStart,
  fetchMealTime,
})(PageMenuCalendar);
