import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { sendPhoneVerificationCode, signUp } from "src/store/actions";
import styled from "styled-components";
import validator from "validator";
import { AnonymousActionsWrapper } from "./Shared";
import {
  FormContainer,
  SignUpField,
  ButtonCreate,
  EnterCodeTitle,
} from "./Shared";
import { LOGGING } from "..";
import {
  Centered,
  CenteredByColumn,
  Gold,
  WarningRed,
  centered,
  text12300,
} from "../Shared";
const ButtonNext = styled.button`
  ${centered}
  ${text12300}
  font-size: 14px;
  /* text-transform: uppercase; */
  font-weight: 500;
  /* font-size: 15px; */
  padding: 0px 30px;
  height: 60px;
  /* line-height: 60px; */
  /* flex-grow: 1; */
  margin: 0px 20px;
  /* max-width: 300px; */
  /* align-self: stretch; */
  /* width: 100%; */
  text-align: center;
  color: black;
  opacity: 0.8;
  background-color: white;
  position: relative;
  @media only screen and (min-width: 800px) {
    width: 200px;
    align-self: auto;
    margin-left: 0px;
  }
`;
const ButtonVerify = styled(ButtonNext)`
  background-color: ${Gold};
  margin: 10px 20px;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  height: 80px;
  flex-grow: 1;
  align-self: stretch;
  width: calc(100% - 40px);
  ${({ disabled }) => disabled && "background-color: #f0f0f0;"}
`;
// background-color: ${Gold}

const VerificationForm = styled(CenteredByColumn)`
  width: 100vw;
  max-width: 400px;
  padding: 20px;
  /* align-items: stretch; */
  .verification-input {
    width: 100% !important;
    ${centered}
    input {
      margin-right: 0px;
    }
  }
`;
const ButtonSendAgain = styled.button`
  ${text12300}
  border-bottom  : 1px solid black;
  padding: 0px;
  margin-top: 30px;
  font-weight: 400;
`;

export const NewUserFormPhone = ({
  onSubmit,
  resetModelError,
  allowToSendCode,
}) => {
  const [phone, setPhone] = useState("");
  const [phoneCodeSentCount, setPhoneCodeSentCount] = useState(0);
  const [error, setError] = useState("");
  const [verifying, setVerifying] = useState(false);
  //   useEffect(() => {
  //   // Perform actions when error state changes
  //   // This will be triggered after verifyInputs sets the new error state
  //   LOGGING && console.log("NewUserFormPhone Error state updated:", error);
  // }, [error]);
  // scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onEditPhone = (value) => {
    if (resetModelError) {
      resetModelError();
    }
    setPhone(value);
  };

  function phoneFormat(input) {
    //returns (###) ###-####
    input = input.replace(/\D/g, "").substring(0, 10); //Strip everything but 1st 10 digits
    var size = input.length;
    if (size > 0) {
      input = "(" + input;
    }
    if (size > 3) {
      input = input.slice(0, 4) + ") " + input.slice(4);
    }
    if (size > 6) {
      input = input.slice(0, 9) + "-" + input.slice(9);
    }
    return input;
  }

  const verifyPhone = () => {
    setPhoneCodeSentCount(phoneCodeSentCount + 1);
    let newError = "";
    let allGood = true;

    LOGGING &&
      console.log(
        "verifyPhone called with phoneCodeSentCount:",
        phone,
        phoneCodeSentCount,
        allGood,
        newError
      );
    if (phone === "") {
      newError = "Phone number can not be empty";
      allGood = false;
    }
    if (!validator.isMobilePhone(phone)) {
      newError = "Please provid a valid phone number";
      allGood = false;
    }
    if (phoneCodeSentCount >= 5) {
      newError = "Too many codes have been sent";
      allGood = false;
    }
    if (!allowToSendCode) {
      newError = "Please wait for 1 min before sending another one";
      allGood = false;
    }
    LOGGING &&
      console.log(
        "verifyPhone called with 2:",
        phone,
        phoneCodeSentCount,
        allGood,
        newError
      );
    setError(newError);
    return allGood;
  };
  LOGGING &&
    console.log("NewUserFormPhone rendering with:", { verifying, phone });
  return (
    <FormContainer fixed={true}>
      <SignUpField
        fieldName="phone"
        fieldValue={phoneFormat(phone)}
        onChange={onEditPhone.bind(this)}
        errorMessage={error}
        fieldValidator={validator.isMobilePhone.bind(this)}
        loading={verifying}
      />
      <ButtonCreate
        disabled={verifying}
        onClick={(e) => {
          e.preventDefault();
          LOGGING && console.log("ButtonCreate clicked");
          const verifyResult = verifyPhone();
          LOGGING &&
            console.log(
              "verifyPhone returned with verifyResult:",
              verifyResult
            );
          if (verifyResult) {
            setVerifying(true);
            onSubmit(phone.replace(/[\(\)\-\s]/g, ""));
          }
        }}
      >
        {verifying ? "checking..." : "continue"}
      </ButtonCreate>
    </FormContainer>
  );
};
export const PhoneCodeVerificationForm = ({
  onCheckPhoneVerificationCode,
  onSendPhoneVerificationCode,
  resetModalError,
  verifying,
  setVerifying,
}) => {
  const [phoneCode, setPhoneCode] = useState("");
  return (
    <VerificationForm>
      <EnterCodeTitle>
        Enter the 4 digit code sent to your phone:
      </EnterCodeTitle>
      <ReactCodeInput
        fields={4}
        type="number"
        values={Array.from(phoneCode)}
        onChange={(value) => {
          setPhoneCode(value);
          if (resetModalError) {
            resetModalError();
          }
        }}
        className="verification-input"
      />
      <ButtonVerify
        disabled={verifying}
        onClick={(e) => {
          e.preventDefault();
          setVerifying(true);
          onCheckPhoneVerificationCode(phoneCode);
        }}
      >
        {verifying ? "verifying..." : "verify"}
      </ButtonVerify>

      <ButtonSendAgain
        onClick={(e) => {
          e.preventDefault();
          onSendPhoneVerificationCode();
          toast.success("Sent code again!");
        }}
      >
        send code again
      </ButtonSendAgain>
    </VerificationForm>
  );
};
const message = [
  "Please enter a valid U.S. mobile phone number.",
  "Please enter the 4-digit verification number you received.",
  //`Please type the 4-digit verification code sent to ${phone}.`,
];
const ModalError = styled(Centered)`
  width: calc(100% - 60px);
  margin: 0px;
  margin-top: 20px;
  text-align: center;
  ${text12300}
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
  color: ${WarningRed};
`;
export const GetPhone = ({ onSuccess, inviteCode = null }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0); //0: get phone 1: verify phone
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [correctCode, setCorrectCode] = useState("");
  const [allowToSendCode, setAllowToSendCode] = useState(true);
  const [phoneCodeSentCount, setPhoneCodeSentCount] = useState(0);
  const [modalError, setModalError] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const timeoutBeforeAnotherCode =
    process.env.NODE_ENV === "development" ? 3000 : 60000;

  const resetModalError = () => {
    setModalError("");
  };
  const onSendPhoneVerificationCode = async (phoneNumber) => {
    try {
      // Dispatch the action to send the verification code
      LOGGING &&
        console.log(
          "onSendPhoneVerificationCode called with phoneNumber:",
          phoneNumber
        );
      setPhone(phoneNumber);
      setPhoneCodeSentCount(phoneCodeSentCount + 1);
      setModalError("");
      setLoading(true);
      setCorrectCode("");
      setAllowToSendCode(false);
      const result = await dispatch(sendPhoneVerificationCode(phoneNumber));
      LOGGING && console.log("sendPhoneVerificationCode result:", result);
      //@ts-ignore
      if (result === "0000") {
        setModalError("The phone number is already taken");
        setAllowToSendCode(true);
      } else {
        //@ts-ignore
        setCorrectCode(result);
        setStep(1);
        setTimeout(() => {
          setLoading(false);
        }, 300);
        setTimeout(() => {
          setAllowToSendCode(true);
        }, timeoutBeforeAnotherCode);
      }

      toast.success("Verification code sent!");
    } catch (error) {
      // Handle errors from the action
      console.error("Error sending verification code:", error);
      toast.error("Failed to send verification code. Please try again.");
    }
  };
  const onCheckPhoneVerificationCode = (phoneCode) => {
    if (phoneCode === "") {
      setModalError("Phone Code can not be empty");
      return;
    }
    if (phoneCode.length !== 4) {
      setModalError("Phone Code should be 4 digits");
      return;
    }
    setModalError(null);

    if (phoneCode === String(correctCode)) {
      onSuccess(phone);
    } else {
      setModalError("Incorrect Code");
    }
  };

  return (
    <>
      {modalError && <ModalError>{modalError}</ModalError>}
      {step === 0 ? (
        <NewUserFormPhone
          onSubmit={onSendPhoneVerificationCode}
          allowToSendCode={allowToSendCode}
          resetModelError={resetModalError}
        />
      ) : (
        <PhoneCodeVerificationForm
          verifying={verifying}
          setVerifying={setVerifying}
          resetModalError={resetModalError}
          onCheckPhoneVerificationCode={onCheckPhoneVerificationCode}
          onSendPhoneVerificationCode={onSendPhoneVerificationCode.bind(
            this,
            phone
          )}
        />
      )}
    </>
  );
};

export const GetPhoneAndSignUp = ({
  user,
  next,
  setParentError,
  treatPath = null,
}) => {
  LOGGING &&
    console.log("GetPhoneAndSignUp rendering with:", {
      user,
      next,
      treatPath,
    });
  const dispatch = useDispatch();
  const history = useHistory();
  const verifyInputs = (user) => {
    const { firstName, lastName, email, password, googleId } = user;
    setParentError("");
    if (firstName === "") {
      setParentError("First name can not be empty");
      return false;
    }
    if (lastName === "") {
      setParentError("Last name can not be empty");
      return false;
    }
    if (email === "") {
      setParentError("Email can not be empty");
      return false;
    }
    if (!validator.isEmail(email)) {
      setParentError("Please provide a valid email.");
      return false;
    }
    if (!googleId && password === "") {
      setParentError("Password can not be empty");
      return false;
    }
    return true;
  };
  const register = async (phone) => {
    try {
      if (verifyInputs(user)) {
        const result = await dispatch(signUp({ ...user, phone }));
        LOGGING && console.log("register got from server: ", result);
        if (next) {
          history.push({
            pathname: next,
            state: { treatPath },
          });
        } else {
          history.push("/");
        }
        history.push(next || "/");
      }
    } catch (error) {
      setParentError(error?.message || "Failed to register.");
    }
    //user + phne
  };

  return <GetPhone onSuccess={register} />;
};
