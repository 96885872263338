import { DriverMealStopTask } from './DriverMealStopTask';
import { DriverOrderStopTask } from './DriverOrderStopTask';

export const CurrentDriverTask = ({ task, totalNumTasks }) => {
  return (
    <div className="list driver-tasks" style={{ paddingBottom: '20px' }}>
      {task.taskType === 'PICKUP' ? (
        <DriverMealStopTask task={task} totalNumTasks={totalNumTasks} />
      ) : (
        <DriverOrderStopTask task={task} totalNumTasks={totalNumTasks} />
      )}
    </div>
  );
};
