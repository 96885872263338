import { useCallback } from 'react';
import { faCar, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectStopIndex = ({ stopNum, isAllowed, isSelected, onSelect, onDeselect, driverColor }) => {
  const toggleStopNum = useCallback(() => {
    if (isSelected) {
      onDeselect(stopNum);
    } else {
      onSelect(stopNum);
    }
  }, [stopNum, isSelected, onSelect, onDeselect]);

  const isSelectedStyle = isSelected
    ? {
        background: driverColor,
      }
    : {
        color: driverColor,
        borderColor: driverColor,
      };
  const isAllowedStyle = isAllowed
    ? {}
    : { background: '#5A5A5A', borderColor: '#5A5A5A', color: 'white', pointerEvents: 'none' };
  return (
    <button
      className={`stop ${isSelected ? 'selected' : 'unselected'}`}
      disabled={!isAllowed}
      onClick={toggleStopNum}
      style={{ ...isSelectedStyle, ...isAllowedStyle }}
    >
      <span>{stopNum + 1}</span>
    </button>
  );
};

const SelectStopIndexList = ({
  earliestAllowedStopNum,
  latestAllowedStopNum,
  selectedStopNum,
  onSelectStopNum,
  onDeselectStopNum,
  totalStops,
  driverColor,
}) => {
  return (
    <div className="select-stop">
      {Array.from(Array(totalStops).keys()).map((stopNum) => (
        <SelectStopIndex
          key={stopNum}
          stopNum={stopNum}
          onSelect={onSelectStopNum}
          onDeselect={onDeselectStopNum}
          isSelected={selectedStopNum === stopNum}
          isAllowed={stopNum >= earliestAllowedStopNum && stopNum <= latestAllowedStopNum}
          driverColor={driverColor}
        />
      ))}
    </div>
  );
};

const DriverStopSelector = ({
  driverId,
  isSelected,
  color,
  label,
  selectedStopNum,
  earliestAllowedStopNum,
  latestAllowedStopNum,
  totalStops,
  onSelectDriver,
  onDeselectDriver,
  onSelectStopNum,
  onDeselectStopNum,
}) => {
  const toggleDriverOnStop = useCallback(() => {
    if (isSelected) {
      onDeselectDriver(driverId);
    } else {
      onSelectDriver(driverId);
    }
  }, [isSelected, driverId, onSelectDriver, onDeselectDriver]);

  const assignStopNum = useCallback(
    (stopNum) => {
      onSelectStopNum(driverId, stopNum);
    },
    [driverId, onSelectStopNum]
  );
  const unassignStopNum = useCallback(
    (stopNum) => {
      onDeselectStopNum(driverId, stopNum);
    },
    [driverId, onDeselectStopNum]
  );

  return (
    <div className="driver-for-stop">
      <button
        className={`select-driver ${isSelected ? 'selected' : 'unselected'}`}
        style={
          isSelected
            ? { backgroundColor: color }
            : {
                color: color,
                border: `1px solid ${color}`,
                backgroundColor: 'white',
              }
        }
        onClick={toggleDriverOnStop}
      >
        <FontAwesomeIcon icon={faCar} />
        <span>{label}</span>
        {isSelected ? <FontAwesomeIcon icon={faCheck} /> : null}
      </button>
      {isSelected ? (
        <SelectStopIndexList
          earliestAllowedStopNum={earliestAllowedStopNum}
          latestAllowedStopNum={latestAllowedStopNum}
          selectedStopNum={selectedStopNum}
          onSelectStopNum={assignStopNum}
          onDeselectStopNum={unassignStopNum}
          totalStops={totalStops}
          driverColor={color}
        />
      ) : null}
    </div>
  );
};

export const DriverStopSelectorList = ({
  eligibleDrivers,
  onSelectDriver,
  onDeselectDriver,
  onSelectStopNum,
  onDeselectStopNum,
}) => {
  return (
    <div className="driver-list-on-map">
      {eligibleDrivers.map((driver) => (
        <DriverStopSelector
          key={driver.driverId}
          driverId={driver.driverId}
          label={driver.firstName}
          color={driver.color}
          isSelected={driver.isSelected}
          selectedStopNum={driver.selectedStopNum}
          totalStops={driver.totalAllowedStops}
          earliestAllowedStopNum={driver.earliestAllowedStopNum}
          latestAllowedStopNum={driver.latestAllowedStopNum}
          onSelectDriver={onSelectDriver}
          onDeselectDriver={onDeselectDriver}
          onSelectStopNum={onSelectStopNum}
          onDeselectStopNum={onDeselectStopNum}
        />
      ))}
    </div>
  );
};
