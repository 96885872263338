import React, { ReactElement, useCallback } from "react";
import { useAsync } from "react-async";
import { GetDishListByIds, SerializedGood } from "src/api/dish";
import { useSerializedGoodsToGoods } from "src/hooks/useHydrateGoodDishes";
import { removeArr } from "src/util/array";
import { getDishKey } from "src/util/order";
import Goods from "../Goods";

type Props = {
  goods: SerializedGood[];
  onSelectPreSelectedGoods: (selectedGoods: SerializedGood[]) => void;
};

export const GoodsContainer: React.FC<Props> = ({
  goods,
  onSelectPreSelectedGoods,
}): ReactElement => {
  const getNewPreSelectedGoods = useCallback(
    (goodToChange: SerializedGood, changeAmount: number) => {
      if (goodToChange.quantity + changeAmount <= 0) {
        return removeArr(goods, goodToChange, (el) =>
          getDishKey({ _id: el.dish }, el.selections, el.addedBy)
        );
      } else {
        goodToChange.quantity += changeAmount;
        return [...goods];
      }
    },
    [goods]
  );

  const changeDishQuantity = useCallback(
    (goodsKey: string, changeAmount: number) => {
      const goodToChange = goods.find(
        (good) => getDishKey({ _id: good.dish }, good.selections) === goodsKey
      );

      const newSelectedGoods = getNewPreSelectedGoods(
        goodToChange,
        changeAmount
      );
      onSelectPreSelectedGoods(newSelectedGoods);
    },
    [getNewPreSelectedGoods, goods, onSelectPreSelectedGoods]
  );

  const supplementedGoods = useSerializedGoodsToGoods(goods);
  if (supplementedGoods == null) return null;

  return (
    <div style={{ marginTop: "80px" }} className="receipt">
      <Goods
        goods={supplementedGoods}
        onChangeDishQuantity={changeDishQuantity}
        onEditDishComment={null}
        onSaveDish={null}
        isMember={false}
      />
    </div>
  );
};
