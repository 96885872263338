import React, { useCallback } from "react";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import "./CartDetails.scss";
import { OrderTips } from "src/components/OrderTips";
import {
  LOGGING,
  Back,
  DeliveryWindowInReceipt,
  Goods,
  GroupGoods,
  OrderTotal,
  Loading,
  OrderingWithCode,
  ReferralCodeError,
} from "./";
import { BottomBanner } from "./customers/banners/BottomBanner";
import { MembershipDetailsCard } from "./customers/banners/MemberSaving";
import { ToSaveByMembership } from "./customers/banners/MemberSaving";
import {
  MemberSaving,
  TooFar,
  Exclusive,
  ExclusiveNonMember,
  NonMemberSaving,
} from "./customers/banners/MemberSaving";
import { DirectCheckout } from "./customers/carts/DirectCheckout";
import { CountDown } from "./customers/mealMenu/CountDown";
import { ExistingGroupOrder } from "./customers/mealMenu/ViralActions";
import { Feature } from "../../../components/Feature";
import { OrderDiscount } from "../../../components/OrderDiscount";
import { getWindowForOrderTime } from "../../../util/time";
const CartDetails = ({
  cart,
  orderBy,
  isAuthenticated,
  isLunch,
  loading,
  initializing,
  onChangeDishQuantity,
  onChangeTips,
  onEditDishComment,
  onRemoveCoupon,
  onEmptyCart,
  onHome,
  onSaveDish,
  onShowAuth,
  onApplyCouponCode,
  couponCodeError,
  referralCode,
  stickyError,
  user,
  isMember,
  wasMember,
  onStartMembership,
  onResumeMembership,
  onToggleShowJoin,
  showJoin,
  isGroupOrder,
  onOpenInvite,
  initialTip,
  isEditingTip,
  onDirectOrder,
}) => {
  const DEFAULT_ORDER_MINIMUM = 15;
  const minOrderValue = Math.max(
    DEFAULT_ORDER_MINIMUM,
    cart?.couponCode?.minTotal || 0,
    (!cart?.userGroup && cart?.meal?.restaurant?.minOrderValue) || 0
  );

  const { goods, window, couponCode, meal } = cart;
  const isNotOrganizer = user?._id !== cart?.user?._id;
  const { markups, memberOnly } = meal?.restaurant || {};
  const isExclusive =
    memberOnly ||
    (markups?.doordash?.isAvailable === false &&
      markups?.doordash?.isAvailable === false);
  const history = useHistory();
  const nextOrganizer = useCallback(() => {
    if (isAuthenticated) {
      history.push("/checkout");
      return;
    }

    onShowAuth();
  }, [history, isAuthenticated, onShowAuth]);

  const nextNonOrganizer = useCallback(() => {
    if (isAuthenticated) {
      history.goBack();
      return;
    }

    onShowAuth();
  }, [history, isAuthenticated, onShowAuth]);

  const isCartValid = minOrderValue == null || cart.total >= minOrderValue;
  const eligibleForDirectCheckout =
    !initializing &&
    !loading &&
    !isGroupOrder &&
    cart?.user?.deliveryInfo?.address &&
    cart?.user?.stripeInfo?.paymentMethodId &&
    cart?.user?.stripeInfo?.card?.last4 &&
    cart?.due > 0;

  const showMinOrderWarning =
    cart.total && minOrderValue && cart.total < minOrderValue;
  const showReferral = couponCodeError && !cart.promo;
  const showCoupon = cart.couponCode;
  const showOrganizerInfo = !loading && !isNotOrganizer;
  const showOutOfRange = cart.doordashComparables?.isOutOfRange;
  const showMemberSaving =
    cart.doordashComparables?.memberSaving?.totalSaving >= 0.01;
  const showNonMemberSaving =
    cart.doordashComparables?.nonMemberSaving?.totalSaving >= 0.01;

  LOGGING &&
    console.log("CartDetails rendering with:", {
      initializing,
      isNotOrganizer,
      isGroupOrder,
      cart,
      isEditingTip,
      referralCode,
      couponCodeError,
      couponCode,
      stickyError,
      minOrderValue,
      user,
      loading,
      isCartValid,
      isMember,
      wasMember,
      eligibleForDirectCheckout,
      showMinOrderWarning,
    });

  return (
    <div className={`list ${isLunch ? "lunch" : "dinner"}-cart`}>
      <div className="list-title cart-title">
        <Back onClick={onHome} />
        <span>{`${cart?.groupOrderType > -1 ? "group" : "my"} cart`}</span>
        <CountDown deadline={orderBy} />
      </div>
      {/* {isGroupOrder ? (
        <ExistingGroupOrder
          leaderName={`${cart?.user?.firstName}`}
          isLeader={cart?.user?._id === user?._id}
          onOpenInvite={onOpenInvite}
          isFromCart={true}
        />
      ) : null} */}

      {showReferral ? (
        <ReferralCodeError
          code={referralCode}
          error={couponCodeError}
          onRemoveCoupon={onRemoveCoupon}
          stickyError={stickyError}
        />
      ) : showCoupon ? (
        <OrderingWithCode
          code={cart.couponCode}
          hasError={Boolean(couponCodeError)}
        />
      ) : showOrganizerInfo ? (
        isMember ? (
          isExclusive ? (
            <Exclusive />
          ) : showOutOfRange ? (
            <TooFar cart={cart} />
          ) : showMemberSaving ? (
            <MemberSaving cart={cart} isMember={true} />
          ) : null
        ) : isExclusive ? (
          <ExclusiveNonMember />
        ) : showOutOfRange ? (
          <TooFar cart={cart} />
        ) : showNonMemberSaving ? (
          <NonMemberSaving cart={cart} />
        ) : null
      ) : null}
      {showMinOrderWarning ? (
        <div className="display-warning">
          <span>
            Orders need to be <strong>${minOrderValue}+</strong> for this
            restaurant. Spend{" "}
            <strong>${(minOrderValue - cart.total).toFixed(2)}</strong> more to
            checkout.
          </span>
        </div>
      ) : null}
      {!loading && Object.keys(goods).length > 0 && !isNotOrganizer && (
        <div className={`empty-cart`}>
          <button onClick={onEmptyCart}>
            <FontAwesomeIcon icon={faTrashAlt} />
            {/* <span>empty cart</span> */}
          </button>
        </div>
      )}
      {loading ? (
        <Loading transparent={true} />
      ) : (
        <div
          className={`list-body cart ${
            cart.groupOrderType > -1 ? "group" : ""
          } ${cart.couponCode ? "has-coupon" : ""} ${
            couponCodeError ? "has-error" : ""
          } ${isMember ? "member" : "non-member"} 
          ${
            cart.total >= minOrderValue || !minOrderValue
              ? "above-min"
              : "below-min"
          } ${initializing ? "initializing" : ""}`}
        >
          {Object.keys(goods).length === 0 ? (
            <div className={`receipt${isGroupOrder ? " group" : ""}`}>
              <div className="receipt-section cart-is-empty">
                <span>Your cart is empty.</span>
              </div>
            </div>
          ) : (
            <div className={`receipt${isGroupOrder ? " group" : ""} goods`}>
              {isGroupOrder ? (
                <GroupGoods
                  goods={Object.values(goods)
                    .map((g, i) => ({ ...g, key: Object.keys(goods)[i] }))
                    .filter((g) => g.quantity > 0)}
                  onChangeDishQuantity={
                    cart.promo?.amount ? null : onChangeDishQuantity
                  }
                  onEditDishComment={onEditDishComment}
                  onSaveDish={onSaveDish}
                  isMember={isMember}
                  myself={user}
                  organizer={cart?.user}
                  isNotOrganizer={isNotOrganizer}
                />
              ) : (
                <Goods
                  goods={Object.values(goods)
                    .map((g, i) => ({ ...g, key: Object.keys(goods)[i] }))
                    .filter((g) => g.quantity > 0)}
                  onChangeDishQuantity={
                    cart.promo?.amount ? null : onChangeDishQuantity
                  }
                  onEditDishComment={onEditDishComment}
                  onSaveDish={onSaveDish}
                  isMember={isMember}
                />
              )}
              {/* {user.userGroup === undefined || !user?.userGroup?.disableTip ? (
                <Feature name={FEATURES.ORDER_TIPS}> */}
              {isNotOrganizer ? null : (
                <OrderTips
                  onChangeTips={onChangeTips}
                  sum={cart.sum}
                  tax={cart.tax}
                  defaultTips={cart.tips}
                  initialTip={initialTip}
                  tipRate={cart.tipRate}
                  tipCustom={cart.tipCustom}
                  customerName={`${cart?.user?.firstName} ${cart?.user?.lastName}`}
                  isEditingTip={isEditingTip}
                />
              )}
              {/* </Feature>
              ) : null} */}
              {/* {!referralCode && ( */}
              {/* {user.userGroup !== undefined ? null : ( */}
              {/* <Feature name={FEATURES.COUPON_CODE}> */}
              {isNotOrganizer ? null : (
                <OrderDiscount
                  couponCode={cart?.couponCode?.code}
                  onApplyCouponCode={onApplyCouponCode}
                />
              )}
              {/* </Feature> */}
              {/* )} */}
              {/* )} */}
              <div className="receipt-section cart-summary">
                {meal?.mealGroup === 8 ? null : (
                  <DeliveryWindowInReceipt
                    orderWindow={getWindowForOrderTime(window.start)}
                  />
                )}
                {isNotOrganizer ? null : (
                  <OrderTotal
                    order={cart}
                    isMember={isMember}
                    wasMember={wasMember}
                    couponCodeError={couponCodeError}
                    onToggleShowJoin={onToggleShowJoin}
                    isExclusive={isExclusive}
                  />
                )}
              </div>
              {showJoin ? (
                <MembershipDetailsCard
                  saving={cart?.saving}
                  card={{}}
                  isAuthenticated={isAuthenticated}
                  wasMember={wasMember}
                  onStartMembership={onStartMembership.bind(
                    this,
                    "MembershipDetailsCard"
                  )}
                  onResumeMembership={onResumeMembership}
                  onHideCard={onToggleShowJoin}
                  source="CartDetails"
                />
              ) : null}
              {!isMember && !isNotOrganizer && !initializing ? (
                <ToSaveByMembership
                  cart={cart}
                  card={user?.stripeInfo?.card}
                  onStartMembership={onStartMembership.bind(
                    this,
                    "ToSaveByMembership"
                  )}
                  onResumeMembership={onResumeMembership}
                  wasMember={wasMember}
                  higher={eligibleForDirectCheckout}
                />
              ) : null}
              {eligibleForDirectCheckout && isCartValid ? (
                <DirectCheckout
                  onSubmit={onDirectOrder}
                  onEdit={isNotOrganizer ? nextNonOrganizer : nextOrganizer}
                />
              ) : (
                <BottomBanner
                  disabled={initializing || !isCartValid}
                  label={
                    initializing
                      ? "connecting..."
                      : isNotOrganizer
                      ? "Done Adding Items"
                      : "CHECKOUT"
                  }
                  onClick={isNotOrganizer ? nextNonOrganizer : nextOrganizer}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CartDetails;
