import "./Routes.scss";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faCommentDots } from "@fortawesome/pro-light-svg-icons";
import {
  faCar,
  faUser,
  faHouse,
  faUtensilsAlt,
  faCalendarAlt,
  faBarcode,
  faSpinnerThird,
  faSteeringWheel,
  faPhone,
  faCalculator,
  faRoute,
  faSackDollar,
  faWrench,
  faMeat,
  faDoorClosed,
  faGlass,
  faBell,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { Button } from "src/components/Button";
import { useForm } from "src/hooks";
import {
  receiptHourFormat,
  receiptHourFormatShort,
  receiptTimeFormat,
  receiptDateFormatShort,
  PhoneFormat,
  LOGGING,
} from ".";

const Stop = ({
  routeStartTime,
  stop,
  index,
  totalTasks,
  driverId,
  onSkipTask,
  onDeliverTask,
  onSaveCredit,
  onSaveAddressMap,
}) => {
  const { formData, onInputChange } = useForm({ message: "", picture: "" });
  // LOGGING && console.log(`Stop rendering with: `, stop);
  return (
    <div className="order">
      {stop.withDrink && (
        <div className="drink">
          <FontAwesomeIcon icon={faGlass} />
        </div>
      )}
      <div className="stop">Stop #{stop.stopNum + 1}</div>
      <div className="address-info">
        <div>
          <FontAwesomeIcon icon={faWrench} />
          <span>{stop.stopType[0].toUpperCase() + stop.stopType.slice(1)}</span>
        </div>
        {stop.stopType === "order" ? (
          <div>
            <FontAwesomeIcon icon={faMeat} />
            <span>{stop.restaurantName}</span>
          </div>
        ) : null}
        <div className="name">
          <FontAwesomeIcon icon={faUser} />
          <span>{stop.names.join(", ")}</span>
        </div>
        {stop?.stopType === "order" ? (
          <div className="phone">
            <FontAwesomeIcon icon={faBell} />
            <span>
              {stop?.ringDoorbell ? "Ring Doorbell" : "Do Not Ring Doorbell"}
            </span>
          </div>
        ) : null}
        {stop.phones != null ? (
          <div className="phone">
            <FontAwesomeIcon icon={faPhone} />
            <span>
              {stop.phones.map((phoneNum) => PhoneFormat(phoneNum)).join(", ")}
            </span>
          </div>
        ) : null}
        {stop?.phones?.[0] ? (
          <div className="message">
            {/* <label>{`text ${stop?.phones?.[0]}`}</label> */}
            <textarea
              name="message"
              placeholder="Type your message here"
              onChange={onInputChange}
              value={formData?.message}
            />
            <textarea
              name="picture"
              placeholder="Type the link to the picture here"
              onChange={onInputChange}
              value={formData?.picture}
            />
            <Button
              onClick={(e) => {
                e.preventDefault();
                fetch("https://foodie-watch-api.herokuapp.com/api/messages/", {
                  method: "put",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    To: stop?.phones[0],
                    Body: formData.message,
                    mediaUrl: formData.picture,
                  }),
                }).then(() => {
                  onInputChange({
                    currentTarget: { name: "message", value: "" },
                  });
                  onInputChange({
                    currentTarget: { name: "picture", value: "" },
                  });
                });
              }}
            >
              Send
            </Button>
          </div>
        ) : null}
        {stop?.stopType === "order" ? (
          <div className="message">
            <label>{stop?.userId}</label>
            <br />
            <label>Credit: {stop?.credit || 0}</label>
            <textarea
              name="credit"
              type="number"
              placeholder="Add more credit here"
              onChange={onInputChange}
              value={formData?.credit}
            />
            <Button
              onClick={(e) => {
                e.preventDefault();
                onSaveCredit(
                  stop.userId,
                  (Number(stop.credit) + Number(formData.credit)).toFixed(2),
                  {
                    success: () => {
                      onInputChange({
                        currentTarget: { name: "credit", value: "" },
                      });
                    },
                  }
                );
              }}
            >
              Save
            </Button>
            <label>Credit Spent: {stop?.creditSpent || 0}</label>
          </div>
        ) : null}
        {stop.addressMap ? (
          <div className="address-map">
            <img src={stop.addressMap} alt="address map" />
          </div>
        ) : null}
        {stop.stopType === "order" ? (
          <div className="message">
            <textarea
              name="addressMap"
              type="string"
              placeholder="Update address map here"
              onChange={onInputChange}
              value={formData?.addressMap}
            />
            <Button
              onClick={(e) => {
                e.preventDefault();
                onSaveAddressMap(stop.userId, formData.addressMap, {
                  success: () => {
                    onInputChange({
                      currentTarget: { name: "addressMap", value: "" },
                    });
                  },
                });
              }}
            >
              Save
            </Button>
          </div>
        ) : null}
        <div className="address">
          <FontAwesomeIcon icon={faHouse} />
          <span>{stop.address}</span>
        </div>
        {stop.notes != null ? (
          <div className="address">
            <FontAwesomeIcon icon={faDoorClosed} />
            <span>{stop.notes.join(", ")}</span>
          </div>
        ) : null}
        {stop.tips != null ? (
          <div className="tips">
            <FontAwesomeIcon icon={faSackDollar} />
            <span>${stop.tips?.join(", ")}</span>
          </div>
        ) : null}
      </div>
      <div className="analytics">
        {stop.messages.map((m) => (
          <div className="user-message">
            <div className="message-time">
              <FontAwesomeIcon icon={faCommentDots} />
              {moment(m.sentAt).format(receiptHourFormat)}
            </div>
            <div className="message-content">{m.content}</div>
          </div>
        ))}

        {stop.deliveryTime != null && stop.dispatchTime != null ? (
          <div className="delay">
            <div className="delay-reasons">
              <label>Delay at this stop</label>
              <span>Traffic, parking, walking, etc.</span>
            </div>
            <span
              className={`walk-time ${
                stop.deliveryTime -
                  stop.dispatchTime -
                  stop.estimatedTimeSpent * 60000 >
                4 * 60000
                  ? "extra-long"
                  : ""
              } ${
                stop.deliveryTime -
                  stop.dispatchTime -
                  stop.estimatedTimeSpent * 60000 <
                0
                  ? "negative-delay"
                  : ""
              }`}
            >
              {stop.deliveryTime && stop.dispatchTime
                ? `${
                    stop.deliveryTime -
                      stop.dispatchTime -
                      stop.estimatedTimeSpent * 60000 <
                    0
                      ? "-"
                      : ""
                  }${moment
                    .duration(
                      stop.deliveryTime -
                        stop.dispatchTime -
                        stop.estimatedTimeSpent * 60000
                    )
                    .humanize()}`
                : "TBD"}
            </span>
          </div>
        ) : null}
      </div>
      {!stop.deliveryTime && index < totalTasks - 1 ? (
        <button
          className="skip-stop"
          type="submit"
          onClick={onSkipTask.bind(this, { driverId, taskId: stop._id })}
        >
          skip
        </button>
      ) : null}
      {(!stop.deliveryTime || stop.isSkipped) &&
      index < totalTasks &&
      stop.stopType === "order" ? (
        <button
          className="skip-stop"
          type="submit"
          onClick={onDeliverTask.bind(this, { driverId, taskId: stop._id })}
        >
          deliver
        </button>
      ) : null}
    </div>
  );
};

const Route = ({
  route,
  onChangePaymentAmount,
  onSendPaymant,
  onChangeMessage,
  onSkipTask,
  onDeliverTask,
  onSaveCredit,
  onSaveAddressMap,
}) => {
  const sortedStops = route.stops.sort((a, b) => a.stopNum - b.stopNum);
  // console.log("Route rendering with", route);
  return (
    <div className="route">
      <div className="route-info">
        <div className="route-header">
          {route.withDrink && (
            <div className="drink">
              <FontAwesomeIcon icon={faGlass} />
            </div>
          )}
          <div className="driver">
            <FontAwesomeIcon icon={faCar} />
            <span>{route.driverFirstName || "N/A"}</span>
          </div>
          <div className="restaurant">
            <FontAwesomeIcon icon={faUtensilsAlt} />
            <span>{route.restaurantNames.join(" & ")}</span>
          </div>
          <div className="date">
            <FontAwesomeIcon icon={faCalendarAlt} />
            <span>
              {`${moment(route.startTime).format(receiptDateFormatShort)}
            ${moment(route.startTime).format(
              route.endTime ? receiptHourFormatShort : receiptHourFormat
            )}~${
                route.endTime
                  ? moment(route.endTime).format(receiptHourFormat)
                  : "TBD"
              }`}
            </span>
          </div>

          <div className="duration">
            <FontAwesomeIcon icon={faSteeringWheel} />
            <span>{`Driving ${route.realRouteTime} minutes`}</span>
          </div>

          <div className="duration">
            <FontAwesomeIcon icon={faCalculator} />
            <span>{`Total ETA ${route.estimatedRouteTime} minutes`}</span>
          </div>
          <div className="duration">
            <FontAwesomeIcon icon={faSackDollar} />
            <span>{`Total Tips $${route?.totalTip?.toFixed(2)}`}</span>
          </div>
          <div className="duration">
            <FontAwesomeIcon icon={faRoute} />
            <span>
              {`Total Actual ${
                route.endTime && route.startTime
                  ? moment(route.endTime).diff(
                      moment(route.startTime),
                      "minute"
                    ) + "minutes"
                  : "TBD"
              }`}
            </span>
          </div>
          <div className="route-id">
            <FontAwesomeIcon icon={faBarcode} />
            <span>{route._id}</span>
          </div>
        </div>
        {route.driverPaymentInfo.payTime ? (
          <div className="payment-info">
            <FontAwesomeIcon icon={faPaypal} />
            <span>
              ${route.driverPaymentInfo.amount.toFixed(2)} paid by PayPal at{" "}
              {moment(route.driverPaymentInfo.payTime).format(
                receiptTimeFormat
              )}
            </span>
          </div>
        ) : (
          <form
            className="driver-payment-form"
            onSubmit={onSendPaymant.bind(this, route._id)}
          >
            <div className="form-title">pay driver</div>
            <div className="field sum">
              <label>USD</label>
              <input
                defaultValue={route.driverPaymentInfo.amount}
                onChange={onChangePaymentAmount.bind(this, route._id)}
              />
            </div>
            <div className="field notes">
              <label>Notes</label>
              <input
                defaultValue={route.driverPaymentInfo.message}
                onChange={onChangeMessage.bind(this, route._id)}
                name="message"
              />
            </div>
            <button className="send-payment" type="submit">
              send
            </button>
            {route.driverPaymentInfo.paying && (
              <FontAwesomeIcon icon={faSpinnerThird} />
            )}
            {route.driverPaymentInfo.paymentErrormessage && (
              <b>{route.driverPaymentInfo.paymentErrormessage}</b>
            )}
          </form>
        )}
      </div>
      <div className="route-orders">
        {sortedStops.map((stop, index) => (
          <Stop
            stop={stop}
            index={index}
            totalTasks={route.stops.length}
            driverId={route.driverId}
            onSkipTask={onSkipTask}
            onDeliverTask={onDeliverTask}
            routeStartTime={route.startTime}
            key={stop._id}
            onSaveCredit={onSaveCredit}
            onSaveAddressMap={onSaveAddressMap}
          />
        ))}
      </div>
      <br />
    </div>
  );
};

const Routes = ({
  routes,
  onChangePaymentAmount,
  onSendPaymant,
  onChangeMessage,
  onSkipTask,
  onDeliverTask,
  onSaveCredit,
  onSaveAddressMap,
}) => {
  const sortedRoutes = [...routes];
  sortedRoutes.sort((a, b) => b.startTime - a.startTime);
  return (
    <div className="list routes">
      {sortedRoutes.map((route, i) => (
        <Route
          route={route}
          key={i}
          route_index={i}
          onChangePaymentAmount={onChangePaymentAmount}
          onSendPaymant={onSendPaymant}
          onChangeMessage={onChangeMessage}
          onSkipTask={onSkipTask}
          onDeliverTask={onDeliverTask}
          onSaveCredit={onSaveCredit}
          onSaveAddressMap={onSaveAddressMap}
        />
      ))}
    </div>
  );
};

export default Routes;
