import styled, { css } from "styled-components";
import { ReactComponent as GoogleMapLogo } from "../../../google-maps-2020-icon.svg";
export const Text12300 = styled.span`
  font-size: 12px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
`;

export const TextUPPER = styled.span`
  font-size: 28px;
  font-weight: 400;
  line-height: 35.2px;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
`;
export const breakPoint = 600;
export const BreakPoint = 800;
export const MealHeight = 135;
export const MealWidthHeightRatio = 0.8;
export const NavHeight = 75;
export const MealHeaderWidgetHeight = 100;
export const Yellow = "#ffe352";
export const EasterBlue = "#85dfd8";
export const HighlightBlue = "#218aff";
export const BackgroundBlue = "rgb(95, 138, 178)";
export const Beige = "#f6f4f0";
export const Lotus = "#f9ece5";
export const BorderGrey = "#dbd9d9";
export const YelpRed = "#ff1a1a";
export const WarningRed = "rgba(223, 44, 20)";
export const Blue = "#9ddcff";
export const VividBlue = "#296d9d";
export const Neon = "#eeff33";
export const Rose = "#ff9ad7";
export const Pink = "rgb(238 188 197)";
export const BackgroundPink = "#ffe3d4";
export const Grey = "rgba(228, 228, 228)";
export const LightBlue = "rgb(157, 220, 255, 0.75)";
export const BackgroundDarkGreen = " rgb(8, 107, 92)";
export const MechanicGreen = "#76ee59";
export const SpringYellow = "#e8e02b";
export const RibbonYellow = "#ffd90c";
export const SummerWatermelon = "#f35659";
export const SummerBlue = "#baf1e4";
export const GoogleMapBlank = "#e5e3df";
export const Bronze = "#cd7f32";
export const Gold = "rgb(255, 191, 0)";
export const Peach = "rgb(244, 189, 156)";
export const PinkPeach = "#ffe3d3";
export const Brown = "#bf9b30";
export const Purple = "#5f5cff";
export const GhostWhite = "#f7f9f9";
export const BannerWhite = "#dcdcdc";
export const BackgroundGrey = "rgb(241, 241, 241)";
export const TextGrey = "#757575";
export const Lavendar = "#cdbfd9";
export const WinterBlue = "#82caff";
export const LightShadow =
  "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.16) 0px 0px 5px 0px";
export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CenteredByColumn = styled(Centered)`
  flex-direction: column;
`;
export const CenteredByColumnLeft = styled(CenteredByColumn)`
  align-items: flex-start;
`;

export const TextSlogan = styled.span`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1.2px;
  font-family: "Montserrat", sans-serif;
`;
export const CardTitle = styled.span`
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.6px;
  font-size: 26px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
`;
const MapPinContainer = styled(Centered)`
  svg {
    /* font-size: 20px;     */
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
`;

export const MapPin = () => (
  <MapPinContainer>
    <GoogleMapLogo />
  </MapPinContainer>
);
export const CardSubTitle = styled.span`
  font-family: "Theano Didot", serif;
  letter-spacing: 0.6px;
  font-size: 260x;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 20px;
`;
export const TextTagline = styled.span`
  font-family: "Theano Didot", serif;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: lowercase;
  // @media only screen and (min-width: 1200px) {
  //   text-transform: uppercase;
  //   font-weight: 500;
  //   font-size: 20px;
  //   line-height: 24px;
  //   letter-spacing: 0.6px;
  //   font-family: 'Montserrat', sans-serif;
  // }
`;
interface OverlayProps {
  opacity?: number;
}

export const centered = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const centeredByColumn = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Overlay = styled.div<OverlayProps>`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${(props) => props.opacity || 0.4});
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  ${centeredByColumn}
`;
export const textUpper = css`
  font-size: 28px;
  font-weight: 400;
  line-height: 35.2px;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
`;

export const openingPopUp = css`
  left: max(40px, 50vw - 250px);
  top: max(${NavHeight}px, 50vh - 400px);
  width: calc(100vw - 80px);
  max-width: 500px;
  height: calc(100vh - ${NavHeight * 2}px);
  max-height: 800px;
  background-color: white;
  flex-direction: column;
  position: fixed;
  z-index: 2;
`;
export const text12300 = css`
  font-size: 12px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
`;

export const mobileNextAction = css`
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  width: auto;
  height: auto;
  margin: 0px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  padding: 0px 20px;
  span {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

export const withCurls = css`
  position: relative;
  line-height: 30px !important;
  :before,
  :after {
    content: "";
    position: absolute;
    top: 14px;
    display: block;
    width: 25px;
    height: 1px;
    background-color: rgb(219, 217, 217);
  }
  :before {
    left: calc(-25px - 5px);
  }
  :after {
    left: calc(100% + 5px);
  }
`;

export const text14300 = css`
  font-size: 14px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.8px;
  font-family: "Montserrat", sans-serif;
`;
export const text13500 = css`
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 1.8px;
  font-family: "Montserrat", sans-serif;
`;

export const upper14500 = css`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
`;
export const card = css`
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
  background-color: #ffffff;
`;
export const actionTransparent = css`
  ${centered}
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  padding: 12px 25px;
  min-width: 170px;
  height: 40px;
  border: 1px solid black;
  box-shadow: none;
  box-sizing: border-box;
`;

export const Card = styled.div`
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
  background-color: #ffffff;
`;
export const ActionTransparent = styled.button`
  ${centered}
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  padding: 12px 25px;
  min-width: 170px;
  height: 40px;
  border: 1px solid black;
  box-shadow: none;
  box-sizing: border-box;
`;
export const ActionBlack = styled.button`
  ${centered}
  background: black;
  opacity: 0.8;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  padding: 0px 30px;
  height: 40px;
  box-shadow: none;
  box-sizing: border-box;
  color: white;
`;

export const ActionUnderline = styled.button`
  ${centered}
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 2;
  border-bottom: 1px solid black;
  box-shadow: none;
  box-sizing: border-box;
`;
export const actionUnderline = css`
  ${centered}
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 2;
  border-bottom: 1px solid black;
  box-shadow: none;
  box-sizing: border-box;
`;
export const actionBlack = css`
  ${centered}
  background: black;
  opacity: 0.8;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  padding: 0px 30px;
  height: 40px;
  box-shadow: none;
  box-sizing: border-box;
  color: white;
`;
export const ActionGrey = styled.button`
  ${centered}
  background: rgba(228, 228, 228, 0.5);
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.8px;
  padding: 10px 15px;
  box-shadow: none;
  box-sizing: border-box;
`;
export const TextTitle = styled.span`
  font-family: "Theano Didot", serif;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 0.6px;
  text-transform: capitalize;
`;

// export const TextTagline = styled.span`
//   font-family: 'Montserrat', sans-serif;
//   font-weight: 400;
//   font-size: 13px;
//   letter-spacing: 0.6px;
//   line-height: 1.5;
//   /* opacity: 0.6; */
//   /* text-transform: capitalize; */
// `;

export const textTitle = css`
  font-family: "Theano Didot", serif;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 0.6px;
  text-transform: capitalize;
`;

export const actionGrey = css`
  ${centered}
  background: rgba(228, 228, 228, 0.5);
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.8px;
  padding: 10px 15px;
  box-shadow: none;
  box-sizing: border-box;
`;

export const tagLineText = css`
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.6px;
  line-height: 1.5;
`;

export const SectionHeader = styled(Centered)`
  flex-direction: column;
  margin-top: 20px;
  max-width: 100vw;
`;
export const SectionTitle = styled(Text12300)`
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid black;
`;
export const SectionNote = styled(Text12300)`
  padding: 0px 40px;
  font-size: 12px;
  line-height: 1.7;
`;
export const textOverflow = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const ALLCAPTEXT = css`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0.6px;
  line-height: 110%;
  text-transform: uppercase;
`;

export interface ListTitleProps {
  noShadow?: Boolean;
}
export const ListTitle = styled(Centered)<ListTitleProps>`
  ${card}
  width: 100%;
  max-width: 100vw;
  height: ${NavHeight}px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  box-shadow: ${(props) => (props.noShadow ? "none" : "")};
  span {
    ${textTitle}
  }
  button {
    position: fixed;
    top: 0px;
    left: 0px;
    height: ${NavHeight}px;
    width: ${NavHeight}px;
    font-size: 18px;
  }
`;

export const DownwardShadow = styled.div`
  box-shadow: 0 2px 2px 0px rgba(28, 29, 33, 0.15);
`;
export const UpwardShadow = styled.div`
  box-shadow: 0 -2px 8px 8px rgba(28, 29, 33, 0.15);
`;
export const SubNavHeight = 70;
export const BadgeHeight = 70;

export const SubNav = styled(Centered)`
  height: ${SubNavHeight}px;
  position: fixed;
  top: ${NavHeight}px;
  border-bottom: 1px solid rgb(217, 217, 217);
  ${textTitle}
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${Beige};
  z-index: 2;
  /* button {
    
    height: ${SubNavHeight}px;
    
    margin: 0px 20px;
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
    svg {
      display: none;
    }
  } */
  /* .view-button.selected {
    opacity: 1;
    border-bottom: 1px solid black;
    svg {
      display: inline-block;
      margin-right: 10px;
    }
  }  */
`;
interface ViewButtonProps {
  selected: boolean;
}

export const ViewButton = styled.button<ViewButtonProps>`
  height: ${SubNavHeight}px;
  opacity: ${(props) => (props.selected ? "1" : "0.2")};
  margin: 0px 20px;
  padding: 0px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  box-sizing: border-box;
  border-bottom: ${(props) =>
    props.selected ? "1px solid black" : "1px solid transparent"};

  /* svg {
    display: ${(props) => (props.selected ? "inline-block" : "none")};
    margin-right: 10px;
  }  */
`;

export const FormSection = styled(CenteredByColumnLeft)`
  padding: 0px;
  ${text12300}
  width: 100%;
  margin: 20px 0px;
`;
export const FormSectionBody = styled(CenteredByColumnLeft)`
  background-color: ${Beige};
  padding: 30px 20px;
  align-self: stretch;
  flex-grow: 1;
  div:first-child {
    margin-top: 0px;
  }
`;

export const FormRow = styled(Centered)`
  /* flex-direction: column; */
  width: 100%;
  justify-content: flex-start;
  align-content: stretch;
  align-self: stretch;
  flex-grow: 1;
  margin: 20px 0px 0px 0px;
  > label {
    text-transform: uppercase;
    margin-right: 15px;
    font-weight: 500;
  }
  > input {
    margin: 0px;
    margin-right: 20px;
    padding: 5px 10px;
    border: none;
    height: 30px;
    width: 100px;
  }
  > input[type="checkbox"] {
    width: 30px;
    flex-grow: 0;
    margin-right: 50px;
  }
  > button {
    margin-right: 10px;
  }
`;
export const stretchWidth = css`
  align-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
`;
