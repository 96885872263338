import React, { ReactElement, useCallback } from "react";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import {
  faTimes,
  faPercent,
  faGlobe,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsync } from "react-async";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { GetFavoritesOverview } from "src/api/restaurants";
import { UserType, useUserType } from "src/hooks/useUserType";
import { RootState } from "src/store";
import styled from "styled-components";
import logo from "../../logo_black_transparent.png";
import qr from "../../qr_source_card.png";
import { LOGGING } from "../shared";
import { Loading } from "./components";
import {
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Text12300,
  TextTitle,
  centered,
  card,
  WinterBlue,
  text12300,
  textTitle,
  Gold,
  Pink,
  Bronze,
  BackgroundPink,
  ALLCAPTEXT,
  SummerBlue,
} from "./components/Shared";

// import logo from logo_transparent.png using the right path

interface PageSMLinkPreviewProps {}

const ContentContainer = styled(CenteredByColumn)`
  /* width: 400px; */
  ${card}
  padding: 30px 60px 80px 60px;
  background-color: ${SummerBlue};
`;
const ContentBorder = styled(CenteredByColumn)`
  flex-grow: 1;
  align-self: stretch;
  border: 2px solid ${WinterBlue};
`;
const Footer = styled(Centered)`
  ${textTitle}
  font-size: 18px;
  line-height: 1;
`;

const Header = styled(Centered)`
  position: relative;
  bottom: -10px;
`;
const Slogan = styled.span`
  ${textTitle}
  font-size: 20px;
  line-height: 1;
`;
const Snow = styled.span`
  margin-right: 10px;
  margin-bottom: 5px;
  font-size: 60px;
`;
const Drink = styled.span`
  margin-left: 8px;
  margin-bottom: 10px;
  font-size: 60px;
  transform: rotate(20deg);
`;
const Title = styled(Centered)`
  ${text12300}
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 20px;
`;
const Benefit = styled(CenteredByColumn)`
  ${textTitle}
  font-size: 60px;
  font-weight: 600;
  /* text-transform: uppercase; */
  line-height: 1;
  /* margin: 5px 0px; */
  flex-direction: column;
  img.logo {
    width: 120px;
    margin-bottom: -15px;
  }
  img.qr {
    width: 180px;
    margin-bottom: 0px;
  }
  span {
    ${text12300}
    line-height: 1;
    font-size: 95px;
    font-weight: 700;
    margin: 10px 0px;
    text-align: center;
    position: relative;
    label {
      position: absolute;
      font-size: 24px;
      top: 5px;
    }
    svg {
      font-size: 24px;
      line-height: 32px;
      margin-left: 3px;
      margin-bottom: 2px;
    }
  }
`;
const Benefit1 = styled(CenteredByColumnLeft)`
  align-items: flex-end;
  margin-right: 10px;
  span:first-child {
    margin-bottom: 10px;
  }
`;
const Benefit2 = styled(CenteredByColumnLeft)`
  span:first-child {
    margin-bottom: 10px;
  }
`;
const BenefitIcon = styled(Centered)`
  display: none;
`;
const RegularContainer = styled(CenteredByColumn)`
  background-color: ${Gold}; //${WinterBlue};
  padding: 5px 20px 30px 20px;
  height: 500px;
  width: 300px;
  align-content: stretch;
  img {
    width: 100px;
    margin-bottom: 10px;
  }
`;
const Icons = styled(Centered)`
  align-self: stretch;
  /* border: 1px solid red; */
  justify-content: space-evenly;
  /* margin-bottom: 10px; */
  letter-spacing: 1px;
  font-size: 30px;
`;
const Icon = styled(Centered)`
  font-size: 38px;
`;
const TagLine = styled(Centered)`
  width: 225px;
  margin-top: 0px;
  /* align-self: stretch;
flex-grow: 1; */
  /* border: 1px solid black; */
  ${textTitle}
  text-align: center;
  text-transform: none;
  /* text-transform: uppercase; */
  font-weight: 500;
  /* margin-top: 5px; */
  font-size: 16px;
  line-height: 1.4;
`;

export const PageSMLinkPreview: React.FC<PageSMLinkPreviewProps> =
  (): ReactElement => {
    // 🧋❄️
    // ☃️🏂
    return (
      // winter treats (blue)
      <div className="page">
        <ContentContainer>
          <Header>
            <Snow>🍉</Snow>
            <Slogan>you are invited</Slogan>
            <Drink>🍰</Drink>
          </Header>
          <Title>SUMMER TREATS ON US!</Title>
          <Footer>Ends Tuesday 6.11.2024</Footer>
        </ContentContainer>
      </div>

      //regular
      // <div className="page">
      //   <RegularContainer>
      //     {/* <Icons>
      //       <Icon>🍔</Icon>
      //       <Icon>🍕</Icon>
      //       <Icon>🍣</Icon>
      //       <Icon>🌮</Icon>
      //       <Icon>🍜</Icon>
      //     </Icons> */}
      //     {/* <TagLine><FontAwesomeIcon icon={faGlobeAmericas} />foodie.earth</TagLine> */}
      //     <Benefit>
      //       {/* <Benefit1>
      //     <span>30%</span>
      //     <span>400%</span>
      //     </Benefit1>
      //     <Benefit2>
      //       <span>cheaper</span>
      //       <span>further</span>
      //     </Benefit2> */}
      //       {/* <span>
      //         30
      //         <FontAwesomeIcon icon={faPercent} /> cheaper
      //       </span>
      //       <span>
      //         4<FontAwesomeIcon icon={faTimes} /> farther
      //       </span> */}
      //       <img src={logo} className="logo" />
      //       <img src={qr} className="qr" />
      //       <span>$10</span>
      //     </Benefit>
      //     {/* <Benefit>
      //       <BenefitIcon>🛣️</BenefitIcon>
      //       <span>70% cleaner</span>
      //     </Benefit> */}

      //     <TagLine>
      //       Carpooled delivery,<br></br>1/2 price, 5x distance of DoorDash.
      //     </TagLine>
      //     {/* <Icons>
      //       🍣🥗🌮🍔🍕🧆🍜🥞🧁🍩

      //     </Icons> */}
      //   </RegularContainer>
      // </div>
    );
  };
