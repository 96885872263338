import React from "react";
import "./RestaurantDirectory.scss";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { EmailSchedule } from "src/components/EmailSchedule";
import { WeeklySchedule } from "src/components/WeeklySchedule";
import {
  Back,
  Select,
  OrderMethods,
  RestaurantLevels,
  RestaurantMeals,
  LOGGING,
} from ".";
import { CuisineSelector } from "./CuisineSelector";
import { RestaurantAdminDishList } from "./restaurants/RestaurantAdminDishList";

const RestaurantDetails = ({
  restaurant,
  meals,
  onClose,
  onEditRestaurant,
  onSaveRestaurant,
  onEditRestaurantContact,
  onToggleEdittingRestaurantInfo,
  isEdittingRestaurantInfo,
  showOrderMethods,
  onToggleShowOrderMethods,
  onSelectOrderMethod,
  showLevels,
  onToggleShowLevels,
  onSelectLevel,
  onTogglePreOrderOnly,
  onToggleDisallowSpecialInstruction,
  onToggleHasKidsMenu,
  onToggleHasVeggieMenu,
  onToggleScheduleChecked,
  onToggleAllowPartyOrders,
  onToggleDoordashDeliveryFee,
  onToggleMayDelay,
  onEditCommission,
  onEditTaxRate,
  onEditMinOrderValue,
  onEmailScheduleChange,
  onSetHeroImage,
  onCreateAccount,
  onSelectCuisines,
  onEditMaxOrders,
  onEditFlatFee,
  onEditFeePerMile,
  onEditWeeklyHours,
  onSaveNewCategory,
  onToggleHeroImages,
  onUpdateCategory,
}) => (
  <div className="list dishes">
    <div className="list-title">
      <span>{restaurant?.name || "new restaurant"}</span>
      {restaurant._id ? (
        <button
          className="toggle-editting-restaurant"
          onClick={onToggleEdittingRestaurantInfo}
        >
          <FontAwesomeIcon
            icon={isEdittingRestaurantInfo ? faChevronUp : faChevronDown}
          />
        </button>
      ) : null}

      <Back onClick={onClose} />
    </div>
    {isEdittingRestaurantInfo ? (
      <form className="restaurant">
        <div className="restaurant-section">
          <div className="restaurant-field restaurant-id">
            <label>Restaurant ID</label>
            <span className="id">{restaurant._id}</span>
            <button
              className="create-account"
              onClick={onCreateAccount.bind(this, restaurant._id)}
            >
              create account
            </button>
          </div>
          <div className="restaurant-field restaurant-level">
            <label>foodie level</label>
            <Select
              selected={restaurant.level}
              onSelect={onSelectLevel}
              options={[0, 1, 2]}
              optionWords={RestaurantLevels}
              showOptions={showLevels}
              onToggleShowOptions={onToggleShowLevels}
              onHideOptions={onToggleShowLevels}
              className="restaurant-level-list"
            />
          </div>
          <div className="restaurant-field restaurant-name">
            <label>name</label>
            <input
              defaultValue={restaurant.name}
              onChange={onEditRestaurant}
              name="name"
            />
          </div>
          <div className="restaurant-field restaurant-address">
            <label>address</label>
            <input
              defaultValue={restaurant.address}
              onChange={onEditRestaurant}
              name="address"
            />
          </div>
        </div>
        <div className="restaurant-section">
          <div className="restaurant-field restaurant-preorder">
            <label>pre-order only</label>
            <input
              type="checkbox"
              checked={restaurant.preOrderOnly}
              onChange={onTogglePreOrderOnly}
              name="preOrderOnly"
            />
            <label>may delay</label>
            <input
              type="checkbox"
              checked={restaurant.mayDelay}
              onChange={onToggleMayDelay}
              name="mayDelay"
            />
            <label>Disallow Special Instruction</label>
            <input
              type="checkbox"
              checked={restaurant.disallowSpecialInstruction}
              onChange={onToggleDisallowSpecialInstruction}
              name="disallowSpecialInstruction"
            />
            <label>Has Kids Menu?</label>
            <input
              type="checkbox"
              checked={restaurant.hasKidsMenu}
              onChange={onToggleHasKidsMenu}
              name="hasKidsMenu"
            />
            <label>Has Vegetarian Menu?</label>
            <input
              type="checkbox"
              checked={restaurant.hasVeggieMenu}
              onChange={onToggleHasVeggieMenu}
              name="hasVeggieMenu"
            />
          </div>
          <div className="restaurant-field restaurant-preorder">
            <label>Schedule Checked?</label>
            <input
              type="checkbox"
              checked={restaurant.scheduleChecked}
              onChange={onToggleScheduleChecked}
              name="scheduleChecked"
            />
            <label>Allow Party Orders?</label>
            <input
              type="checkbox"
              checked={restaurant.allowPartyOrders}
              onChange={onToggleAllowPartyOrders}
              name="allowPartyOrders"
            />
            <label>DoorDash Delivery Fee Is Flat Fee</label>
            <input
              type="checkbox"
              checked={restaurant.doordashDeliveryFee?.isFlatFee}
              onChange={onToggleDoordashDeliveryFee}
              name="doordashDeliveryFeeMethod"
            />
            <label>Flat Fee</label>
            <input
              defaultValue={restaurant.doordashDeliveryFee?.flatFee}
              onChange={onEditFlatFee}
              name="flatFee"
              type="number"
            />
            <label>Fee Per Mile</label>
            <input
              defaultValue={restaurant.doordashDeliveryFee?.feePerMile}
              onChange={onEditFeePerMile}
              name="feePerMile"
              type="number"
            />
          </div>
          <div className="restaurant-field order-method">
            <label>order method</label>
            <Select
              selected={restaurant.orderMethod}
              onSelect={onSelectOrderMethod}
              options={Object.keys(OrderMethods)}
              optionWords={OrderMethods}
              showOptions={showOrderMethods}
              onToggleShowOptions={onToggleShowOrderMethods}
              onHideOptions={onToggleShowOrderMethods}
              className="order-method-list"
            />
          </div>
          <div className="restaurant-field">
            <label>Cuisines</label>
            <CuisineSelector
              selectedCuisines={restaurant.cuisines ?? []}
              onSelectCuisines={onSelectCuisines}
            />
          </div>
          <div className="restaurant-field">
            <label>Weekly Schedule</label>
            <WeeklySchedule
              value={Object.fromEntries(
                [
                  "sunday",
                  "monday",
                  "tuesday",
                  "wednesday",
                  "thursday",
                  "friday",
                  "saturday",
                ].map((dayOfWeekPropKey, dayOfWeek) => [
                  dayOfWeekPropKey,
                  {
                    lunch: restaurant?.weeklySchedule?.[dayOfWeek]?.lunch,
                    earlyDinner:
                      restaurant?.weeklySchedule?.[dayOfWeek]?.earlyDinner,
                    dinner: restaurant?.weeklySchedule?.[dayOfWeek]?.dinner,
                  },
                ])
              )}
              onChange={onEditWeeklyHours}
            />
          </div>
          <div className="restaurant-field">
            <label>Max Orders</label>
            <input
              defaultValue={restaurant.maxOrders}
              onChange={onEditMaxOrders}
              name="maxOrders"
              type="number"
              step={1}
            />
          </div>

          <div className="restaurant-field order-notes">
            <label>order notes</label>
            <input
              defaultValue={
                restaurant.contact ? restaurant.contact.orderNotes : ""
              }
              onChange={onEditRestaurantContact}
              name="orderNotes"
            />
          </div>

          {restaurant.orderMethod === 1 && (
            <div className="restaurant-field">
              <label>Email Schedule</label>
              <EmailSchedule
                emailSchedule={restaurant.emailSchedule}
                onEmailScheduleChange={onEmailScheduleChange}
              />
            </div>
          )}
          <div className="restaurant-field last-call-time">
            <label>last call time</label>
            <input
              defaultValue={restaurant.lastCallTime}
              onChange={onEditRestaurant}
              name="lastCallTime"
            />
          </div>
          <div className="restaurant-field commission">
            <label>commission</label>
            <input
              defaultValue={restaurant.commission}
              onChange={onEditCommission}
              name="commission"
              type="number"
              step={1}
            />
            <div className="restaurant-field tax">
              <label>Tax Rate %</label>
              <input
                value={restaurant.taxRate}
                onChange={onEditTaxRate}
                name="taxRate"
                type="number"
              />
            </div>
          </div>
          <div className="restaurant-field min-order-value">
            <label>minimum order value</label>
            <input
              defaultValue={restaurant.minOrderValue}
              onChange={onEditMinOrderValue}
              name="minOrderValue"
              type="number"
              step={1}
            />
          </div>
        </div>
        <div className="restaurant-section">
          <div className="restaurant-field restaurant-email">
            <label>email</label>
            <input
              defaultValue={restaurant.contact ? restaurant.contact.email : ""}
              onChange={onEditRestaurantContact}
              name="email"
            />
          </div>
          <div className="restaurant-field restaurant-phone">
            <label>name</label>
            <input
              defaultValue={restaurant.contact ? restaurant.contact.name : ""}
              onChange={onEditRestaurantContact}
              name="name"
            />
          </div>
          <div className="restaurant-field restaurant-phone">
            <label>mobile</label>
            <input
              defaultValue={restaurant.contact ? restaurant.contact.mobile : ""}
              onChange={onEditRestaurantContact}
              name="mobile"
            />
          </div>
          <div className="restaurant-field restaurant-website">
            <label>ordering website</label>
            <input
              defaultValue={
                restaurant.contact ? restaurant.contact.orderWebsite : ""
              }
              onChange={onEditRestaurantContact}
              name="orderWebsite"
            />
          </div>
          <div className="restaurant-field restaurant-website">
            <label>restaurant website</label>
            <input
              defaultValue={
                restaurant.contact ? restaurant.contact.websiteUrl : ""
              }
              onChange={onEditRestaurantContact}
              name="websiteUrl"
            />
          </div>
          <div className="restaurant-field restaurant-website">
            <label>restaurant yelp</label>
            <input
              defaultValue={
                restaurant.contact ? restaurant.contact.yelpUrl : ""
              }
              onChange={onEditRestaurantContact}
              name="yelpUrl"
            />
          </div>
          <div className="restaurant-field restaurant-website">
            <label>restaurant instagram</label>
            <input
              defaultValue={
                restaurant.contact ? restaurant.contact.instagramUrl : ""
              }
              onChange={onEditRestaurantContact}
              name="instagramUrl"
            />
          </div>
        </div>
        <div className="restaurant-action-wrapper">
          <button
            className="save-restaurant"
            onClick={onSaveRestaurant}
            type="button"
          >
            save
          </button>
        </div>
      </form>
    ) : (
      <div></div>
    )}
    {restaurant._id ? (
      <React.Fragment>
        <div className="link-wrapper">
          <Link
            className="view-restaurant-history"
            to={`/restaurant/${restaurant._id}/history`}
          >
            history
          </Link>
          <Link
            className="view-restaurant-history"
            to={`/restaurant/${restaurant._id}/map`}
          >
            Map
          </Link>
        </div>
        <RestaurantAdminDishList
          bestSellers={restaurant.bestSellers}
          dishes={restaurant.dishes}
          restaurantId={restaurant._id}
          heroImageUrl={restaurant.heroImage}
          heroImages={restaurant.heroImages}
          categories={restaurant.categories}
          onSetHeroImageUrl={onSetHeroImage}
          onToggleHeroImages={onToggleHeroImages}
          onSaveNewCategory={onSaveNewCategory}
          onUpdateCategory={onUpdateCategory}
        />
        <RestaurantMeals meals={meals} />
      </React.Fragment>
    ) : null}
  </div>
);

export default RestaurantDetails;
