import React from "react";
import "./RestaurantSign.scss";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SignText = () => (
  <React.Fragment>
    <FontAwesomeIcon className="heart" icon={faHeart} />
    <span className="slogan">free delivery by</span>
    <span className="website-url">https://Foodie.earth</span>
    <span className="delivery-date">every thursday lunch</span>
    <div className="cities">
      <span>Stanford</span>
      <span>Palo Alto</span>
      <span>Los Altos</span>
      <span>Mountain View</span>
      <span>Sunnyvale</span>
      <span>Cupertino</span>
      <span>Santa Clara</span>
      <span>Menlo Park</span>
      <span>Saratoga</span>
      <span>Campbell</span>
      <span>Monte Sereno</span>
      <span>Atherton</span>
    </div>
  </React.Fragment>
);
const RestaurantSign = () => (
  <div className="restaurant-sign">
    <div className="variation a">
      <SignText />
    </div>
    <div className="variation 2">
      <SignText />
    </div>
  </div>
);

export default RestaurantSign;
