import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import {
  assignDriverToStop,
  assignStopNum,
  unassignDriverFromStop,
  unassignStopNum,
} from 'src/store/actions/adminDeliveryV2';
import { DriverStopSelectorList } from './DriverStopSelectorList';

export const DriverStopSelectorListContainer = ({ stopId, eligibleDrivers }) => {
  const dispatch = useDispatch();
  const store = useStore();

  const assignDriverToStopCallback = useCallback(
    async (driverId) => {
      await assignDriverToStop(driverId, stopId)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  const assignStopNumCallback = useCallback(
    async (driverId, stopNum) => {
      await assignStopNum(driverId, stopId, stopNum)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  const unassignDriverFromStopCallback = useCallback(
    async (driverId) => {
      await unassignDriverFromStop(driverId, stopId)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  const unassignStopNumCallback = useCallback(
    async (driverId) => {
      await unassignStopNum(driverId, stopId)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  return eligibleDrivers != null ? (
    <DriverStopSelectorList
      eligibleDrivers={eligibleDrivers}
      onSelectDriver={assignDriverToStopCallback}
      onDeselectDriver={unassignDriverFromStopCallback}
      onSelectStopNum={assignStopNumCallback}
      onDeselectStopNum={unassignStopNumCallback}
    />
  ) : null;
};
