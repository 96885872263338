import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Route, SetPickupTime } from "src/store/actions/adminDeliveryV2";
import { apiCall } from "src/store/api";
import styled from "styled-components";
import { LOGGING } from ".";
import { getTodayWindowStart, kCritialHourMinute } from "../../../util/time";
import { AwaitServerResponseContainer } from "./AwaitServerResponseContainer";
import { PickupOption } from "./PickupOption";
import { ActionBlack, card, Centered, Text12300 } from "./Shared";
// import { apiCall } from '../../../api';

const PickupOptionsContainer = styled(Centered)`
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0px 0 10px 0;
  padding: 40px 10px 60px 10px;
  position: relative;
  ${card}
`;
const Button = styled(ActionBlack)`
  position: absolute;
  bottom: 10px;
  :disabled {
    opacity: 0.4;
  }
`;
const Notice = styled(Text12300)`
  position: absolute;
  bottom: 20px;
  left: 140px;
  color: green;
  /* font-weight: 500; */
`;
const Title = styled(Text12300)`
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
`;
const Length = styled(Text12300)`
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
`;
const lunchOptions = [
  { hour: 11, minute: 0 },
  { hour: 11, minute: 5 },
  { hour: 11, minute: 10 },
  { hour: 11, minute: 15 },
  { hour: 11, minute: 20 },
  { hour: 11, minute: 25 },
  { hour: 11, minute: 30 },
  { hour: 11, minute: 35 },
  { hour: 11, minute: 40 },
  { hour: 11, minute: 45 },
  { hour: 11, minute: 50 },
  { hour: 11, minute: 55 },
  { hour: 11, minute: 0 },
];

const earlyDinnerOptions = [
  { hour: 15, minute: 0 },
  { hour: 15, minute: 5 },
  { hour: 15, minute: 10 },
  { hour: 15, minute: 15 },
  { hour: 15, minute: 20 },
  { hour: 15, minute: 25 },
  { hour: 15, minute: 30 },
  { hour: 15, minute: 35 },
  { hour: 15, minute: 40 },
  { hour: 15, minute: 45 },
  { hour: 15, minute: 50 },
];

const dinnerOptions = [
  { hour: 17, minute: 0 },
  { hour: 17, minute: 5 },
  { hour: 17, minute: 10 },
  { hour: 17, minute: 15 },
  { hour: 17, minute: 20 },
  { hour: 17, minute: 25 },
  { hour: 17, minute: 30 },
  { hour: 17, minute: 35 },
  { hour: 17, minute: 40 },
  { hour: 17, minute: 45 },
  { hour: 17, minute: 50 },
  { hour: 17, minute: 55 },
  { hour: 18, minute: 0 },
];

const PickupOptions = ({
  mealType,
  isLunch,
  onSelect,
  selected,
  onRoute,
  isRouting,
  routed,
}) => {
  const options =
    mealType === "lunch"
      ? lunchOptions
      : mealType === "earlyDinner"
      ? earlyDinnerOptions
      : dinnerOptions;
  return (
    <PickupOptionsContainer>
      <Title>select start time</Title>
      {options.map((option, index) => (
        <PickupOption
          onClick={onSelect.bind(this, option.hour, option.minute)}
          isSelected={
            option.hour === selected.hour && option.minute === selected.minute
          }
          key={index}
        >
          <span>{`${option.hour}:${option.minute
            .toString()
            .padStart(2, "0")}`}</span>
        </PickupOption>
      ))}
      <Length>{`route cap: ${selected.length / 60} min`}</Length>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRoute(isLunch, mealType);
        }}
        disabled={isRouting}
      >{`${isRouting ? "Routing..." : "Route"}`}</Button>
      {routed ? (
        <Notice>
          Routes ready, please <b>reload</b> and <b>re-estimate</b>!
        </Notice>
      ) : null}
    </PickupOptionsContainer>
  );
};

export const PickupTimeSelect = () => {
  const isLunch = useSelector((state) => state.adminDeliveryV2.filters.isLunch);
  const mealType = useSelector(
    (state) => state.adminDeliveryV2.filters.mealType
  );
  const start = useSelector((state) => state.start);
  const { hour, minute } = start;

  const [pickupOption, setPickupOption] = useState(start);
  const [isRouting, setIsRouting] = useState(false);
  const [routed, setRouted] = useState(false);

  const fetchDataQuery = useCallback(
    async (dispatch, getState) => {
      console.log("fetchDataQuery called with:", { isLunch, mealType, hour });
      const pickupHour = kCritialHourMinute[mealType].driverPickup[0];
      const mealTypeChanged = hour !== pickupHour;
      const windowStart = getTodayWindowStart(mealType);
      console.log("fetchDataQuery called with:", {
        windowStart,
        isLunch,
        mealType,
        mealTypeChanged,
      });
      await SetPickupTime(
        windowStart,
        mealTypeChanged ? undefined : pickupOption.hour,
        mealTypeChanged ? undefined : pickupOption.minute
      )(dispatch, getState);
    },
    [isLunch, mealType, pickupOption]
  );

  const onSelect = (hour, minute) => {
    setPickupOption({ hour, minute });
  };

  const onRoute = useCallback(
    async (isLunch, mealType) => {
      // don't send again while we are sending
      if (isRouting) return;
      // update state
      setIsRouting(true);
      // send the actual request
      await apiCall("post", `/routes/CalcRoutesForMeal`, { isLunch, mealType });
      // once the request is sent, update state again
      setIsRouting(false);
      setRouted(true);
    },
    [isRouting]
  ); // update the callback if the state changes

  // const onRoute = useCallback(
  //   async (e) => {
  //     e.preventDefault();
  //     console.log('onRoute called with:', { e, isLunch });
  //     setIsRouting(true);
  //     await Route(isLunch);
  //     console.log('serverRoute done');
  //     setIsRouting(false);
  //   },
  //   [isRouting]
  // );

  LOGGING &&
    console.log("PickupTimeSelect got:", {
      mealType,
      isLunch,
      start,
      pickupOption,
      isRouting,
    });

  return (
    <AwaitServerResponseContainer awaitable={fetchDataQuery}>
      <PickupOptions
        mealType={mealType}
        isLunch={isLunch}
        onSelect={onSelect}
        selected={start}
        onRoute={onRoute}
        isRouting={isRouting}
        routed={routed}
      />
    </AwaitServerResponseContainer>
  );
};
