export const testimonialList = [
  {
    name: "Robert P.",
    city: "Sunnyvale",
    joined: 1690737011173,
    benefit:
      "Being able to try a variety of restaurants I may not normally have at a great price with an easy process",
    who: "People who are open minded to trying food but don’t always have the time to go out and try them.",
  },
  {
    name: "Sheryl H.",
    city: "Cupertino",
    joined: 1684084632281,
    benefit:
      "That the restaurant selection changes (vs the fixed restaurant list I see on doordash for example); member discount pricing is nice to have",
    who: "People who don’t have time or prefer not to cook. Working professionals or retirees.",
  },
  {
    name: "Hannah L.",
    city: "Mountain View",
    joined: 1690309128978,
    benefit:
      "Happy with restaurant selection, a lot of restaurants with high ratings, some I order from before Foodie. Low fee.",
    who: "People who don’t have time or prefer not to cook. Working professionals or retirees. But must have a certain income level — eating out is expensive.",
  },
  {
    name: "David C.",
    city: "Los Altos",
    joined: 1683479977398,
    benefit:
      "Nice selection of restaurants with a good pricing model. Also improved driver model that's potentially more eco-friendly by bundling deliveries.",
    who: "Busy families that like the model and want to explore new restaurants.",
  },
  {
    name: "Jing W.",
    city: "Mountain View",
    joined: 1684084632281,
    benefit: "I can buy from restaurants far away and without markup.",
    who: "Those who don't cook and order from other delivery apps.",
  },
  {
    name: "Vivian C.",
    city: "Sunnyvale",
    joined: 1679930883957,
    benefit:
      "- Order last-min and still be able to enjoy the meal. (I don't plan that far ahead!) And no crazy fees. - I like the early delivery dinner option (express). - I like knowing that it's a real restaurant that people or I have gone physically. (I like seeing the quick Yelp ratings) - I like that it has a narrow (but good) selections of choices per meal. I can't pick if there are too many options.",
    who: "Families with busy schedules.",
  },
  {
    name: "Grace C.",
    city: "Los Altos",
    joined: 1666911367710,
    benefit: "limited, trusted options",
    who: "people who enjoy quality food choices and ease of choices, saving fuel costs hopefully.",
  },
  {
    name: "Jennifer G.",
    city: "Palo Alto",
    joined: 1665118993014,
    benefit:
      "I like that this service is convenient, offers great restaurant prices and affordable delivery, and feels better for the environment.",
    who: "Foodies that want to try a variety of vetted restaurants around the Bay Area.",
  },
  {
    name: "Helen C.",
    city: "Los Altos",
    joined: 1664472013195,
    benefit:
      "Fast, convenient meals, plus a wide variety of restaurants to choose from, with a list of restaurants that continues to grow.",
    who: "Busy professionals/families.",
  },
  {
    name: "Betty S.",
    city: "Saratoga",
    joined: 1664392798000,
    benefit:
      "My fav thing is being able to have some of my fav foods delivered without having to go to certain restaurants, esp if they are not super close by.",
    who: "Busy families with growing kids.",
  },
  {
    name: "Yesul S.",
    city: "Sunnyvale",
    joined: 1662938176301,
    benefit: "More affordable prices.",
    who: "Anyone who needs to save time and get meals more affordable than Uber eats.",
  },
  {
    name: "Sarah L.",
    city: "Sunnyvale",
    joined: 1660683664683,
    benefit: "Wide variety of cuisines.",
    who: "People too busy to cook, as well as those who want to try different cuisines.",
  },
  {
    name: "Sylvie Huang",
    city: "Sunnyvale",
    joined: 1658121296714,
    benefit: "Good restaurant selections and limited options everyday.",
    who: "People who order delivery food frequently.",
  },
  {
    name: "Yvette Chu",
    city: "Palo Alto",
    joined: 1653891960070,
    benefit: "Lots of choices and reasonable fees.",
    who: "Working parents, people with no transportation, people who like to try different cuisine.",
  },
  {
    name: "Mike Huang",
    city: "Palo Alto",
    joined: 1653433979283,
    benefit:
      "Convenience. Restaurants that are too far to go or order from DoorDash. Like to try new food. Responsive customer service.",
    who: "Working people. Busy. No time to look for new food. Need convenience. Enjoy new restaurants.",
  },
  {
    name: "Amanda H",
    city: "Sunnyvale",
    joined: 1653433979283,
    benefit:
      "I like how it's a set restaurant list every day - makes decision making easy. I also liked how in the early stages, it was only a $1 delivery fee (and even no fee before that!). It was a great alternative to the other usual doordash services.",
    who: "WFH people who don't have time to go out to pick up lunch, but also don't want to spend an exorbitant amount of money on lunch.",
  },
  {
    name: "Connor C",
    city: "Santa Clara",
    joined: 1650866698655,
    benefit:
      "Getting restaurant food delivered for the same price as eating at restaurant.",
    who: "People who don't have the time or energy to cook for themselves.",
  },
  {
    name: "Lindsey Tsai",
    city: "Santa Clara",
    joined: 1650401620060,
    benefit:
      "Their quick responsiveness and good customer service and versatile food selections.",
    who: "People with a lot of kids.",
  },
  {
    name: "Yuchun Ku",
    city: "Mountain View",
    joined: 1649690228161,
    benefit:
      "Good restaurant options Food arrived warm and fresh, and generally on time.",
    who: "Are Foodies but too busy to drive around the Bay Area to try new restaurants.",
  },
  {
    name: "Ramesh Bart",
    city: "Sunnyvale",
    joined: 1646845620362,
    benefit: "Convenience, trying new food/restaurants.",
    who: "Everyone who’s busy or has to deal with quarantine.",
  },
  {
    name: "Anita W",
    city: "Los Altos",
    joined: 1646845620362,
    benefit: "The choice in restaurants is better than doordash and uber eats.",
    who: "Anyone who wants a variety of restaurants instead of the same ones on DoorDash.",
  },
  {
    name: "Jerry M",
    city: "Sunnyvale",
    joined: 1644648549513,
    benefit:
      "I like the variety of different daily restaurant menu choices as my favorite thing about Foodie Earth.",
    who: "I think busy people benefit most from the Foodie Earth service.",
  },
  {
    name: "Melanie L",
    city: "Mountain View",
    joined: 1643792075487,
    benefit: "Simple choice simple ordering.",
    who: "Busy people who like good food.",
  },
  {
    name: "Deborah C",
    city: "Los Altos",
    joined: 1642971101220,
    benefit: "Great local restaurants without all the crazy markups.",
    who: "Everyone.",
  },
  {
    name: "Christina L",
    city: "Mountain View",
    joined: 1642783605629,
    benefit:
      "Trying new restaurants I haven't tried, no mark-up on food items if you are a member.",
    who: "Busy families who don't have time to cook or to think about what to order.",
  },
  {
    name: "Lynn Chung",
    city: "Sunnyvale",
    joined: 1642482928922,
    benefit:
      "pretty good prices, pretty good delivery (food comes fairly hot, good time - early enough to eat dinner), packing is generally pretty good, variety of food options is pretty good, website format pretty good, ordering and canceling is easy, discovering new restaurants!",
    who: "cheapoes who like to eat out a lot and are too lazy to go get it themselves or taking a break from door dash :)",
  },
  {
    name: "Chiin",
    city: "Cupertino",
    joined: 1635985704460,
    benefit:
      "Very little fee, no up charge on food prices, options are usually good.",
    who: "Busy people that don’t cook.",
  },
  {
    name: "Christine Tam",
    city: "San Jose",
    joined: 1632436221412,
    benefit:
      "Access to restaurants I normally wouldn’t get from other apps, supporting smaller local business.",
    who: "Working people who don’t have time to cook.",
  },
  {
    name: "Carol Osiecki",
    city: "Sunnyvale",
    joined: 1632361032187,
    benefit:
      "Creates less thinking. Just order our face dish from the restaurants and just arrives on time.",
    who: "Young start up workers who don't want to think to much about what to eat and just arrives everyday.",
  },
  {
    name: "Paul Cho",
    city: "Sunnyvale",
    joined: 1631251590642,
    benefit: "Limited options, good choices, convenience and fair prices.",
    who: "People who want convenience, good value, and not too many choices.",
  },
  {
    name: "Yu",
    city: "Sunnyvale",
    joined: 1631251590642,
    benefit:
      "No fee. Plan out. Don't need to think about what to do. Good restaurant combination of needs and favorites.",
    who: "Anybody, people who order takeout, people who are not organized.",
  },
  {
    name: "Lisa G",
    city: "Los Altos",
    joined: 1619330050609,
    benefit:
      "That the delivery is pooled - I feel it's more environmentally friendly.",
    who: "Busy people.",
  },
  {
    name: "Doug Ueno",
    city: "Mountain View",
    joined: 1617689922515,
    benefit:
      "Low prices. Wide menu variation. Responsive and helpful customer support. The menu looking ahead a week so I can plan out my meals.",
    who: "Those that work from home. People that want to try a wide variety of food.",
  },
  {
    name: "Claire Jiang",
    city: "Mountain View",
    joined: 1616543310674,
    benefit:
      "Cost transparency! No added costs to the menu or to delivery. Great restaurant options. Easy to use and user-friendly interface. Responsive customer services.",
    who: "Young professions.",
  },
  {
    name: "Hiroaki Yasutake",
    city: "Palo Alto",
    joined: 1613259233954,
    benefit:
      "Reasonable price, helping us to make a quick decision because of the limited restaurant options.",
    who: "busy people who work at home.",
  },
  {
    name: "Annette Motoyama",
    city: "Los Altos",
    joined: 1613239628224,
    benefit:
      "Reasonable price, helping us to make a quick decision because of the limited restaurant options.",
    who: "busy people who work at home.",
  },
  {
    name: "Susan Lei",
    city: "Mountain View",
    joined: 1611778007400,
    benefit:
      "I have discovered so many new local restaurants via Foodie. Plus, I like that someone reaches out to you directly if something needs adjusting in your order. The customer service is very responsive.",
    who: "Busy people with packed schedules who love good food from restaurants that they would not otherwise discover.",
  },
];
