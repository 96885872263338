import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useStore } from 'react-redux';
import { DriverTaskDetails } from 'src/screens/tasting_menu/components/DriverTaskDetails';
import { completeTaskForDriver } from 'src/store/actions/tasks';
import { DriverOrderTask } from './DriverOrderTask';

export const DriverMealStopTask = ({ task, totalNumTasks }) => {
  const [orderIdsPickedUp, setOrderIdsPickedUp] = useState({});
  const [isCompleteTaskLoading, setIsCompleteTaskLoading] = useState(false);

  const allOrdersToPickup = task.ordersToPickup;
  const remainingOrdersToPickup = allOrdersToPickup.filter(
    (order) => orderIdsPickedUp[order._id] == null
  );
  const ordersAlreadyPickedUp = allOrdersToPickup.filter(
    (order) => orderIdsPickedUp[order._id] != null
  );

  const pickupOrder = useCallback(
    (orderId) => {
      setOrderIdsPickedUp({ ...orderIdsPickedUp, [orderId]: true });
    },
    [orderIdsPickedUp]
  );
  const orderTaskConfirmMessageGenerator = useCallback((orderName) => {
    return `You have ${orderName}'s order?`;
  }, []);

  const dispatch = useDispatch();
  const store = useStore();
  const completeTask = useCallback(async () => {
    setIsCompleteTaskLoading(true);

    try {
      await completeTaskForDriver(task.taskId)(dispatch, store.getState);
    } catch (error) {
      setOrderIdsPickedUp({});
      if (error == null) {
        toast.error('An error occurred, check your network connection and try again');
      } else {
        toast.error(error.message);
      }
    }

    setIsCompleteTaskLoading(false);
  }, [dispatch, store.getState, task.taskId]);

  const remainingOrdersLength = remainingOrdersToPickup.length;
  const allOrdersLength = allOrdersToPickup.length;
  return (
    <>
      <h3 style={{ marginTop: '12px' }}>Task Details</h3>
      <DriverTaskDetails
        taskNum={task.taskNum}
        address={task.address}
        addressName={task.addressName}
        totalNumTasks={totalNumTasks}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '18px',
        }}
      >
        <h3 style={{ marginBottom: '10px', marginTop: '20px' }}>
          Orders Picked Up {`(${allOrdersLength - remainingOrdersLength}/${allOrdersLength})`}
        </h3>
        {remainingOrdersLength === allOrdersLength ? (
          <span>Nothing picked up yet...</span>
        ) : (
          ordersAlreadyPickedUp.map((order) => (
            <DriverOrderTask
              key={order._id}
              orderId={order._id}
              name={order.name}
              orderItems={order.goods}
              orderHash={order.orderHash}
            />
          ))
        )}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h3 style={{ marginBottom: '10px', marginTop: '20px' }}>Orders to Pickup</h3>
        {remainingOrdersToPickup.length === 0 ? (
          isCompleteTaskLoading ? (
            <div>Loading...</div>
          ) : (
            <button className="deliver-button" onClick={completeTask}>
              <span>Complete Task</span>
            </button>
          )
        ) : (
          remainingOrdersToPickup.map((order) => (
            <DriverOrderTask
              key={order._id}
              orderId={order._id}
              name={order.name}
              orderHash={order.orderHash}
              orderItems={order.goods}
              onComplete={pickupOrder}
              onCompleteButtonLabel="Pickup"
              confirmMessageGenerator={orderTaskConfirmMessageGenerator}
            />
          ))
        )}
      </div>
    </>
  );
};
