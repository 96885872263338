import React from "react";
import "./Nav.scss";
import {
  faUserCircle,
  faMapMarkedAlt,
  faReceipt,
  faCalendarEdit,
  faUtensilsAlt,
  faHome,
  faTrophyAlt,
  faRoute,
  faHatChef as faRestaurants,
  faGift,
  // faMailBulk,
  faUsers,
  faChartBar,
  faCog,
  faMoneyCheckAlt,
  faCars,
  faCalculatorAlt,
  // faGift,
  faSignOut,
  faSignIn,
  faUser as faPersonal,
  // faHomeAlt as faWeekly,
  // faCalendarAlt as faWeekly,
  faMapMarkerAlt as faLocation,
  faUsers as faGroup,
  faUsersClass,
  faTrophy,
  faPiggyBank as faMembership,
  faSlidersH,
} from "@fortawesome/pro-light-svg-icons";
import {
  faBars /* faChevronDown, faChevronUp, faCheck */,
  faMoneyBill,
} from "@fortawesome/pro-regular-svg-icons";
// import { faMapMarkerAlt as faLocation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Feature } from "src/components/Feature";
import { FEATURES } from "src/constants";
import {
  PopUp,
  Change /* CartThumbnail, LOGGING, getItemNumberInCart */,
} from ".";
const Logo = () => (
  <div className="logo">
    <div className="background-image"></div>
    <span>eat with neighbors.</span>
  </div>
);
const NavSingleMeal = ({
  onToggleUserMenu,
  showUserMenu,
  onSignOut,
  currentUser,
  noShadow,
  marketingEmailTemplate,
  onViewByWeekEmptyCart,
  cartIsFull,
  dishesLoaded,
  deliveryZoneByCity,
  onChangeDeliveryCity,
  selectedDeliveryCity,
  onToggleShowDeliveryCityOptions,
  showDeliveryCityOptions,
}) => {
  const profilePictureURL = currentUser.user?.profilePictureURL;
  return (
    <nav className={`${noShadow ? "no-shadow" : ""}`}>
      <div className="nav-content">
        <button className="user" type="button" onClick={onToggleUserMenu}>
          {profilePictureURL ? (
            <img src={profilePictureURL} />
          ) : (
            <FontAwesomeIcon
              icon={currentUser.isAuthenticated ? faUserCircle : faBars}
            />
          )}
        </button>
        {/* <Link className="go-to-weekly" to="/">
          <FontAwesomeIcon icon={faWeekly} />
        </Link> */}
        <Logo />
        <div
          className={`select-city ${
            currentUser.isAuthenticated ? "" : "anonymous"
          }`}
        >
          {selectedDeliveryCity ? (
            <>
              {currentUser.isAuthenticated ? null : (
                <span className="hi">Hi, </span>
              )}
              <Change
                options={deliveryZoneByCity.map((d) => d.city)}
                onChange={onChangeDeliveryCity}
                selected={selectedDeliveryCity}
                showOptions={showDeliveryCityOptions}
                onToggleShowOptions={onToggleShowDeliveryCityOptions}
                className="change-city"
                disabled={!dishesLoaded}
                icon={faLocation}
              />
            </>
          ) : null}
        </div>

        {currentUser.user.isAdmin ? (
          <div className="admin-quick-access">
            <div className="quick-access-container">
              <Link
                to="/"
                className={`${
                  !window.location.pathname.includes("calendar") &&
                  !window.location.pathname.includes("drivers") &&
                  !window.location.pathname.includes("current") &&
                  !window.location.pathname.includes("users") &&
                  !window.location.pathname.includes("leaders") &&
                  !window.location.pathname.includes("nominations") &&
                  !window.location.pathname.includes("stats") &&
                  !window.location.pathname.includes("restaurant-directory") &&
                  !window.location.pathname.includes("routes") &&
                  !window.location.pathname.includes("couponCodes") &&
                  !window.location.pathname.includes("config") &&
                  !window.location.pathname.includes("delivery")
                    ? "selected"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faHome} />
                <span>home</span>
              </Link>
              <Link
                to="/calendar"
                className={`${
                  window.location.pathname.includes("calendar")
                    ? "selected"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faCalendarEdit} />
                <span>calendar</span>
              </Link>
              <Link
                to="/accounting"
                className={`${
                  window.location.pathname.includes("accounting")
                    ? "selected"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faCalculatorAlt} />
                <span>accounting</span>
              </Link>
              <Link
                to="/delivery_v2"
                className={`${
                  window.location.pathname.includes("delivery")
                    ? "selected"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faMapMarkedAlt} />
                <span>delivery</span>
              </Link>
              <Link
                to="/current"
                className={`${
                  window.location.pathname.includes("current") ? "selected" : ""
                }`}
              >
                <FontAwesomeIcon icon={faReceipt} />
                <span>orders</span>
              </Link>
              <Link
                to="/restaurant-directory"
                className={`${
                  window.location.pathname.includes("restaurant-directory")
                    ? "selected"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faUtensilsAlt} />
                <span>restaurants</span>
              </Link>
              <Link
                to="/nominations"
                className={`${
                  window.location.pathname.includes("nominations")
                    ? "selected"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faTrophyAlt} />
                <span>nominations</span>
              </Link>
              <Link
                to="/stats"
                className={`${
                  window.location.pathname.includes("stats") ? "selected" : ""
                }`}
              >
                <FontAwesomeIcon icon={faChartBar} />
                <span>stats</span>
              </Link>
              <Link
                to="/routes"
                className={`${
                  window.location.pathname.includes("routes") ? "selected" : ""
                }`}
              >
                <FontAwesomeIcon icon={faRoute} />
                <span>Routes</span>
              </Link>
              <Link
                to="/users"
                className={`${
                  window.location.pathname.includes("users") ? "selected" : ""
                }`}
              >
                <FontAwesomeIcon icon={faUsers} />
                <span>Users</span>
              </Link>
              <Link
                to="/leaders"
                className={`${
                  window.location.pathname.includes("leaders") ? "selected" : ""
                }`}
              >
                <FontAwesomeIcon icon={faTrophy} />
                <span>Leaders</span>
              </Link>
              <Link
                to="/drivers"
                className={`${
                  window.location.pathname.includes("drivers") ? "selected" : ""
                }`}
              >
                <FontAwesomeIcon icon={faCars} />
                <span>drivers</span>
              </Link>
              <Link
                to="/couponCodes"
                className={`${
                  window.location.pathname.includes("couponCodes")
                    ? "selected"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faMoneyCheckAlt} />
                <span>Coupons</span>
              </Link>
              {/* <a href={`${marketingEmailTemplate}`}>
            <FontAwesomeIcon icon={faMailBulk} />
            <span>News</span>
          </a> */}
              <Link
                to="/config"
                className={`${
                  window.location.pathname.includes("config") ? "selected" : ""
                }`}
              >
                <FontAwesomeIcon icon={faCog} />
                <span>Config</span>
              </Link>
            </div>
          </div>
        ) : currentUser.user.isOps ? (
          <div className="admin-quick-access">
            <div className="quick-access-container">
              <Link
                to="/"
                className={`${
                  !window.location.pathname.includes("current") &&
                  !window.location.pathname.includes("restaurant-directory")
                    ? "selected"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faHome} />
                <span>home</span>
              </Link>
              <Link
                to="/current"
                className={`${
                  window.location.pathname.includes("current") ? "selected" : ""
                }`}
              >
                <FontAwesomeIcon icon={faReceipt} />
                <span>orders</span>
              </Link>
              <Link
                to="/restaurant-directory"
                className={`${
                  window.location.pathname.includes("restaurant-directory")
                    ? "selected"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faUtensilsAlt} />
                <span>restaurants</span>
              </Link>
            </div>
          </div>
        ) : currentUser.user.isRestaurant ? (
          <div className="nav-title">
            <div className="restaurant-title">
              <span>{currentUser?.user?.restaurant?.name}</span>
            </div>
          </div>
        ) : null}
        <PopUp
          isPoppedUp={showUserMenu}
          componentToDisplay={
            currentUser.isAuthenticated ? (
              <ul className="user-menu">
                <li>
                  <FontAwesomeIcon icon={faHome} />
                  <Link to="/">home</Link>
                </li>
                {currentUser.user.isUserGroupAdmin && (
                  <li>
                    <FontAwesomeIcon icon={faUsers} />
                    <Link to="/group-management">manage team</Link>
                  </li>
                )}

                {currentUser.user.isUserGroupAdmin && (
                  <li>
                    <FontAwesomeIcon icon={faUsersClass} />
                    <Link to="/team-orders">team orders</Link>
                  </li>
                )}
                <li>
                  <FontAwesomeIcon icon={faReceipt} />
                  <Link to="/orders">my orders</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faMembership} />
                  <Link to="/membership">membership</Link>
                </li>
                {/* <li>
                  <FontAwesomeIcon icon={faGift} />
                  <Link to="/referralCode">my gift code</Link>
                </li> */}
                <Feature name={FEATURES.GIFT_CARD}>
                  <li>
                    <Link to="/my/credit">my credit</Link>
                  </li>
                </Feature>
                <li>
                  <FontAwesomeIcon icon={faGroup} />
                  <Link to="/referrals">Referrals</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faGift} />
                  <Link to="/gifts">gift cards</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faRestaurants} />
                  <Link to="/restaurants">restaurants</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faSlidersH} />
                  <Link to="/setting">settings</Link>
                </li>

                <li>
                  <FontAwesomeIcon icon={faSignOut} />
                  <button type="button" onClick={onSignOut}>
                    sign out
                  </button>
                </li>
              </ul>
            ) : (
              <ul className="user-menu">
                <li>
                  <FontAwesomeIcon icon={faSignIn} />
                  <Link to="/signin">sign in</Link>
                </li>
                <li>
                  {/* <div className="icon-wrapper"> */}
                  <FontAwesomeIcon icon={faPersonal} />
                  {/* <FontAwesomeIcon icon={faPlus} /> */}
                  {/* </div> */}
                  <Link to="/signup">sign up</Link>
                </li>
                {/* <li>
                 
                  <FontAwesomeIcon icon={faGroup} />
                 
                 
                  <Link to="/signup-company">sign up as a company admin</Link>
                </li> */}
              </ul>
            )
          }
          hidePopUp={onToggleUserMenu}
          backgroundColor="transparent"
        />
      </div>
    </nav>
  );
};

export default NavSingleMeal;
