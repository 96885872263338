import React, { Fragment, ReactElement, useCallback, useState } from "react";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SerializedRestaurantGood,
  SerializedRestaurantOrder,
} from "src/api/meals";
import "./OrderReceipt.scss";
// import '../MealOrderList.scss';
import { getNameFromOrder } from "src/constants";
import { ObjectId } from "src/constants/types";
import { LOGGING, getItemNumberInCart, ItemWithoutQuantity, zeroPad } from "..";

type OrderType = SerializedRestaurantOrder & { index?: number };
type CancelItemFn = (
  orderId: ObjectId,
  index: number,
  cancelQuantity: number
) => void;
type CancelSelectionItemFn = (
  orderId: ObjectId,
  goodId: ObjectId,
  goodSelectionId: ObjectId,
  selectedItemId: ObjectId
) => void;
interface OrderReceiptProps {
  order: OrderType;
  onCancelItem?: CancelItemFn;
  onCancelSelectionItem?: CancelSelectionItemFn;
  receiptFont: boolean; // Used for printing actual receipts.
}

const SingleLineItem = ({
  dish,
  quantity,
  cancelQuantity,
  selections,
  specialInstructions,
  onCancelItem,
  itemCount,
  numberOfItems,
  onCancelSelectionItem,
}) => {
  const [
    cancelItemFormIsOpenForItemIndex,
    setCancelItemFormIsOpenForItemIndex,
  ] = useState(null);
  const { name, selections: dishSelections, priceFoodieListed } = dish;
  console.log("dish:", dish);
  const selectionItemCache = {};
  dishSelections?.forEach((selection) => {
    selection.items?.forEach((selectionItem) => {
      selectionItemCache[selectionItem._id] = selectionItem;
      console.log("selectionItem._id:", selectionItem._id);
    });
  });
  console.log("selections", selections);
  console.log("selectionItemCache:", selectionItemCache);
  const selectionItems = [];
  selections?.forEach((selection) => {
    selection.selectedItems?.forEach((selectionItem) => {
      console.log("selectionItem.item:", selectionItem.item);

      if (selectionItem.count - (selectionItem.cancelCount ?? 0) <= 0) return;

      selectionItems.push(
        Object.assign(
          {
            goodSelectionId: selection._id,
            goodSelectedItemId: selectionItem._id,
          },
          {
            ...selectionItemCache[
              selectionItem?.item?._id || selectionItem?.item
            ],
          }
        )
      );
    });
  });
  const count = Array.from(Array(quantity - (cancelQuantity || 0)).keys());

  const openCancelItemForm = useCallback(
    (itemNumberInOrder) =>
      setCancelItemFormIsOpenForItemIndex(itemNumberInOrder),
    []
  );
  const closeCancelItemForm = useCallback(
    () => setCancelItemFormIsOpenForItemIndex(null),
    []
  );
  const cancelItem = useCallback(() => {
    closeCancelItemForm();
    onCancelItem();
  }, [closeCancelItemForm, onCancelItem]);

  return (
    <>
      {count.map((i) => {
        const itemNumberInOrder = i + itemCount;
        return (
          <div
            className="item-wrapper"
            key={i}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <ItemWithoutQuantity
              item={itemNumberInOrder}
              numberOfItems={numberOfItems}
              name={name}
              price={priceFoodieListed}
              specialInstructions={specialInstructions}
              selectionItems={selectionItems}
              onCancelSelectionItem={onCancelSelectionItem}
              categoryName={null}
              description={null}
            />
            {onCancelItem != null ? (
              <div style={{ alignSelf: "stretch", marginTop: "-3.5px" }}>
                <button
                  style={{ margin: "0 8px", padding: "0 8px" }}
                  type="button"
                  onClick={() => {
                    openCancelItemForm(itemNumberInOrder);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ) : null}
            {cancelItemFormIsOpenForItemIndex === itemNumberInOrder && (
              <div className="cancel-item-form">
                <div className="field cancel-item-quantity">
                  <label>
                    Cancel <b>{name}</b>
                  </label>
                </div>
                <button onClick={cancelItem}>Yes</button>
                <button onClick={closeCancelItemForm}>No</button>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

interface OrderProps {
  order: OrderType;
  onCancelItem?: CancelItemFn;
  onCancelSelectionItem?: CancelSelectionItemFn;
  receiptFont: boolean;
}
const Order: React.FC<OrderProps> = ({
  order,
  onCancelItem,
  onCancelSelectionItem,
  receiptFont,
}) => {
  LOGGING && console.log("Order rendering with:", order);
  const numberOfItems = getItemNumberInCart(order);
  const { goods } = order;
  const nameFromOrder = getNameFromOrder(order);
  let goodsWithCount: Array<{ count?: number } & SerializedRestaurantGood> =
    goods.map((g) => ({
      ...g,
    }));
  goodsWithCount[0].count = 0;
  goods.reduce(function (a, b, i) {
    const count = a + b.quantity - (b.cancelQuantity || 0);
    goodsWithCount[i].count = a;
    return count;
  }, 0);

  LOGGING &&
    console.log("Order rendering with with count:", { order, goodsWithCount });
  return (
    <div className={`order ${receiptFont ? "receipt-font" : null}`}>
      <div className="order-name">
        <div className="person-name">
          <b>({order.orderHash})</b> {`${nameFromOrder}`}
        </div>
        {order.index != null ? (
          <div className="order-index">
            {`(Order #${zeroPad(order.index + 1, 3)}, ${numberOfItems} items)`}
          </div>
        ) : null}
      </div>
      <div className="order-items">
        {Object.values(goodsWithCount).map((g, i) => (
          <Fragment key={i}>
            {g.quantity > (g.cancelQuantity || 0) && (
              <SingleLineItem
                key={i}
                itemCount={g.count}
                numberOfItems={numberOfItems}
                dish={g.dish}
                selections={g.selections}
                quantity={g.quantity}
                cancelQuantity={g.cancelQuantity}
                specialInstructions={g.comment}
                onCancelItem={onCancelItem?.bind(
                  this,
                  order._id,
                  i,
                  g.dish._id,
                  1
                )}
                onCancelSelectionItem={onCancelSelectionItem?.bind(
                  this,
                  order._id,
                  g._id
                )}
              />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export const OrderReceipt: React.FC<OrderReceiptProps> = ({
  order,
  onCancelItem,
  onCancelSelectionItem,
  receiptFont,
}): ReactElement => {
  if (order == null) return null;

  return (
    <div className="order-wrapper">
      <Order
        order={order}
        onCancelItem={onCancelItem}
        onCancelSelectionItem={onCancelSelectionItem}
        receiptFont={receiptFont}
      />
    </div>
  );
};
