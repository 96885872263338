import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faEmpty } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "src/store";
import styled from "styled-components";
import { LOGGING, PopUp } from "../..";
import { setSurveyAnswer } from "../../../../../store/actions";
import {
  ActionUnderline,
  ActionTransparent,
  Beige,
  BorderGrey,
  card,
  Centered,
  centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Grey,
  MealHeight,
  text12300,
  Text12300,
} from "../../Shared";
const answers = [
  "Very disappointed",
  "Somewhat disappointed",
  "Not disappointed",
];
const labels = ["A", "B", "C"];
const margin = 30;
const ContentContainer = styled(CenteredByColumn)`
  z-index: 100;
  height: calc(100vh - ${margin * 2}px);
  width: calc(100vw - ${margin * 2}px);
  max-width: 500px;
  padding: ${margin}px;
  position: fixed;
  top: ${margin}px;
  left: calc(max(${margin}px, 50vw - 250px));
  ${card}
  @media only screen and (min-width: 500px) {
    max-height: 400px;
    top: calc(max(${margin}px, 50vh - 200px));
  }
`;
const Body = styled(CenteredByColumn)`
  position: relative;
`;
const Confirmation = styled(Text12300)`
  position: absolute;
  height: 40px;
  bottom: -60px;
`;
const Question = styled(Text12300)`
  text-align: left;
  margin-bottom: 20px;
`;
const Answers = styled(CenteredByColumnLeft)`
  align-self: stretch;
`;
const AnswerButton = styled.button`
  border: 1px solid #666;
  border-radius: 3px;
  ${text12300}
  height: 35px;
  margin-bottom: 10px;
  width: 240px;
  text-align: left;
  background-color: ${Beige};
  padding-right: 20px;
  position: relative;
  label {
    width: 20px;
    height: 20px;
    margin: 0px 10px;
    font-weight: 500;
  }
`;

const Answered = styled(Centered)`
  position: absolute;
  right: 6px;
  top: 6px;
  background-color: green;
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 50%;
`;
const SkipButton = styled(ActionUnderline)`
  position: absolute;
  bottom: ${margin}px;
`;

export interface DisappontmentLevelProps {
  onSkip: () => void;
}
export const DisappontmentLevel: React.FC<DisappontmentLevelProps> = ({
  onSkip,
}: DisappontmentLevelProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const firstName = currentUser?.user?.firstName;
  const displayName = firstName.slice(0, 1).toUpperCase() + firstName.slice(1);
  const dispatch = useDispatch();
  const store = useStore();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userAnswer, setUserAnswer] = useState(0);

  const sendAnswer = useCallback(
    async (answer) => {
      LOGGING && console.log("setAnswer called with answer: ", answer);
      setUserAnswer(answer);
      setShowConfirmation(true);
      setTimeout(async () => {
        await setSurveyAnswer("disappointmentLevel", answer)(
          dispatch,
          store.getState
        );
      }, 400);
    },
    [currentUser]
  );
  return (
    <ContentContainer>
      <Body>
        {showConfirmation ? <Confirmation>Thank you!</Confirmation> : null}
        <Question>
          Hey, {`${displayName}`} 👋 <br></br>
          How would you feel <b>if you could no longer</b> use Foodie Earth?
        </Question>
        <Answers>
          {answers.map((answer, index) => (
            <AnswerButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                LOGGING && console.log(`AnswerButton ${index + 1} clicked`);
                sendAnswer(index + 1);
              }}
            >
              <label>{labels[index]}</label>
              {answer}
              {userAnswer === index + 1 ? (
                <Answered>
                  <FontAwesomeIcon icon={faCheck} />
                </Answered>
              ) : null}
            </AnswerButton>
          ))}
        </Answers>
      </Body>
      <SkipButton onClick={onSkip}>skip for now</SkipButton>
    </ContentContainer>
  );
};
