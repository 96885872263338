import React, { ReactElement } from "react";
import { faYelp } from "@fortawesome/free-brands-svg-icons";
import {
  faStar as faStarSolid,
  faStarHalfAlt as faStarHalf,
  faStar,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTimes as faCrossout,
  faRoute,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCrown as faExclusive,
  faStar as faStarEmpty,
  faCheck,
  faChevronRight,
  faTimes,
  faSackDollar as faSaving,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { RootState } from "src/store";
import { calcDistance } from "src/util/location";
import styled from "styled-components";
import { MealQuickLinks } from "./MealQuickLinks";
import { ViralActions } from "./ViralActions";
import { Yelp } from "./Yelp";
import { LOGGING } from "../..";
import { AlertButton } from "../../favorites/AlertButton";
import {
  textTitle,
  YelpRed,
  card,
  Card,
  text12300,
  Beige,
  centered,
  Yellow,
  Centered,
  Grey,
  CenteredByColumn,
  Brown,
  Gold,
  ActionBlack,
  Text12300,
  TextTitle,
  MealHeaderWidgetHeight,
  centeredByColumn,
  CenteredByColumnLeft,
  BorderGrey,
  actionUnderline,
  actionGrey,
  TextGrey,
  NavHeight,
  actionBlack,
  SubNavHeight,
} from "../../Shared";

export interface MealHeaderProps {
  restaurant: any;
  onShowJoin?: () => void;
  isMember?: boolean;
  isAuthenticated?: boolean;
  isMobile?: boolean;
  isExpress?: boolean;
  onShowGroupOrder?: () => void;
  onOpenInvite?: () => void;
  groupOrderId?: string;
  name?: string;
  groupOrderLeader?: any;
  isNotOrganizer?: boolean;
  distance?: number;
  isToday?: boolean;
  orderBy?: number;
}
export const BreakPoint = 800;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  position: relative;
  align-content: stretch;
  @media only screen and (min-width: ${BreakPoint}px) {
    flex-direction: row-reverse;
    /* flex-direction: row; */
    height: auto;
    margin-bottom: 0px;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    background-color: ${Beige};
    border-bottom: 1px solid ${BorderGrey};
  }
`;

const RestaurantName = styled.div`
  /* font-family: Theano Didot, serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 40px;
  text-transform: capitalize; */
  ${textTitle}
  font-size: 24px;
  width: 100%;
  text-align: center;
`;
const YelpLink = styled(Centered)`
  padding: 15px 20px;
  /* box-shadow: 0 2px 4px rgba(52, 41, 41, 0.2); */
  justify-content: flex-start;
  border-bottom: 1px solid ${BorderGrey};
  @media only screen and (min-width: ${BreakPoint}px) {
    padding: 0px;
  }
`;
const Markup = styled(CenteredByColumn)`
  background-color: ${Gold};
  padding: 15px;
  justify-content: space-between;
  height: ${MealHeaderWidgetHeight}px;
  svg {
    margin: 0 10px 0 0;
  }
  width: 150px;
  max-width: 200px;
  box-sizing: border-box;
  flex-grow: 1;
`;

const LinkItem = styled(Link)`
  position: relative;
  font-size: 14px;
  margin: 0px 15px 0 0;
  /* ${card} */
  box-shadow: 0 0px 4px 2px rgba(0, 0, 0, 0.2);
  padding: 15px;
  /* width: 100px; */
  height: ${MealHeaderWidgetHeight}px;
  box-sizing: border-box;
  ${CenteredByColumn}
  justify-content: space-between;
  /* background: ${Beige}; */
  background-color: ${YelpRed};
  color: white;
  box-sizing: border-box;
  width: 150px;
  max-width: 200px;
  flex-grow: 1;
  svg {
    margin-left: 5px;
  }
  /* align-self: stretch;
  flex-grow: 1;
  max-width: 200px; */
`;
const MarkupButton = styled.button`
  background-color: ${Gold};
  ${centeredByColumn}
  padding: 15px;
  justify-content: space-between;
  height: ${MealHeaderWidgetHeight}px;
`;
const Exclusive = styled(Markup)`
  background-color: ${Brown};
  svg {
    margin: 0 0 15px 0;
    font-size: 20px;
  }
  width: 150px;
  padding: 0px 15px;
  justify-content: center;
  font-size: 14px;
  /* div {
    line-height: 1.6;
    font-size: 8px;
  } */
`;
const ExclusiveButton = styled.button`
  background-color: ${Brown};
  ${textTitle}
  ${centeredByColumn}
  justify-content: space-between;
  font-size: 14px;
  height: ${MealHeaderWidgetHeight}px;
  padding: 15px;
  svg {
    margin: 0 0 3px 0;
  }
`;
const MarkupText = styled(Centered)`
  ${text12300}
  font-size: 10px;
  line-height: 1.4;
  text-align: left;
  /* text-transform: capitalize; */
  /* margin-top: 3px; */
  /* position: absolute;
  bottom: 5px; */
  @media only screen and (min-width: ${BreakPoint}px) {
    font-size: 12px;
  }
`;
const ExclusiveText = styled(TextTitle)`
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  @media only screen and (min-width: ${BreakPoint}px) {
    white-space: nowrap;
  }
`;
const MarkupAmount = styled(CenteredByColumn)`
  ${textTitle}
  font-size: 13px;
  /* width: 125px; */
  justify-content: space-between;
  text-align: center;
  /* padding: 0px 5px; */
  line-height: 1;
  svg {
    font-size: 14px;
    margin: 0px;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    width: auto;
  }
`;
const LinkWrapper = styled(CenteredByColumn)`
  /* border: 1px solid transparent;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: absolute; */
  /* border-bottom: 1px solid white; */
  height: 100%;
  /* padding: 0px 10px; */
  justify-content: space-between;
`;

const LinkText = styled.span`
  /* margin-left: 2px; */
  border-bottom: 1px solid ${(props) => props.theme.color};
  ${text12300}
  text-transform: uppercase;
  /* font-size: 18px; */
  line-height: 1.4;
  font-weight: 500;
  padding: 0 3px;
  /* margin-top: 5px; */
`;

const JoinButton = styled(ActionBlack)`
  margin-left: 15px;
  height: 55px;
  padding: 20px;
`;
const Star = styled(Centered)`
  svg {
    font-size: 14px;
  }
`;
const StarSolid = () => (
  <Star>
    <FontAwesomeIcon icon={faStarSolid} />
  </Star>
);
const StarEmpty = () => (
  <Star>
    <FontAwesomeIcon icon={faStarEmpty} />
  </Star>
);
const StarHalf = () => (
  <Star>
    <FontAwesomeIcon icon={faStarHalf} />
  </Star>
);
const YelpRating = styled(Centered)`
  ${textTitle}
  font-size: 12px;
  /* margin-bottom: 3px; */
`;
export const YelpStars = ({ rating }) => (
  <YelpRating>
    {rating < 0.25 ? (
      <StarEmpty />
    ) : rating < 0.75 ? (
      <StarHalf />
    ) : (
      <StarSolid />
    )}
    {rating < 1.25 ? (
      <StarEmpty />
    ) : rating < 1.75 ? (
      <StarHalf />
    ) : (
      <StarSolid />
    )}
    {rating < 2.25 ? (
      <StarEmpty />
    ) : rating < 2.75 ? (
      <StarHalf />
    ) : (
      <StarSolid />
    )}
    {rating < 3.25 ? (
      <StarEmpty />
    ) : rating < 3.75 ? (
      <StarHalf />
    ) : (
      <StarSolid />
    )}
    {rating < 4.25 ? (
      <StarEmpty />
    ) : rating < 4.75 ? (
      <StarHalf />
    ) : (
      <StarSolid />
    )}
  </YelpRating>
);
const Reviews = styled(TextTitle)`
  text-transform: capitalize;
  font-weight: 600;
  /* letter-spacing: 1px; */
  font-size: 14px;
  /* font-weight: ; */
  line-height: 1;
  /* margin-top: 3px; */
`;
const YelpReviews = ({ reviews, rating }) => (
  <Reviews>{`${reviews.toLocaleString()} reviews`} </Reviews>
);
type PriceContentProps = { isMember?: boolean };
const PriceContent = styled(Centered)<PriceContentProps>`
  position: relative;
  padding: 15px 20px;
  box-sizing: border-box;
  /* align-self: stretch; */
  /* flex-grow: 1; */
  /* padding-top: ${(props) => (props.isMember ? "40px" : "15px")}; */
  justify-content: ${(props) =>
    props.isMember ? "space-between" : "flex-start"};
  div:last-child {
    margin-right: 0px;
  }
  border-bottom: 1px solid ${BorderGrey};
  flex-grow: 1;
  @media only screen and (min-width: ${BreakPoint}px) {
    margin: 0px;
    padding: 20px 20px 20px 70px;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: ${Beige};
    align-self: stretch;
    border-bottom: none;
    flex-grow: 0;
    /* background-color: white; */
  }
`;
type MembershipStatusProps = { isMobile?: boolean };
const MembershipStatus = styled(Centered)<MembershipStatusProps>`
  position: absolute;
  ${text12300}
  left: 0px;
  top: 4px;
  font-size: 11px;
  width: 100%;
  padding: 0px 5px;
  justify-content: space-between;
  display: ${(props) => (props.isMobile ? "flex" : "none")};
  b {
    margin-left: 5px;
    font-weight: 500;
    text-transform: uppercase;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    display: ${(props) => (props.isMobile ? "none" : "flex")};
    /* flex-direction: column; */
    /* top: 0px; */
    /* width: 200px; */
    /* padding-top: 5px; */
    position: relative;
    /* top:37px; */
    /* left:20px; */
    justify-content: flex-end;
    align-items: flex-start;
    /* margin-right: 20px; */
    /* position: relative; */
    /* border: 1px solid green; */
    /* align-items: flex-start; */
    span {
      /* b {
        display: block;
        white-space: nowrap;
        margin-left: 0px;
      } */
      white-space: nowrap;
    }
  }
`;
const StartMember = styled(Link)`
  background-color: black;
  ${text12300}
  color: white;
  font-size: 15px;
  margin-top: 12px;
  height: 35px;
  line-height: 35px;
  text-transform: capitalize;
  white-space: normal;
  padding: 5px 20px;
  font-weight: 500;
  text-align: center;
  flex-grow: 1;
  align-self: stretch;
  @media only screen and (max-width: 370px) {
    width: 85px;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    white-space: nowrap;
    /* ${actionBlack} */
    /* margin-left: 15px; */
  }
`;
const MemberOnly = ({ next, isMember }) => (
  <PriceContent isMember={true}>
    <PriceRow isSelected={true} isMember={isMember}>
      <BreakDown>
        <label>
          <FontAwesomeIcon icon={faCrown} />
          Member Only
        </label>
        {isMember ? null : (
          <StartMember
            to={{
              pathname: "/membership",
              state: {
                // title: "To See Menu",
                next,
                // secondary: { title: "Create an Account", next: "/signup" },
              },
            }}
          >
            start membership
          </StartMember>
        )}
      </BreakDown>
    </PriceRow>
  </PriceContent>
);
type PriceRowProps = {
  isSelected: boolean;
  isMember?: boolean;
  isFeeOnly?: boolean;
};

const PriceRow = styled(CenteredByColumn)<PriceRowProps>`
  ${text12300}
  align-items: flex-start;
  line-height: 1.5;
  font-size: 11px;
  margin-right: 15px;
  position: relative;
  align-self: stretch;
  /* flex-grow: 1; */
  justify-content: flex-start;
  /* width: 50%; */
  /* padding: ${(props) => (props.isSelected ? "10px" : "0px")}; */
  padding: 12px 18px;
  /* border: 1px solid ${BorderGrey}; */
  /* border: 1px solid ${(props) =>
    props.isSelected ? "black" : `${BorderGrey}`}; */
  /* box-shadow: ${(props) =>
    props.isSelected ? "0 0px 4px 2px rgba(52, 41, 41, 0.2)" : "none"}; */
  /* background-color: ${(props) =>
    props.isSelected ? `${Beige}` : "transparent"}; */
  /* color: ${(props) => (props.isSelected ? "black" : `${TextGrey}`)}; */
  min-width: auto; /* Allow it to shrink to fit content */
  box-sizing: content-box; /* Ensure padding is not included in width calculation */
  background-color: ${Beige};
  flex-grow: 1;
  @media only screen and (max-width: 370px) {
    margin-right: 10px;
    padding: 10px;
    /* align-self: auto; */
    /* flex-grow: 0; */
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    /* margin-bottom: 5px; */
    /* margin-right: 10px; */
    > label {
      width: 65px;
    }
    flex-grow: 0;
    align-self: auto;
    /* width: 30vw; */
    /* max-width: 280px; */
    /* flex-direction: row; */
    /* flex-grow: 0; */
    /* align-self: auto; */
    background-color: white;
  }
`;
const BreakDown = styled(CenteredByColumnLeft)`
  flex-grow: 1;
  align-self: stretch;
  div:first-child {
    /* height: 50px; */
  }
  > label {
    white-space: nowrap;
    margin-right: 5px;
    /* margin-bottom: 5px; */
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    svg {
      margin-right: 8px;
      color: ${Brown};
    }
    /* position: absolute;
    left: 0px;
    top: 0px; */
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    /* flex-direction: row; */
    width: auto;
    flex-grow: 0;
    align-self: auto;
    > label {
      /* width: 100px; */
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 5px;
    }
    position: relative;
    align-self: stretch;
    /* flex-grow: 1; */
    justify-content: flex-start;
    /* border: 1px solid green; */
    > div:last-child {
      /* position: absolute;
    top: 40px;
    left: 0px;
    align-self: auto;
    flex-grow: 0;
    width: 100%; */
      /* background-color: pink; */
    }
  }
`;
type BreakDownSectionProps = { food?: boolean; isMember?: boolean };
const BreakDownSection = styled(Centered)<BreakDownSectionProps>`
  margin-top: ${(props) => (props.isMember ? "0px" : "5px")};
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  color: ${TextGrey};
  label {
    text-transform: capitalize;
    margin-right: 5px;
    color: black;
  }

  .placeholder {
    opacity: 0;
  }

  span {
    white-space: nowrap;
    svg {
      margin-left: 2px;
      display: none;
    }
  }

  @media only screen and (min-width: ${BreakPoint}px) {
    margin: 0px;
    width: 100%;
    /* height: 20px;
    line-height: 20px; */
    position: relative;
    flex-direction: row;
    /* flex-direction: ${(props) => (props.food ? "row" : "column")}; */

    min-width: ${(props) => (props.isMember ? "300px" : "auto")};
  }
`;
const Cheaper = styled.div`
  ${text12300}
  font-size: 14px;
  color: black;
  line-height: 1;
  font-weight: 500;
  background-color: ${Gold};
  padding: 5px 8px;
  border-radius: 2px;
  margin-left: 5px;
  /* margin-top: 5px; */
  /* position: absolute; */
  /* bottom: -20px; */
  @media only screen and (min-width: ${BreakPoint}px) {
    /* bottom: 0px; */
    /* left: 100%; */
    white-space: nowrap;
    /* position: absolute; */
    /* bottom: auto;
    top: -1px; */
    font-size: 11px;
    padding: 4px 8px;
    position: relative;
    left: 0px;
    margin-left: 10px;
  }
`;
const CheaperMember = styled(Cheaper)`
  position: relative;
  bottom: 1px;
  margin-left: 5px;
`;
const MarkupRangeString = ({ markupAmount, markupAmount2 }) =>
  `${markupAmount}${
    markupAmount2 && markupAmount2 > markupAmount ? `~${markupAmount2}` : ""
  }% cheaper`;
const MarkupRangeString2 = ({ markupAmount, markupAmount2 }) =>
  `${markupAmount}${
    markupAmount2 && markupAmount2 > markupAmount ? `~${markupAmount2}` : ""
  }%`;

const Stamp = styled(Centered)`
  border: 4px solid red;
  color: red;
  border-radius: 5px;
  ${text12300}
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  padding: 5px 10px;
  text-transform: uppercase;
  position: absolute;
  transform: rotate(-20deg);
  opacity: 0.7;
  left: 30px;
`;

const Doordash = styled(CenteredByColumnLeft)<PriceRowProps>`
  margin: ${(props) => (props.isMember ? "0px" : "10px")} 0px 0px 0px;
  position: relative;
  color: ${(props) => (props.isSelected ? "black" : `${TextGrey}`)};
  > svg {
    position: absolute;
    color: ${Gold};
    font-size: 90px;
    left: calc(50% - 25px);
    opacity: 0.9;
    top: -5px;
    /* left: 10px; */
    display: none;
  }
  > label {
    ${text12300}
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    text-transform: uppercase;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: ${TextGrey};
    b {
      /* font-weight: 700; */
      color: black;
      text-transform: capitalize;
      margin-right: 5px;
    }
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    /* background-color: red; */
    display: none;
  }

  &:before {
    top: 0;
    left: 0;
    transform: rotate(20deg);
    transform-origin: top left;
  }

  &:after {
    top: 0;
    right: 0;
    transform: rotate(-20deg);
    transform-origin: top right;
  }

  .cross-out {
    text-decoration: line-through;
    position: relative;
    /* font-weight: 400; */
    /* color: rgba(0, 0, 0); */
    b {
      font-weight: 500;
    }
    svg {
      display: none;
    }
  }

  /* .cross-out:after {
    content: "";
    position: absolute;
    left: 0px;
    top: calc(50% - 0px);
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  } */
  @media only screen and (max-width: 370px) {
    font-size: 7px;
    .cross-out {
      font-size: 12px;
    }
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    /* margin-top: 40px; */
    position: relative;
    /* margin-left: 10px; */
    label.cross-out {
      /* width: 100px; */
      /* background-color:red; */
      flex-grow: 0;
      flex-shrink: 0;
      /* position: absolute;
      left: 0px;
      top: 0px; */
      line-height: 1.5;
    }
    span.cross-out {
      /* margin-left: 10px; */
      text-transform: capitalize;
    }
  }
`;
const ReminderContainer = styled(Centered)`
  position: relative;
  height: ${SubNavHeight}px;
  border-bottom: 1px solid ${BorderGrey};
  justify-content: flex-start;
  padding: 20px;
  @media only screen and (min-width: ${BreakPoint}px) {
    /* height: 100%;
    align-self: stretch;
    flex-grow: 1; */
    /* position: absolute; */
    top: 20px;
    right: 0px;
    margin-left: 20px;
    border-bottom: none;
    width: auto;
    height: auto;
  }
  @media only screen and (min-width: 1200px) {
    /* height: 100%;
    align-self: stretch;
    flex-grow: 1; */
    /* position: absolute; */
    top: 0px;
  }
`;
const DoorDashFees = ({
  isSelected,
  doordashDeliveryFee = { feePerMile: 0 },
  distanceToRestaurant = 0,
}) => {
  let deliveryFee = "$1~2/mi";
  if (doordashDeliveryFee?.feePerMile) {
    if (distanceToRestaurant) {
      deliveryFee = `$${(
        doordashDeliveryFee?.feePerMile * distanceToRestaurant
      ).toFixed(2)}`;
    } else {
      deliveryFee = `$${doordashDeliveryFee?.feePerMile}/mi`;
    }
  }
  const isOutOfRangeOnDoordash = distanceToRestaurant > 10;
  return (
    <Doordash isSelected={isSelected}>
      <FontAwesomeIcon icon={faCrossout} />
      {/* <ByeStamp /> */}
      {isOutOfRangeOnDoordash ? (
        <>
          <label>out of range on doordash</label>
          <span>
            <b>distance</b>
            {distanceToRestaurant.toFixed(1)} miles
          </span>
        </>
      ) : (
        <>
          <label className="cross-out">Doordash fees</label>
          {/* <span className="cross-out">
            service fee 15%
            <FontAwesomeIcon icon={faTimes} />
          </span> */}
          <span className="cross-out">
            {`delivery fee ${deliveryFee}`}
            <FontAwesomeIcon icon={faTimes} />
          </span>
          {distanceToRestaurant > 5 ? (
            <span className="cross-out">
              expanded range $1.5
              <FontAwesomeIcon icon={faTimes} />
            </span>
          ) : null}
        </>
      )}
    </Doordash>
  );
};
const DoorDashFeesMember = ({
  isSelected,
  doordashDeliveryFee = { feePerMile: 0 },
  distanceToRestaurant = 0,
  feeOnly = false,
}) => {
  let deliveryFee = "$1~2/mi";
  if (doordashDeliveryFee?.feePerMile) {
    if (distanceToRestaurant) {
      deliveryFee = `$${(
        doordashDeliveryFee?.feePerMile * distanceToRestaurant
      ).toFixed(2)}`;
    } else {
      deliveryFee = `$${doordashDeliveryFee?.feePerMile}/mi`;
    }
  }
  const isOutOfRangeOnDoordash = distanceToRestaurant > 10;
  return (
    <Doordash isSelected={isSelected}>
      <FontAwesomeIcon icon={faCrossout} />
      {/* <ByeStamp /> */}
      {isOutOfRangeOnDoordash ? (
        <>
          <label>out of range on doordash</label>
          <span>
            <b>distance</b>
            {distanceToRestaurant.toFixed(1)} miles
          </span>
        </>
      ) : (
        <>
          {feeOnly ? null : <label>Also Saving</label>}
          <span className="cross-out">
            <b>Doordash service fee</b>15% ($3 minimum)
          </span>
          <span className="cross-out">
            <b>Doordash delivery fee</b>
            {`${deliveryFee} (${distanceToRestaurant.toFixed(1)} mi)`}
          </span>
          {distanceToRestaurant > 5 ? (
            <span className="cross-out">
              Doordash expanded range fee $1.49
              <FontAwesomeIcon icon={faTimes} />
            </span>
          ) : null}
        </>
      )}
    </Doordash>
  );
};
type DesktopTitleProps = { isMember?: boolean };

const DesktopTitle = styled(Centered)<DesktopTitleProps>`
  > span {
    ${text12300}
    font-weight: 700;
    white-space: nowrap;
    b {
      font-weight: 300;
      text-transform: none;
    }
  }
  /* align-self: stretch; */
  /* flex-grow: 1; */
  justify-content: space-between;
  text-transform: uppercase;
  width: ${(props) => (props.isMember ? "auto" : "calc(100% - 40px)")};
  /* width: auto; */
  position: ${(props) => (props.isMember ? "relative" : "absolute")};
  top: ${(props) => (props.isMember ? "0px" : "10px")};
  padding-right: ${(props) => (props.isMember ? "20px" : "0px")};
  /* padding-right: 20px; */
  svg {
    margin-right: 5px;
    font-size: 13px;
  }
  /* display: none; */
  @media only screen and (min-width: ${BreakPoint}px) {
    display: flex;
    width: ${(props) => (props.isMember ? "auto" : "545px")};
  }
`;
const Saving = styled(Link)`
  ${text12300}
  border-bottom: 1px solid black;
  line-height: 1.5;
  font-weight: 400;
`;
const DistanceContainer = styled(Centered)`
  span {
    ${text12300}
    text-transform: none;
  }
`;
const Distance = ({ distance }) => (
  <DistanceContainer>
    <FontAwesomeIcon icon={faRoute} />
    <span>{`${distance.toFixed(1)} mi`}</span>
  </DistanceContainer>
);
export const HowMuchDoISave = ({}) => (
  <Saving to="/pricecomparison">How much do i save?</Saving>
);
const PriceGuide = ({ distance }) => (
  <span>
    <FontAwesomeIcon icon={faSaving} />
    Price Guide {distance ? <b> ({`${distance.toFixed(1)}`} mi)</b> : null}
    {/* {`${distance>10?", N/A on Doordash.":""}`} */}
  </span>
);
const MemberBenefits = ({ distance }) => (
  <span>
    <FontAwesomeIcon icon={faSaving} />
    Price Guide
  </span>
);
const PriceComparison = ({
  isMember,
  isExclusive,
  markupAmount,
  markupAmount2,
  isExpress,
  next,
  doordashDeliveryFee,
  distanceToRestaurant,
}) => (
  <PriceContent isMember={isMember}>
    {/* <DesktopTitle isMember={isMember}> */}
    {/* <PriceGuide distance={distanceToRestaurant} /> */}
    {/* <span>
        <FontAwesomeIcon icon={faRoute}/>
        <
      </span> */}
    {/* <MembershipStatus isMobile={false}>
        <span>
          Status: <b>{isMember ? "member" : "non-member"}</b>
        </span>
        {isMember ? null : (
          <StartMember href="/membership">start membership</StartMember>
        )}
      </MembershipStatus> */}
    {/* <HowMuchDoISave /> */}
    {/* {distanceToRestaurant?<Distance distance={distanceToRestaurant}/>:null} */}
    {/* </DesktopTitle> */}
    {/* <MembershipStatus isMobile={true}>
      <span>
        Status: <b>{isMember ? "member" : "non-member"}</b>
      </span>    
    </MembershipStatus> */}
    {/* <PriceRow isSelected={!isMember}>
      <BreakDown>
        <label>
          non-member        
        </label>
        <BreakDownSection food={true}>
          <div>
            <label>food</label>
            <span>
              Doordash price
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </div>          
        </BreakDownSection>
        <BreakDownSection>
          <div>
            <label>fees</label>
            <span>
              {`15% ($3 minimum)`}
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </div>
        </BreakDownSection>
      </BreakDown>
      <DoorDashFees
        isSelected={!isMember}
        doordashDeliveryFee={doordashDeliveryFee}
        distanceToRestaurant={distanceToRestaurant}
      />
    </PriceRow> */}
    <PriceRow isSelected={isMember}>
      <BreakDown>
        <label>
          <FontAwesomeIcon icon={faCrown} />
          Member
          {/* {isMember ? <FontAwesomeIcon icon={faCheck} /> : null} */}
        </label>
        <BreakDownSection food={true}>
          <div>
            <label>food</label>
            {/* <span className="cross-out">Doordash price.</span> */}
            <span>
              Dine-in price
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </div>
          <Cheaper>
            {MarkupRangeString({ markupAmount, markupAmount2 })}
          </Cheaper>
        </BreakDownSection>
        <BreakDownSection>
          <label>fees</label>
          <span>
            {`$${isExpress ? 3 : 2} flat fee`}
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </BreakDownSection>
        {/* <BreakDownSection>
          <div>
            <label>delivery fee</label>
            <span>
              $0
            </span>
          </div>
        </BreakDownSection> */}
      </BreakDown>
      {/* <DoorDashFees
        isSelected={isMember}
        doordashDeliveryFee={doordashDeliveryFee}
        distanceToRestaurant={distanceToRestaurant}
      /> */}
      {isMember ? null : (
        <StartMember
          to={{
            pathname: "/membership",
            state: {
              // title: "To See Menu",
              next,
              // secondary: { title: "Create an Account", next: "/signup" },
            },
          }}
        >
          start membership
        </StartMember>
      )}
    </PriceRow>
  </PriceContent>
);

const PriceComparisonMember = ({
  isExclusive,
  markupAmount,
  markupAmount2,
  isExpress,
  next,
  doordashDeliveryFee,
  distanceToRestaurant,
}) => (
  <PriceContent isMember={true}>
    {/* <DesktopTitle>
      <MemberBenefits distance={distanceToRestaurant} />
    </DesktopTitle> */}
    <PriceRow isSelected={true}>
      <BreakDown>
        <label>
          <FontAwesomeIcon icon={faCrown} />
          Member
        </label>
        <BreakDownSection food={true} isMember={true}>
          <div>
            <label>Food</label>
            <span>No markups</span>
          </div>
          <CheaperMember>
            {MarkupRangeString({ markupAmount, markupAmount2 })}
          </CheaperMember>
        </BreakDownSection>
        <BreakDownSection isMember={true}>
          <div>
            <label>fees</label>
            <span>
              {`$${isExpress ? 3 : 2} flat fee`}
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </div>
        </BreakDownSection>
      </BreakDown>
      <DoorDashFeesMember
        isSelected={true}
        doordashDeliveryFee={doordashDeliveryFee}
        distanceToRestaurant={distanceToRestaurant}
      />
    </PriceRow>
  </PriceContent>
);

const FeeComparisonMember = ({
  isExpress,
  next,
  doordashDeliveryFee,
  distanceToRestaurant,
}) => (
  <PriceContent isMember={true}>
    {/* <DesktopTitle>
      <MemberBenefits distance={distanceToRestaurant} />
    </DesktopTitle> */}
    <PriceRow isSelected={true}>
      <BreakDown>
        <label>
          <FontAwesomeIcon icon={faCrown} />
          Member
        </label>
        <BreakDownSection isMember={true}>
          <div>
            <label>fee</label>
            <span>
              {`$${isExpress ? 3 : 2} flat fee`}
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </div>
        </BreakDownSection>
      </BreakDown>
      <DoorDashFeesMember
        isSelected={true}
        doordashDeliveryFee={doordashDeliveryFee}
        distanceToRestaurant={distanceToRestaurant}
        feeOnly={true}
      />
    </PriceRow>
  </PriceContent>
);
const FeeComparison = ({
  isMember,
  isExpress,
  next,
  doordashDeliveryFee,
  distanceToRestaurant,
}) => (
  <PriceContent isMember={isMember}>
    {/* <DesktopTitle isMember={isMember}> */}
    {/* <PriceGuide distance={distanceToRestaurant} /> */}
    {/* <MembershipStatus isMobile={false}>
        <span>
          Status: <b>{isMember ? "member" : "non-member"}</b>
        </span>
        {isMember ? null : (
          <StartMember href="/membership">start membership</StartMember>
        )}        
      </MembershipStatus> */}
    {/* <HowMuchDoISave /> */}
    {/* </DesktopTitle> */}
    {/* <PriceRow isSelected={!isMember}>
      <BreakDown>
        <label>
          non-member          
        </label>
        <BreakDownSection>
          <div>
            <label>fees</label>
            <span>
              {`$${isExpress ? 4 : 2} flat fee`}
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </div>
        </BreakDownSection>
      </BreakDown>
      <DoorDashFees
        isSelected={!isMember}
        doordashDeliveryFee={doordashDeliveryFee}
        distanceToRestaurant={distanceToRestaurant}
      />
    </PriceRow> */}
    <PriceRow isSelected={isMember}>
      <BreakDown>
        <label>
          <FontAwesomeIcon icon={faCrown} />
          Member
          {/* {isMember ? <FontAwesomeIcon icon={faCheck} /> : null} */}
        </label>
        <BreakDownSection>
          <div>
            <label>fees</label>
            <span>
              {`$${isExpress ? 3 : 2} flat fee`}
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </div>
        </BreakDownSection>
      </BreakDown>
      {/* <DoorDashFees
        isSelected={isMember}
        doordashDeliveryFee={doordashDeliveryFee}
        distanceToRestaurant={distanceToRestaurant}
      /> */}
      {isMember ? null : (
        <StartMember
          to={{
            pathname: "/membership",
            state: {
              // title: "To See Menu",
              next,
              // secondary: { title: "Create an Account", next: "/signup" },
            },
          }}
        >
          start membership
        </StartMember>
      )}
    </PriceRow>
  </PriceContent>
);
const MarkupContainer = styled(Centered)`
  position: relative;
  ${text12300}
  text-transform: capitalize;
  label,
  b {
    margin-left: 5px;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: 500;
  }
  b {
    background-color: ${Gold};
  }
  label {
    background-color: ${Brown};
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    white-space: nowrap;
  }
`;
const MemberMarkup = ({
  markupAmount,
  markupAmount2,
  distanceToRestaurant = 0,
}) => (
  <PriceContent isMember={true}>
    {/* <DesktopTitle isMember={true}>
      <PriceGuide distance={distanceToRestaurant} />
    </DesktopTitle> */}
    <MarkupContainer>
      <b>Price Guide {MarkupRangeString2({ markupAmount, markupAmount2 })}</b>
    </MarkupContainer>
  </PriceContent>
);

export const MealHeader: React.FC<MealHeaderProps> = ({
  restaurant,
  onShowJoin,
  isAuthenticated,
  isMember,
  isMobile,
  isExpress,
  onShowGroupOrder,
  groupOrderId,
  groupOrderLeader,
  onOpenInvite,
  isNotOrganizer,
  distance,
  isToday,
  orderBy,
}: MealHeaderProps): ReactElement => {
  const location = useLocation();
  const currentUser = useSelector((state: RootState) => state.currentUser);
  if (restaurant == null) return null;
  const websiteUrl = restaurant?.contact?.websiteUrl;
  const yelpUrl = restaurant?.contact?.yelpUrl;
  const { markups, memberOnly, doordashDeliveryFee, map } = restaurant;
  const isExclusive =
    memberOnly ||
    (markups?.doordash?.isAvailable === false &&
      markups?.doordash?.isAvailable === false);
  const markupAmount = markups?.doordash?.amount;
  const markupAmount2 = markups?.doordash?.amount2;
  const markupType = markups?.doordash?.type;
  const distanceToRestaurant =
    1.3 *
    (distance ||
      (currentUser?.user?.deliveryInfo?.map && restaurant?.map
        ? calcDistance(currentUser?.user?.deliveryInfo?.map, restaurant?.map)
        : 0));

  return (
    <ContentContainer>
      <MealQuickLinks
        yelp={{
          rating: restaurant?.yelp?.rating,
          reviews: restaurant?.yelp?.reviews,
          url: restaurant?.contact?.yelpUrl,
        }}
        isMember={isMember}
        onShowGroupOrder={onShowGroupOrder}
        onOpenInvite={onOpenInvite}
        groupOrderId={groupOrderId}
        groupOrderLeader={groupOrderLeader}
        isExclusive={isExclusive}
        restaurantId={restaurant?._id}
        restaurantName={restaurant?.name}
      />
      {/* <YelpLink> */}
      {/* <Yelp restaurant={restaurant} isMobile={true} /> */}
      {/* </YelpLink> */}
      {isNotOrganizer ? null : isExclusive ? (
        <MemberOnly next={location.pathname} isMember={isMember} />
      ) : isMember ? (
        markupAmount ? (
          <PriceComparisonMember
            isExclusive={isExclusive}
            markupAmount={markupAmount}
            markupAmount2={markupAmount2}
            isExpress={isExpress}
            next={location.pathname}
            doordashDeliveryFee={doordashDeliveryFee}
            distanceToRestaurant={distanceToRestaurant}
          />
        ) : (
          <FeeComparisonMember
            isExpress={isExpress}
            next={location.pathname}
            doordashDeliveryFee={doordashDeliveryFee}
            distanceToRestaurant={distanceToRestaurant}
          />
        )
      ) : markupAmount ? (
        <PriceComparison
          isExclusive={isExclusive}
          markupAmount={markupAmount}
          markupAmount2={markupAmount2}
          isMember={isMember}
          isExpress={isExpress}
          next={location.pathname}
          doordashDeliveryFee={doordashDeliveryFee}
          distanceToRestaurant={distanceToRestaurant}
        />
      ) : (
        <FeeComparison
          isMember={isMember}
          isExpress={isExpress}
          next={location.pathname}
          doordashDeliveryFee={doordashDeliveryFee}
          distanceToRestaurant={distanceToRestaurant}
        />
      )}
      {/* <ViralActions
        isMember={isMember}
        onShowGroupOrder={onShowGroupOrder}
        onOpenInvite={onOpenInvite}
        groupOrderId={groupOrderId}
        groupOrderLeader={groupOrderLeader}
        isExclusive={isExclusive}
      />
      <ReminderContainer>
        <AlertButton
          restaurantId={restaurant?._id}
          restaurantName={restaurant?.name}
          isNav={true}
        />
      </ReminderContainer> */}
    </ContentContainer>
  );
};
