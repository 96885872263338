import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { LOGGING } from "..";
import {
  Centered,
  CenteredByColumnLeft,
  NavHeight,
  Text12300,
} from "../Shared";

const Container = styled(CenteredByColumnLeft)`
  position: absolute;
  top: ${NavHeight * 3}px;
  left: 25vw;
  width: auto;
  height: auto;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
`;
const Stop = styled(Centered)`
  align-items: flex-start;
`;
const StopName = styled(Text12300)`
  line-height: 1.5;
  font-weight: 600;
  margin-left: 5px;
`;
export const RestaurantLegendsOnMap = ({ mealMarkers, stops, stopCounts }) => {
  LOGGING &&
    console.log("RestaurantLegendsOnMap got: ", {
      stops,
      mealMarkers,
      stopCounts,
    });
  return (
    <Container>
      {stops.map((stop) => (
        <Stop>
          <FontAwesomeIcon icon={mealMarkers[stop.meal]} />
          {/* split by space or hiphen */}
          <StopName>{stop.mapViewAttributes.label.split(/\s|-/)[0]}</StopName>
          <StopName>{stopCounts[stop.meal]}</StopName>
        </Stop>
      ))}
    </Container>
  );
};
