import _ from "lodash";
import moment from "moment-timezone";
import { MenuDateFormat, CATimeZone, LOGGING } from "../constants";
export const CutOffFromDeliveryStartInMinutes = {
  lunch: -60,
  dinner: -90,
  earlyDinner: -30,
};
export const ISOtoEpoch = ({ str }) => Math.floor(new Date(str).valueOf());
export const DeliveryWindowDisplay = {
  lunch: "11:30~12:30PM",
  dinner: "6~7PM",
  earlyDinner: "4:00~5:30PM",
};
export const GetMealTypeDisplayName = (mealType) =>
  mealType === "earlyDinner" ? "express dinner" : mealType;
export const GetMealTypeFromWindowStart = (windowStart) => {
  const hour = moment(windowStart).tz(CATimeZone).hour();
  const mealType = hour < 13 ? "lunch" : hour < 17 ? "earlyDinner" : "dinner";
  return mealType;
};
export const CutOffDisplay = {
  lunch: "10:30AM",
  dinner: "4:30PM",
  earlyDinner: "3:30PM",
};
export const kCritialHourMinute = {
  lunch: {
    orderCutOff: [10, 30],
    restaurantReady: [11, 0],
    driverPickup: [11, 15],
    deliveryStart: [11, 30],
    deliveryEnd: [12, 30],
    deliveryEndWithDelay: [13, 0],
  },
  earlyDinner: {
    orderCutOff: [15, 30],
    restaurantReady: [15, 45],
    driverPickup: [15, 55],
    deliveryStart: [16, 0],
    deliveryEnd: [17, 30],
    deliveryEndWithDelay: [18, 0],
  },
  dinner: {
    orderCutOff: [16, 30],
    restaurantReady: [17, 15],
    driverPickup: [17, 30],
    deliveryStart: [18, 0],
    deliveryEnd: [19, 0],
    deliveryEndWithDelay: [19, 30],
  },
};

// return [hour, minute]
const getHourMinute = (time) => {
  return time.toArray().slice(3, 5);
};

const getCurrentHourMinute = () => {
  return getHourMinute(moment());
};

const updateHourMinute = (time, hourMinute) => {
  return time
    .hour(hourMinute[0])
    .minute(hourMinute[1])
    .second(0)
    .millisecond(0)
    .valueOf();
};

export const isBeforeLunchCutOffTime = () => {
  return getCurrentHourMinute() < kCritialHourMinute.lunch.orderCutOff;
};

export const isBeforeDinnerCutOffTime = () => {
  return getCurrentHourMinute() < kCritialHourMinute.dinner.orderCutOff;
};

export const getCompleteWindowsForTheDay = (time = moment()) => {
  let windows = {};
  Object.entries(kCritialHourMinute).forEach(([mealName, value]) => {
    windows[mealName] = {};
    Object.entries(value).forEach(([timeName, hourMinute]) => {
      windows[mealName][timeName] = updateHourMinute(time, hourMinute);
    });
  });
  console.log("getCompleteWindowsForTheDay returning windows:", windows);
  return windows;
};

export const getRemainingWindowsForToday = () => {
  let windows = getCompleteWindowsForTheDay();
  if (!isBeforeLunchCutOffTime()) {
    delete windows.lunch;
  }
  if (!isBeforeDinnerCutOffTime()) {
    delete windows.dinner;
  }
  return windows;
};

export const getRemainingWindowsListForTheWeek = () => {
  let windowsList = [getRemainingWindowsForToday()];
  const tomorrow = moment().add(1, "d").startOf("d");
  const endOfWeek = tomorrow.clone().endOf("w");
  for (let day = tomorrow; day.isSameOrBefore(endOfWeek); day.add(1, "d")) {
    windowsList.push(getCompleteWindowsForTheDay(day));
  }
  return windowsList;
};
export const isWindowStartLunch = (windowStart) =>
  moment(windowStart).tz(CATimeZone).hour() < 12;
export const isNextMealLunch = () => {
  return isBeforeLunchCutOffTime() || !isBeforeDinnerCutOffTime();
};

export const getNextWindow = () => {
  if (isBeforeLunchCutOffTime()) {
    return getCompleteWindowsForTheDay().lunch;
  }

  if (isBeforeDinnerCutOffTime()) {
    return getCompleteWindowsForTheDay().dinner;
  }

  // Lunch next day
  return getCompleteWindowsForTheDay(moment().add(1, "d")).lunch;
};

// deliveryStartTime is used for representing order time
export const getWindowForOrderTime = (deliveryStartTimestamp) => {
  const deliveryStartTime = moment(deliveryStartTimestamp);
  const deliveryStartTimeHourMinute = getHourMinute(deliveryStartTime);

  // find is lunch or dinner
  let selectedCritialHourMinute = null;
  if (
    _.isEqual(
      deliveryStartTimeHourMinute,
      kCritialHourMinute.lunch.deliveryStart
    )
  ) {
    selectedCritialHourMinute = kCritialHourMinute.lunch;
  } else if (
    _.isEqual(
      deliveryStartTimeHourMinute,
      kCritialHourMinute.earlyDinner.deliveryStart
    )
  ) {
    selectedCritialHourMinute = kCritialHourMinute.earlyDinner;
  } else if (
    _.isEqual(
      deliveryStartTimeHourMinute,
      kCritialHourMinute.dinner.deliveryStart
    )
  ) {
    selectedCritialHourMinute = kCritialHourMinute.dinner;
  } else {
    console.log("getWindowForOrderTime got wrong: ", {
      deliveryStartTimeHourMinute,
      deliveryStartTimestamp,
    });
    throw "order time does not match config";
  }

  let result = {};
  Object.entries(selectedCritialHourMinute).forEach(
    ([timeName, hourMinute]) => {
      result[timeName] = updateHourMinute(deliveryStartTime, hourMinute);
    }
  );
  return result;
};

export const getOrderCutOffTimestampForOrderTime = (deliveryStartTimestamp) => {
  return getWindowForOrderTime(deliveryStartTimestamp).orderCutOff;
};

export const isBeforeCutOffTimeForOrderTime = (deliveryStartTimestamp) => {
  const cutoffTimestamp = getOrderCutOffTimestampForOrderTime(
    deliveryStartTimestamp
  );
  return moment().valueOf() < cutoffTimestamp;
};

export const getDateKey = (day) => {
  const today = moment().tz(CATimeZone).format(MenuDateFormat);
  const tomorrow = moment().tz(CATimeZone).add(1, "d").format(MenuDateFormat);
  let dateKey = day.format(MenuDateFormat);

  // if (dateKey === today) {
  //   dateKey = 'today, ' + dateKey;
  // } else if (dateKey === tomorrow) {
  //   dateKey = 'tomorrow, ' + dateKey;
  // }
  return dateKey;
};

export const getWindowForDay = () => {
  const now = moment().tz(CATimeZone);
  const startAtMs = now.startOf("day").valueOf();
  const endAtMs = now.endOf("day").valueOf();

  return [startAtMs, endAtMs];
};

export const getWindowForTomorrow = () => {
  const tomorrow = moment().tz(CATimeZone).add(1, "day");
  const startAtMs = tomorrow.startOf("day").valueOf();
  const endAtMs = tomorrow.endOf("day").valueOf();

  return [startAtMs, endAtMs];
};

export const getLunchWindowStart = () => {
  return moment({ hour: 11, minute: 30 }).tz(CATimeZone).valueOf();
};

export const getDinnerWindowStart = () => {
  return moment({ hour: 18, minute: 0 }).tz(CATimeZone).valueOf();
};

export const getEarlyDinnerWindowStart = () => {
  return moment({ hour: 16, minute: 0 }).tz(CATimeZone).valueOf();
};

export const getTodayWindowStart = (mealType) => {
  const hour = kCritialHourMinute[mealType].deliveryStart[0];
  const minute = kCritialHourMinute[mealType].deliveryStart[1];
  return moment({ hour, minute }).tz(CATimeZone).valueOf();
};
