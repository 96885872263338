import { Component } from "react";
import moment from "moment-timezone";
import { GetReferralLinkSender } from "src/api/referrals";
import { FoodieEvent, FoodieSource, TrackEvent } from "src/api/tracker";
import { LOGGING, AnonymousFooter, Loading } from ".";
import { GetEmailOrGoogle } from "../tasting_menu/components/auth/GetEmailOrGoogle";
import { AnonymousRestaurantSlides } from "../tasting_menu/components/home/CoverSliding";
import { ReferralInvitation } from "../tasting_menu/components/referrals/ReferralInvitation";

class PageSignUpByEmailReferral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalErrorMessage: null,
      referrer: null,
      loading: true,
      title: "Your free food is waiting for you!",
    };
    this.handleReferrer = this.handleReferrer.bind(this);
    this.handleSetModalError = this.handleSetModalError.bind(this);
    this.handleSetTitle = this.handleSetTitle.bind(this);
  }

  handleSetTitle(title) {
    this.setState({ title });
  }
  handleSetModalError(modalErrorMessage) {
    this.setState({ modalErrorMessage });
  }

  handleReferrer(referrer) {
    this.setState({
      referrer,
    });
  }

  async componentDidMount() {
    const startAt = moment().valueOf();
    const socialInviteCode = this.props.match?.params?.socialInviteCode;
    let referrer = await GetReferralLinkSender(socialInviteCode);
    LOGGING &&
      console.log("PageSignUpByEmailReferral found referrer: ", {
        socialInviteCode,
        referrer,
      });
    this.handleReferrer(referrer?.sender);
    this.setState({ loading: false, socialInviteCode }, () => {
      window.scrollTo(0, 0);
      this.trackPageSignUpEvent(moment.valueOf() - startAt);
    });
  }

  trackPageSignUpEvent(latency) {
    TrackEvent(
      FoodieEvent.PAGE_SIGN_UP,
      null,
      FoodieSource.UNKNOWN,
      null,
      null,
      latency
    );
  }
  render() {
    LOGGING &&
      console.log("PageSignUpByEmailReferral got", {
        state: this.state,
        props: this.props,
      });

    const referrerName = this.state.referrer?.firstName;

    const { modalErrorMessage, loading, title, socialInviteCode } = this.state;
    return (
      <div className="page new-user">
        <div className="anonymous-container">
          <AnonymousRestaurantSlides />
          {modalErrorMessage && (
            <div className="error-message auth">{modalErrorMessage}</div>
          )}
          {loading ? (
            <Loading />
          ) : (
            <div className="anonymous-form">
              {referrerName ? (
                <ReferralInvitation
                  sender={referrerName}
                  title={title}
                  form={
                    <GetEmailOrGoogle
                      allowSignUp={true}
                      allowLogin={false}
                      parentError={modalErrorMessage}
                      setParentError={this.handleSetModalError}
                      next={this?.props?.location?.state?.next}
                      inviter={referrerName}
                      inviteCode={socialInviteCode}
                      small={true}
                      setParentTitle={this.handleSetTitle}
                    />
                  }
                />
              ) : null}
            </div>
          )}
        </div>
        <AnonymousFooter />
      </div>
    );
  }
}

export default PageSignUpByEmailReferral;
